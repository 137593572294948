.l-plans {
  position: relative;

  background-color: var(--accent-color);

  &::before,
  &::after {
    content: "";

    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;

    width: 0.2rem;
    height: 15rem;

    @include breakpoint("tablet-max") {
      height: 10rem;
    }
  }

  &::before {
    transform: translateY(-100%);

    background-color: var(--luxury-black);
  }

  &::after {
    background-color: #ffffff;
  }

  &__container {
    position: relative;

    &--top {
      padding-top: 18rem;

      @include breakpoint("tablet-max") {
        padding-top: 14rem;
      }
    }

    &--bottom {
      padding-bottom: 12.8rem;
    }
  }

  &__title {
    margin-bottom: 4.8rem;
    text-align: center;

    @include breakpoint("tablet-max") {
      margin-bottom: 2.4rem;
    }
  }

  &__slide-wrapper {
    margin: 5rem 0;

    a {
      display: block;
    }
  }

  &__slide {
    position: relative;
    cursor: pointer;

    &.swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 48rem;

      &:not(.single-slide) {
        background-color: #ffffff;
      }

      &.single-slide {
        .l-plans {
          &__slide-wrapper {
            margin: unset;
          }

          &__expand {
            display: none;
          }
        }

        @include breakpoint("tablet-max") {
          background-color: #ffffff;
        }
      }

      img {
        object-fit: contain;
        max-height: 40rem;
      }
    }
  }

  &__expand {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 4.4rem;
    height: 4.4rem;
    padding: 1.2rem;

    background-color: var(--accent-color);

    pointer-events: none;
    cursor: pointer;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    gap: 1rem;

    margin-bottom: 5rem;

    .swiper-pagination-bullet {
      opacity: 100%;
      transition: all 0.4s;

      border: 0.2rem solid transparent;
      border-radius: unset;
      width: max-content;
      height: 100%;
      padding: 0.8rem 1.3rem;

      background: unset;
      color: var(--luxury-gray);

      &:hover {
        color: #ffffff;
      }
    }

    .swiper-pagination-bullet-active {
      border-bottom: 0.2rem solid #ffffff;

      background: unset;
      color: #ffffff;
    }
  }

  &__pattern {
    position: absolute;
    top: 0;
    right: var(--container-padding);

    margin-left: auto;

    @include breakpoint("tablet-max") {
      display: none;
    }

    &--bottom {
      position: absolute;
      left: 50%;
      bottom: 0;

      transform: translateX(-50%);
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    margin-top: 4rem;
  }
}
