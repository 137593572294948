.video {
  &__section {
    position: relative;
    margin-bottom: 6.4rem;
    @include breakpoint("phone-max") {
      margin-bottom: 3.2rem;
    }

    .container:not(:first-child) {
      margin-top: 4rem;
    }
  }
  &__pattern {
    left: 0;
    top: -38.7%;
    background-image: url("../assets/patterns/video.svg");
    @include breakpoint("phone-max") {
      display: none;
    }
  }
  &__title {
    margin-bottom: 2.4rem;
  }
  &__wrapper {
    border-radius: var(--wrapper-radius, 0);
    box-shadow: var(--sections-shadow);
    overflow: hidden;
  }
  &__player-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}