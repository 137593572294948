.b-gallery {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    cursor: zoom-in;
    transition: all 0.4s;
    overflow: hidden;
    border-radius: var(--image-radius, 0);
    height: 19.2rem;

    @include breakpoint("tablet-max") {
      height: 32rem;
    }

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    img {
      border-radius: var(--image-radius, 0);
      transition: all var(--anim-default-duration);
      object-fit: cover;

      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 3.6rem;
    text-align: center;
    margin-bottom: 2.4rem;
    font-weight: 600;
  }

  &__group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1.2rem;

    width: 100%;
  }

  &__btn {
    margin-top: 3.2rem;
  }
}
