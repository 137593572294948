:root {
  --header-height: 6.4rem;
  --input-height: 4.4rem;
  --container-padding: 4rem;
  --l-container-padding: 3.2rem;
  --font-barlow: "Barlow Semi Condensed", sans-serif;
  --font-forum: "Forum", cursive;
  --font-jost: "Jost", sans-serif;
  --font-sora: "Sora", sans-serif;
  --font-tomorrow: "Tomorrow", sans-serif;
  --font-trirong: "Trirong", sans-serif;
  --font-ibm-plex-sans: "IBM Plex Sans", sans-serif;
  --primary-font: var(--font-jost);
  --hero-radius: 0;
  --wrapper-radius: 0;
  --image-radius: 0;
  --elements-radius: 0;
  --buttons-radius: 0;
  --basic-overview-radius: 0;
  --accent-color: #f59f00;
  --hero-tag-color: #0ca678;
  --error-color: #fa5252;
  --main-color: #141414;
  --text-color: #757575;
  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --gray-10: #333333;
  --gray-11: #666666;
  --luxury-heading: #282422;
  --luxury-black: #0F0E0D;
  --luxury-gray: #F7F6F5;
  --luxury-platinum: #A69F91;
  --luxury-platinum-100: #EBEAE6;
  --luxury-platinum-200: #D9D6CF;
  --luxury-platinum-300: #C0BCB1;
  --sections-shadow: 0 3.2rem 4.8rem rgba(0, 0, 0, 0.15);
  --anim-reveal-y: 60%;
  --anim-slide-x: 100%;
  --anim-slide-y: 100%;
  --anim-default-duration: 0.5s;
  --anim-small-duration: 0.35s;
  --anim-medium-duration: 0.7s;
  --anim-long-duration: 0.9s;
  --anim-very-long-duration: 1.5s;
  --anim-easing-in: cubic-bezier(0.33, 1, 0.68, 1);
  --anim-easing-out: cubic-bezier(0.32, 0, 0.67, 0);
  --anim-easing-in-out: cubic-bezier(0.65, 0, 0.35, 1);
  --anim-btn: cubic-bezier(1, 0, 0, 1);
  --anim-tag: cubic-bezier(0.86, 0, 0.07, 1);
  --anim-nav: 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  --anim-play-video-btn: cubic-bezier(0.16, 1, 0.3, 1);
}
@media screen and (max-width: 1024px) {
  :root {
    --container-padding: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --l-container-padding: 1.2rem;
  }
}

@keyframes reveal-in-from-bottom {
  0% {
    opacity: 0.1;
    transform: translate3d(0, 50px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}
@keyframes reveal-in-from-top {
  0% {
    transform: translate3d(0, -50px, 0);
    opacity: 0.1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 0.99;
  }
}
@keyframes header-reveal {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
  100% {
    transform: translate3d(0, -50px, 0);
    opacity: 0.1;
  }
}
@keyframes fade-in-scale-down {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animate-third-circle {
  0%, 50% {
    transform: translate(-50%, -50%) scale(1);
  }
  50%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes animate-second-circle {
  0%, 20% {
    transform: translate(-50%, -50%) scale(1);
  }
  80%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes animate-first-circle {
  0%, 40% {
    transform: translate(-50%, -50%) scale(1);
  }
  90%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes nav-link-fade {
  0% {
    opacity: 0;
    transform: translateX(5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

:root {
  --header-height: 6.4rem;
  --input-height: 4.4rem;
  --container-padding: 4rem;
  --l-container-padding: 3.2rem;
  --font-barlow: "Barlow Semi Condensed", sans-serif;
  --font-forum: "Forum", cursive;
  --font-jost: "Jost", sans-serif;
  --font-sora: "Sora", sans-serif;
  --font-tomorrow: "Tomorrow", sans-serif;
  --font-trirong: "Trirong", sans-serif;
  --font-ibm-plex-sans: "IBM Plex Sans", sans-serif;
  --primary-font: var(--font-jost);
  --hero-radius: 0;
  --wrapper-radius: 0;
  --image-radius: 0;
  --elements-radius: 0;
  --buttons-radius: 0;
  --basic-overview-radius: 0;
  --accent-color: #f59f00;
  --hero-tag-color: #0ca678;
  --error-color: #fa5252;
  --main-color: #141414;
  --text-color: #757575;
  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --gray-10: #333333;
  --gray-11: #666666;
  --luxury-heading: #282422;
  --luxury-black: #0F0E0D;
  --luxury-gray: #F7F6F5;
  --luxury-platinum: #A69F91;
  --luxury-platinum-100: #EBEAE6;
  --luxury-platinum-200: #D9D6CF;
  --luxury-platinum-300: #C0BCB1;
  --sections-shadow: 0 3.2rem 4.8rem rgba(0, 0, 0, 0.15);
  --anim-reveal-y: 60%;
  --anim-slide-x: 100%;
  --anim-slide-y: 100%;
  --anim-default-duration: 0.5s;
  --anim-small-duration: 0.35s;
  --anim-medium-duration: 0.7s;
  --anim-long-duration: 0.9s;
  --anim-very-long-duration: 1.5s;
  --anim-easing-in: cubic-bezier(0.33, 1, 0.68, 1);
  --anim-easing-out: cubic-bezier(0.32, 0, 0.67, 0);
  --anim-easing-in-out: cubic-bezier(0.65, 0, 0.35, 1);
  --anim-btn: cubic-bezier(1, 0, 0, 1);
  --anim-tag: cubic-bezier(0.86, 0, 0.07, 1);
  --anim-nav: 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  --anim-play-video-btn: cubic-bezier(0.16, 1, 0.3, 1);
}
@media screen and (max-width: 1024px) {
  :root {
    --container-padding: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --l-container-padding: 1.2rem;
  }
}

@keyframes reveal-in-from-bottom {
  0% {
    opacity: 0.1;
    transform: translate3d(0, 50px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}
@keyframes reveal-in-from-top {
  0% {
    transform: translate3d(0, -50px, 0);
    opacity: 0.1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 0.99;
  }
}
@keyframes header-reveal {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
  100% {
    transform: translate3d(0, -50px, 0);
    opacity: 0.1;
  }
}
@keyframes fade-in-scale-down {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animate-third-circle {
  0%, 50% {
    transform: translate(-50%, -50%) scale(1);
  }
  50%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes animate-second-circle {
  0%, 20% {
    transform: translate(-50%, -50%) scale(1);
  }
  80%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes animate-first-circle {
  0%, 40% {
    transform: translate(-50%, -50%) scale(1);
  }
  90%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes nav-link-fade {
  0% {
    opacity: 0;
    transform: translateX(5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
:root {
  --header-height: 6.4rem;
  --input-height: 4.4rem;
  --container-padding: 4rem;
  --l-container-padding: 3.2rem;
  --font-barlow: "Barlow Semi Condensed", sans-serif;
  --font-forum: "Forum", cursive;
  --font-jost: "Jost", sans-serif;
  --font-sora: "Sora", sans-serif;
  --font-tomorrow: "Tomorrow", sans-serif;
  --font-trirong: "Trirong", sans-serif;
  --font-ibm-plex-sans: "IBM Plex Sans", sans-serif;
  --primary-font: var(--font-jost);
  --hero-radius: 0;
  --wrapper-radius: 0;
  --image-radius: 0;
  --elements-radius: 0;
  --buttons-radius: 0;
  --basic-overview-radius: 0;
  --accent-color: #f59f00;
  --hero-tag-color: #0ca678;
  --error-color: #fa5252;
  --main-color: #141414;
  --text-color: #757575;
  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --gray-10: #333333;
  --gray-11: #666666;
  --luxury-heading: #282422;
  --luxury-black: #0F0E0D;
  --luxury-gray: #F7F6F5;
  --luxury-platinum: #A69F91;
  --luxury-platinum-100: #EBEAE6;
  --luxury-platinum-200: #D9D6CF;
  --luxury-platinum-300: #C0BCB1;
  --sections-shadow: 0 3.2rem 4.8rem rgba(0, 0, 0, 0.15);
  --anim-reveal-y: 60%;
  --anim-slide-x: 100%;
  --anim-slide-y: 100%;
  --anim-default-duration: 0.5s;
  --anim-small-duration: 0.35s;
  --anim-medium-duration: 0.7s;
  --anim-long-duration: 0.9s;
  --anim-very-long-duration: 1.5s;
  --anim-easing-in: cubic-bezier(0.33, 1, 0.68, 1);
  --anim-easing-out: cubic-bezier(0.32, 0, 0.67, 0);
  --anim-easing-in-out: cubic-bezier(0.65, 0, 0.35, 1);
  --anim-btn: cubic-bezier(1, 0, 0, 1);
  --anim-tag: cubic-bezier(0.86, 0, 0.07, 1);
  --anim-nav: 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  --anim-play-video-btn: cubic-bezier(0.16, 1, 0.3, 1);
}
@media screen and (max-width: 1024px) {
  :root {
    --container-padding: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --l-container-padding: 1.2rem;
  }
}

@keyframes reveal-in-from-bottom {
  0% {
    opacity: 0.1;
    transform: translate3d(0, 50px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}
@keyframes reveal-in-from-top {
  0% {
    transform: translate3d(0, -50px, 0);
    opacity: 0.1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 0.99;
  }
}
@keyframes header-reveal {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
  100% {
    transform: translate3d(0, -50px, 0);
    opacity: 0.1;
  }
}
@keyframes fade-in-scale-down {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animate-third-circle {
  0%, 50% {
    transform: translate(-50%, -50%) scale(1);
  }
  50%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes animate-second-circle {
  0%, 20% {
    transform: translate(-50%, -50%) scale(1);
  }
  80%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes animate-first-circle {
  0%, 40% {
    transform: translate(-50%, -50%) scale(1);
  }
  90%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes nav-link-fade {
  0% {
    opacity: 0;
    transform: translateX(5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
html {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 10px;
  height: 100%;
  line-height: 1.2;
}
@media only screen and (min-width: 1921px) and (max-width: 6017px) {
  html {
    font-size: calc(10px + 26 * (100vw - 1921px) / 4096);
  }
}
@media only screen and (min-width: 1496px) and (max-width: 1921px) {
  html {
    font-size: calc(9px + 3 * (100vw - 1440px) / 481);
  }
}
@media only screen and (min-width: 1241px) and (max-width: 1496px) {
  html {
    font-size: calc(8px + 2 * (100vw - 1241px) / 199);
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1240px) {
  html {
    font-size: calc(8px + 2 * (100vw - 1025px) / 215);
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  html {
    font-size: calc(8px + 2 * (100vw - 768px) / 256);
  }
}
@media (max-width: 320px) {
  html {
    font-size: 10px;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.2;
  color: var(--text-color);
  font-style: normal;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
@media (max-width: 767px) {
  body {
    --sections-shadow: 0 2.4rem 3.2rem rgba(0, 0, 0, 0.15);
  }
  body.is-menu-open {
    overflow: hidden;
  }
}

.main-content {
  flex: 1;
}

.section {
  padding: 4.8rem 0;
}
@media (max-width: 768px) {
  .section {
    padding: 2.4rem 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 128rem;
  padding: 0 var(--container-padding);
  margin: 0 auto;
  height: 100%;
}
.container--large {
  max-width: 139.2rem;
}
.container--full {
  max-width: 100%;
}

.l-container {
  max-width: 122rem;
  margin: 0 auto;
  padding: 0 var(--l-container-padding);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: var(--main-color);
}

p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 4.8rem;
  line-height: 6rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  h1 {
    font-size: 3.2rem;
    line-height: 4.4rem;
  }
}

h2 {
  font-size: 4.8rem;
  line-height: 6rem;
  font-weight: 500;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  h2 {
    font-size: 3.2rem;
    line-height: 4.4rem;
  }
}

h3 {
  font-size: 3.2rem;
  line-height: 4.4rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  h3 {
    font-size: 2rem;
    line-height: 3.4rem;
  }
}

button {
  all: unset;
}

.b-title {
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--gray-9);
}
.b-title--centered {
  text-align: center;
}
.b-title--margin {
  margin-bottom: 2.4rem;
}

.l-title {
  font-family: var(--font-trirong);
  text-transform: unset;
  color: var(--luxury-heading);
}
.l-title--gray {
  color: var(--luxury-platinum-100);
}
.l-title--medium {
  font-size: 6rem;
  font-weight: 400;
  line-height: 12rem;
}
.l-title--large {
  font-size: 6.4rem;
  font-weight: 300;
  line-height: 6.8rem;
  letter-spacing: -2px;
}
@media (max-width: 768px) {
  .l-title {
    font-size: 4.8rem;
    line-height: 9.6rem;
  }
}

.l-subtitle {
  display: block;
  font-family: var(--font-ibm-plex-sans);
  font-size: 2.4rem;
  color: var(--luxury-heading);
}
@media (max-width: 768px) {
  .l-subtitle {
    font-size: 1.6rem;
  }
}
.l-subtitle--margin {
  margin-bottom: 0.8rem;
}

.l-label {
  font-family: var(--font-ibm-plex-sans);
  line-height: 2.4rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--luxury-heading);
  text-transform: uppercase;
}

.b-subtitle {
  display: inline-block;
  width: 100%;
  margin: 0.8rem 0 2.4rem;
  font-size: 2rem;
  color: var(--gray-6);
}
.b-subtitle--dotted {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  gap: 0.5rem;
}
.b-subtitle--dotted::before {
  content: "";
  display: block;
  height: 0.8rem;
  width: 0.8rem;
  background-color: var(--accent-color);
  border-radius: 50%;
}
.b-subtitle--colored {
  color: var(--accent-color);
}
.b-subtitle--centered {
  text-align: center;
}

.hero-wrapper {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.hero-wrapper--h-screen {
  height: 100vh;
}
@media (max-width: 768px) {
  .hero-wrapper {
    height: 600px;
  }
}

.block {
  display: block;
}

.button {
  position: relative;
  border-width: 0;
  outline: 0;
  border-radius: var(--buttons-radius, 0);
  background-color: var(--accent-color);
  color: #fff;
  padding: 1rem 1.6rem;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.04rem;
  font-weight: 600;
}
.button:after {
  user-select: none;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
  transition: opacity var(--anim-default-duration) var(--anim-easing-in);
  opacity: 0;
}
.button > * {
  position: relative;
  z-index: 2;
}
.button:hover:after {
  opacity: 1;
}

input,
textarea {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  font-family: var(--primary-font);
  border-radius: var(--elements-radius, 0);
  padding: 0 3rem 0 0.8rem;
  border: 1px solid #e2e2e2;
  height: var(--input-height, 0);
  transition: border-color var(--anim-default-duration) var(--anim-easing-in);
  outline: none;
}
input:hover,
textarea:hover {
  border-color: #afafaf;
}
input:focus,
textarea:focus {
  border-color: var(--accent-color);
}

input[type=radio],
input[type=checkbox] {
  width: 2rem;
  height: 2rem;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

textarea {
  resize: none;
  height: 8rem;
  padding: 1rem 3rem 1rem 0.8rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table tr td {
  font-size: 2rem;
  line-height: 3.4rem;
  font-weight: 500;
  padding: 1.2rem 0;
  border-bottom: 1px solid #e2e2e2;
}
@media (max-width: 767px) {
  table tr td {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}
table tr:last-of-type td {
  border-bottom: 0;
}

.tabs {
  max-width: 128rem;
  padding: 0 var(--container-padding);
  margin: 0 auto;
}
@media (max-width: 767px) {
  .tabs {
    max-width: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
}
.tabs__header {
  display: flex;
  flex-direction: row;
  position: relative;
}
.tabs__nav {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
@media (max-width: 767px) {
  .tabs__nav {
    padding: 0 var(--container-padding);
  }
}
.tabs__nav::-webkit-scrollbar {
  display: none;
}
.tabs__link {
  position: relative;
  display: inline-flex;
  flex: 1;
  cursor: pointer;
  padding: 0.9rem 1.2rem 1.3rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.04rem;
  color: var(--text-color);
  text-transform: uppercase;
  transition: color var(--anim-default-duration) var(--anim-easing-in);
  white-space: nowrap;
}
@media (max-width: 767px) {
  .tabs__link {
    padding: 1rem 0.8rem 1.3rem;
  }
}
.tabs__link.active {
  color: var(--main-color);
}
.tabs__link.active::before {
  opacity: 1;
  background-color: var(--accent-color);
}
.tabs__link::before {
  opacity: 0;
  content: "";
  position: absolute;
  z-index: 2;
  width: 100%;
  top: calc(100% - 3px);
  left: 0;
  height: 3px;
  background-color: #eee;
  transition: opacity var(--anim-default-duration) var(--anim-easing-in), background-color var(--anim-default-duration) var(--anim-easing-in), color var(--anim-default-duration) var(--anim-easing-in);
}
.tabs__link:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: calc(100% - 4px);
  width: 100%;
  height: 1px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
}
.tabs__link:hover {
  color: var(--main-color);
}
.tabs__link:hover::before {
  opacity: 1;
}
.tabs__body {
  display: block;
  font-size: 2.4rem;
  line-height: 3.8rem;
  font-weight: 400;
}
@media (max-width: 767px) {
  .tabs__body {
    padding: 0 var(--container-padding);
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}
.tabs__tab-content {
  display: none;
  width: 100%;
}
.tabs__tab-content.active {
  display: flex;
}

.documents-wrapper {
  background-color: #fafafa;
  border-radius: 0.8rem;
  padding: 0.4rem;
}
.documents-wrapper__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.8rem;
  background-color: #fff;
  margin-bottom: 0.2rem;
  border-radius: 0.4rem;
}
.documents-wrapper__row:last-of-type {
  margin-bottom: 0;
}
.documents-wrapper__row span {
  display: inline-flex;
  align-items: center;
  font-size: 2rem;
  line-height: 3.4rem;
  font-weight: 500;
  color: var(--main-color);
}
@media (max-width: 767px) {
  .documents-wrapper__row span {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}
.documents-wrapper__row span img {
  width: 2.8rem;
  height: 2.4rem;
  margin-right: 1.4rem;
}
.documents-wrapper__row a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: #fafafa;
}
.documents-wrapper__row a img {
  width: 1.3rem;
  height: 1.4rem;
}

.tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -1.25rem -1.2rem 0;
}
@media (max-width: 767px) {
  .tags {
    margin: 0 -0.8rem -0.8rem 0;
  }
}
.tags .tag {
  padding: 0.3rem 1.2rem;
  margin: 0 1.25rem 1.2rem 0;
  background-color: #f6f6f6;
  color: var(--main-color);
  font-size: 2rem;
  line-height: 3.4rem;
  font-weight: 400;
  border-radius: var(--elements-radius, 0);
}
@media (max-width: 767px) {
  .tags .tag {
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin: 0 0.8rem 0.8rem 0;
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.iframe-container--ratio {
  padding-top: 56.26%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.iframe-container--cover video {
  object-fit: cover;
}
.iframe-container video,
.iframe-container iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.form__container {
  text-align: center;
}
.form__container.is-hidden {
  display: none;
}
.form__title {
  margin-bottom: 1.6rem;
}
.form__row {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  margin-bottom: 2.4rem;
}
.form__row.double-column .form__column:first-of-type {
  margin-right: 0.4rem;
}
.form__row.double-column .form__column:last-of-type {
  margin-right: 0;
}
@media (max-width: 767px) {
  .form__row.double-column {
    margin-bottom: 0;
  }
  .form__row.double-column .form__column {
    margin-right: 0 !important;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 767px) {
  .form__row {
    flex-direction: column;
  }
}
.form__column {
  flex: 1;
  position: relative;
}
.form__column.has-error input,
.form__column.has-error textarea {
  border-color: var(--error-color);
}
.form__column.has-error .input-wrap:after {
  opacity: 1;
}
.form .input-wrap {
  position: relative;
}
.form .input-wrap:after {
  content: "";
  pointer-events: none;
  position: absolute;
  right: 1.2rem;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 1.65rem;
  height: 1.65rem;
  background: url("../assets/icons/error.svg") no-repeat center;
  background-size: 95% 95%;
  opacity: 0;
  transition: opacity var(--anim-default-duration) var(--anim-easing-in);
}
.form .row__messages {
  position: absolute;
  left: 0.8rem;
  top: calc(100% + 0.3rem);
  font-size: 1.2rem;
  color: var(--error-color);
}
.form .label {
  display: block;
  width: 100%;
  padding-left: 0.8rem;
  margin-bottom: 0.4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  font-family: var(--primary-font);
}
.form .success-view {
  display: none;
  text-align: center;
}
.form .success-view.is-visible {
  display: block;
}
.form .success-view h3 {
  margin-bottom: 1.6rem;
}
.form .success-view p {
  font-size: 2rem;
  line-height: 3.4rem;
}
.form .success-view p:last-of-type {
  margin-bottom: 1.6rem;
}

.marquee {
  position: relative;
  overflow: hidden;
  margin: -9.2rem 0 -8rem;
  user-select: none;
}
.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
}
.marquee span {
  white-space: nowrap;
  padding: 0 2rem;
  font-size: 14.4rem;
  line-height: 1.2;
  font-weight: 800;
  font-style: italic;
  color: #fafafa;
}
@media (max-width: 767px) {
  .marquee span {
    font-size: 7.2rem;
  }
}
@media (max-width: 767px) {
  .marquee {
    margin: -3.6rem 0 -5rem 0;
  }
}

.waypoint-animation {
  transition: transform var(--anim-medium-duration) var(--anim-easing-in), opacity var(--anim-medium-duration) var(--anim-easing-in);
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  will-change: transform, opacity;
}
.waypoint-animation.animate {
  animation-delay: var(--anim-medium-duration);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.pattern {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 200%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.burger {
  display: none;
}
@media (max-width: 768px) {
  .burger {
    display: grid;
    grid-template-rows: 1fr max-content 1fr;
    height: 1.6rem;
    width: 2.4rem;
    cursor: pointer;
  }
}
.burger:hover .burger__line {
  width: 100%;
}
.burger::before, .burger__line, .burger::after {
  content: "";
  width: 100%;
  height: 0.2rem;
  background-color: #ffffff;
}
.burger__line {
  width: 60%;
  justify-self: end;
  transition: var(--anim-default-duration) var(--anim-easing-in);
}
.burger::after {
  align-self: end;
}

.l-burger {
  z-index: 3;
  display: none;
  align-items: center;
  gap: 1rem;
  transition: var(--anim-default-duration) var(--anim-easing-in);
  padding: 1rem;
  margin-left: auto;
  background-color: var(--luxury-black);
  color: #ffffff;
  cursor: pointer;
}
.l-burger__icon {
  display: grid;
  justify-items: end;
  gap: 3px;
  padding: 6px 3px;
}
.l-burger__icon::before {
  width: 1.8rem;
}
.l-burger__icon::before, .l-burger__line, .l-burger__icon::after {
  content: "";
  display: block;
  transition: var(--anim-default-duration) var(--anim-easing-in);
  height: 0.2rem;
  background-color: #ffffff;
}
.l-burger__line {
  display: grid;
  gap: 3px;
}
.l-burger__line, .l-burger__icon::after {
  width: 1.2rem;
}
.l-burger__line {
  justify-self: end;
  transition: var(--anim-default-duration) var(--anim-easing-in);
}
.l-burger__text {
  transition: var(--anim-default-duration) var(--anim-easing-in);
  font-family: var(--font-ibm-plex-sans);
  font-size: 1.4rem;
  line-height: 2.8rem;
  font-weight: 600;
}
@media (max-width: 768px) {
  .l-burger {
    display: flex;
  }
}

.b-button, .b-button:link, .b-button:visited {
  position: relative;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s;
  border-radius: var(--buttons-radius, 0);
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
}
.b-button--accent {
  color: #ffffff;
  padding: 1rem 2rem;
  background-color: var(--accent-color);
}
.b-button--accent:hover span, .b-button--accent:active span {
  color: var(--accent-color);
}
.b-button--accent::before {
  background-color: #ffffff;
}
.b-button--bordered {
  color: var(--accent-color);
  padding: 1.2rem 3rem;
  border: solid 2px var(--accent-color);
}
.b-button--hovered:hover span, .b-button--hovered:active span {
  color: #ffffff;
}
.b-button--hovered::before {
  background-color: var(--accent-color);
}
.b-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.2s 0.4s, width 0.4s var(--anim-btn), background-color 0.1s;
  height: 100%;
  width: 3px;
  transform: scaleY(0);
}
.b-button:hover:before, .b-button:active:before {
  transform: scaleY(1);
  transition: transform 0.2s, width 0.4s var(--anim-btn) 0.2s, background-color 0.1s;
  width: 100%;
}
.b-button span {
  position: relative;
}

.preloader {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 10000;
  animation: animateBackground var(--anim-default-duration) linear;
  transition: all var(--anim-default-duration);
  height: 100%;
  width: 100%;
  backdrop-filter: blur(0.3rem);
  transition: all var(--anim-default-duration);
}
.preloader.hidden {
  opacity: 0;
  visibility: hidden;
}
.preloader__icon, .preloader__icon-box {
  width: 11.8rem;
  height: 11.8rem;
}
.preloader__icon-text {
  font-size: 2.4rem;
  color: var(--gray-9);
  letter-spacing: 0.1rem;
}
.preloader__icon-box {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
}
.preloader__icon-box img {
  background-color: var(--accent-color);
  border-radius: 50%;
  height: 11rem;
  width: 11rem;
  padding: 2rem;
}
.preloader__bg {
  transition: all var(--anim-default-duration);
  height: inherit;
  width: inherit;
  background: var(--gray-1);
}
.preloader__circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 16rem;
  width: 16rem;
}
.preloader__circle--1 {
  opacity: 0.4;
  animation: animate-first-circle 1.5s ease-out infinite;
  border: 0.3rem solid var(--accent-color);
}
.preloader__circle--2 {
  opacity: 0.4;
  animation: animate-second-circle 1.5s ease-out infinite;
  border: 0.2rem solid var(--accent-color);
}
.preloader__circle--3 {
  opacity: 0.4;
  animation: animate-third-circle 1.5s ease-out infinite;
  border: 0.1rem solid var(--accent-color);
}

.b-tabs {
  display: flex;
  padding-left: 1px;
  width: max-content;
  position: relative;
  border-radius: var(--buttons-radius);
  overflow: hidden;
}
.b-tabs--gray {
  background-color: var(--gray-1);
}
.b-tabs__subtitle {
  margin: unset;
}
.b-tabs__button {
  align-items: center;
  color: #767b82;
  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  line-height: 2rem;
  margin-left: -1px;
  max-width: 29rem;
  transition: color 0.3s, border-color 0.3s;
  cursor: pointer;
  background-color: transparent;
  padding: 1.2rem 6.2rem;
  border: unset;
}
.b-tabs__button.active {
  background-color: var(--accent-color);
  color: #ffffff;
}

.btn-arrow {
  transition: background-color 0.3s ease-in-out;
  width: 3.8rem;
  height: 3.8rem;
  border: 1.75px solid var(--gray-9);
  border-radius: 50%;
  display: block;
  position: relative;
  flex: 0 0 auto;
  cursor: pointer;
  z-index: 100;
}
.btn-arrow.swiper-button-disabled {
  opacity: 0.3;
  pointer-events: none;
}
.btn-arrow--next:hover .arrow::before, .btn-arrow--next:hover .arrow::after {
  right: 32%;
}
.btn-arrow--next:hover .arrow::after {
  left: 32%;
}
.btn-arrow--next .arrow::before {
  right: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.btn-arrow--next .arrow::after {
  left: -50%;
  right: 50%;
  transform: translateY(-50%);
}
.btn-arrow--prev:hover .arrow::before, .btn-arrow--prev:hover .arrow::after {
  left: 32%;
}
.btn-arrow--prev:hover .arrow::after {
  right: 32%;
}
.btn-arrow--prev .arrow::before {
  left: 50%;
  transform: translateY(-50%) rotate(228deg);
}
.btn-arrow--prev .arrow::after {
  left: 50%;
  right: -50%;
  transform: translateY(-50%);
}
.btn-arrow:hover {
  background-color: var(--gray-9);
}
.btn-arrow:hover .arrow::before, .btn-arrow:hover .arrow::after {
  border-color: #ffffff;
}
.btn-arrow:hover .arrow::before {
  opacity: 1;
}
.btn-arrow .arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.btn-arrow .arrow::before, .btn-arrow .arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  transition: opacity 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.3s ease-in-out, left 0.3s ease-in-out, right 0.3s ease-in-out;
}
.btn-arrow .arrow::before {
  width: 8px;
  height: 8px;
  border: 1.75px solid var(--gray-9);
  border-width: 1.75px 1.75px 0 0;
  opacity: 0;
}
.btn-arrow .arrow::after {
  border-top: 1.75px solid var(--gray-9);
}

.slider-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  margin-top: 1.6rem;
  position: relative;
}
.slider-buttons .slider-pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}
.slider-buttons .button-prev {
  justify-self: end;
}
.slider-buttons .button-start {
  justify-self: start;
}

.l-slider-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}
@media (max-width: 768px) {
  .l-slider-buttons--desktop {
    display: none;
  }
}
.l-slider-buttons--mobile {
  display: none;
  justify-content: space-between;
  margin-top: 2.4rem;
}
.l-slider-buttons--mobile .slider-pagination {
  order: 2;
  width: max-content;
}
.l-slider-buttons--mobile .l-btn-arrow-next {
  order: 3;
}
@media (max-width: 768px) {
  .l-slider-buttons--mobile {
    display: flex;
  }
}
.l-slider-buttons__pagination {
  display: flex;
  gap: 0.4rem;
  font-family: var(--font-ibm-plex-sans);
  font-weight: 600;
  line-height: 2.4rem;
  letter-spacing: -0.3rem;
  margin: 0.8rem;
}
.l-slider-buttons__pagination.swiper-pagination-fraction {
  color: var(--luxury-heading);
}

.video-player-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper-slide-active {
  z-index: 1;
}

.slider-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slider-header__buttons {
  background-color: #ffffff;
  margin-top: unset;
  z-index: 1000;
}
.slider-header .slider-buttons {
  margin-bottom: unset;
}
.slider-header .swiper-slide-active .b-subtitle {
  opacity: 1;
}

.swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.8rem;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.floorplans {
  position: relative;
}
.floorplans img {
  width: 100%;
}
.floorplans .hotspot {
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: #d0ebff;
  border-radius: 50%;
  border: solid 1px #d1d5db;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
@media (max-width: 767px) {
  .floorplans .hotspot {
    width: 16px;
    height: 16px;
  }
}

.map-container {
  position: relative;
  overflow: hidden;
  border-radius: var(--wrapper-radius, 0);
  max-width: 120rem;
  height: 60rem;
  cursor: pointer;
}
@media (max-width: 767px) {
  .map-container {
    height: 40rem;
  }
}
.map-container__cover, .map-container__interactive {
  height: 100%;
}
.map-container__cover {
  margin: 0 auto;
  background-size: cover;
  background-position: center center;
}

.title-address {
  color: #ffffff;
}
@media (max-width: 768px) {
  .title-address {
    text-align: center;
  }
}
.title-address span {
  display: block;
}

.zipcode {
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 3.6rem;
}

.theme-button {
  all: reset;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s;
  padding: 0.8rem 1.6rem;
  border: 0.1rem solid var(--accent-color);
  border-radius: var(--buttons-radius, 0);
  overflow: hidden;
  font-family: var(--font-ibm-plex-sans);
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  line-height: 2.8rem;
  color: #ffffff;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .theme-button {
    padding: 0.8rem 1.2rem;
  }
}
.theme-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale3d(0.7, 1, 1);
  transition: transform 0.4s, opacity 0.4s;
  width: 100%;
  height: 100%;
  background-color: var(--luxury-platinum-100);
}
.theme-button__icon {
  padding: 0.3rem 0.2rem;
}
.theme-button svg {
  z-index: 1;
}
@media (min-width: 768px) {
  .theme-button:hover {
    border: 0.1rem solid var(--luxury-gray);
    color: var(--accent-color);
  }
  .theme-button:hover::before {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.1);
  }
  .theme-button:hover svg {
    fill: var(--accent-color);
  }
}
.theme-button__text, .theme-button__icon {
  z-index: 1;
}
.theme-button__icon {
  width: 3rem;
  height: 2.4rem;
}
.theme-button--border-white {
  border: 0.1rem solid var(--luxury-gray);
}
.theme-button--border-white svg {
  fill: #ffffff;
}
.theme-button--border-accent {
  border: 0.1rem solid var(--accent-color);
  color: var(--accent-color);
}
.theme-button--border-accent:hover {
  border: 0.1rem solid var(--accent-color);
}
.theme-button--border-accent svg {
  fill: var(--accent-color);
}
.theme-button--accent {
  background-color: var(--accent-color);
}
.theme-button--accent svg {
  fill: var(--luxury-gray);
}
.theme-button--menu {
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3.6rem;
  width: 100%;
  padding: 1.6rem;
  background-color: #ffffff;
  color: var(--accent-color);
}
.theme-button--menu svg {
  fill: var(--accent-color);
}

.street {
  font-family: var(--font-trirong);
  font-weight: 300;
  font-size: 6rem;
  letter-spacing: -2px;
  line-height: 6rem;
}
@media (max-width: 768px) {
  .street {
    font-size: 3.6rem;
    line-height: 4.6rem;
  }
}
.street--small {
  display: none;
  font-size: 2rem;
  color: var(--luxury-gray);
  letter-spacing: 0;
}
@media (max-width: 768px) {
  .street--small {
    display: block;
  }
}

.section-header {
  display: grid;
  grid-template-columns: auto 1fr repeat(2, auto);
  align-items: center;
  gap: 2.4rem;
  margin-bottom: 3.2rem;
}
@media (max-width: 1024px) {
  .section-header {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;
  }
}
@media (max-width: 768px) {
  .section-header {
    margin-bottom: 1.6rem;
  }
}
.section-header--two-col {
  grid-template-columns: auto 1fr;
}
.section-header--three-col {
  grid-template-columns: auto 1fr auto;
}
.section-header--white::after {
  background-color: #ffffff;
}
.section-header .swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.section-header::after {
  content: "";
  grid-column: 2;
  grid-row: 1;
  width: 100%;
  height: 0.2rem;
  background-color: var(--luxury-platinum-200);
}

.l-btn-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  min-height: 4.4rem;
  min-width: 4.4rem;
  background-color: var(--accent-color);
  border: 0.1rem solid var(--accent-color);
  border-radius: var(--buttons-radius, 0);
}
@media (max-width: 768px) {
  .l-btn-arrow.swiper-button-disabled {
    opacity: 0;
  }
}
.l-btn-arrow--white {
  background-color: #ffffff;
  border: 0.1rem solid #ffffff;
}
.l-btn-arrow--white.swiper-button-disabled {
  opacity: 0;
}
.l-btn-arrow--white svg {
  fill: var(--accent-color);
}
@media (min-width: 768px) {
  .l-btn-arrow:hover {
    background-color: var(--luxury-platinum-100);
    border: 0.1rem solid var(--luxury-platinum-100);
  }
  .l-btn-arrow:hover svg {
    fill: var(--accent-color);
  }
}
.l-btn-arrow__icon {
  fill: var(--luxury-gray);
  height: 2rem;
}

.dot-pattern {
  display: block;
  width: 10rem;
  height: auto;
}

.arrow-btn-centered {
  z-index: 2;
  position: absolute;
  top: 50%;
  border-radius: var(--buttons-radius, 0);
  transform: translateY(-50%);
}
.arrow-btn-centered:nth-child(1) {
  left: var(--l-container-padding);
}
.arrow-btn-centered:nth-child(2) {
  right: var(--l-container-padding);
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.3s;
}

mux-player {
  height: 700px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  max-width: 100vw;
  padding: 0;
  height: var(--header-height);
  display: flex;
  align-items: center;
  background-color: #fff;
  opacity: 0;
  transform: translate3d(0, -50px, 0);
  will-change: transform, opacity;
  transform-style: preserve-3d;
  animation: var(--anim-medium-duration) var(--anim-easing-out) reveal-in-from-top forwards;
}
.header.overflow {
  overflow: hidden;
}
@media (max-width: 767px) {
  .header {
    padding: 1.2rem 0;
    align-items: flex-start;
  }
}
.header.is-hidden {
  animation-delay: 0s;
  animation: header-reveal var(--anim-medium-duration) var(--anim-easing-out) forwards;
}
.header.is-transparent {
  background-color: rgba(0, 0, 0, 0);
}
@media (max-width: 767px) {
  .header--is-menu-open {
    background-color: #fff;
  }
  .header--is-menu-open .nav {
    pointer-events: initial !important;
    opacity: 1 !important;
  }
  .header--is-menu-open .menu-button .menu-icon span {
    background-color: #afafaf;
  }
  .header--is-menu-open .menu-button .menu-icon span:nth-child(1) {
    top: 4px;
    width: 0%;
    left: 50%;
  }
  .header--is-menu-open .menu-button .menu-icon span:nth-child(2) {
    transform: rotate(45deg);
  }
  .header--is-menu-open .menu-button .menu-icon span:nth-child(3) {
    transform: rotate(-45deg);
  }
  .header--is-menu-open .menu-button .menu-icon span:nth-child(4) {
    top: 4px;
    width: 0%;
    left: 50%;
  }
}
.header__top-bar {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 13;
  height: 100%;
}
@media (max-width: 767px) {
  .header__top-bar {
    flex: 1 1 100%;
  }
}
.header__logo {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.75rem;
}
.header__logo img {
  display: block;
  max-height: 100%;
}
.header .container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
}
@media (max-width: 767px) {
  .header .container {
    align-items: flex-start;
  }
}
.header .menu {
  display: flex;
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0;
}
@media (max-width: 767px) {
  .header .menu {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--header-height);
  }
}
.header .menu-item {
  display: inline-flex;
  margin-right: 0.4rem;
}
.header .menu-item:last-of-type {
  margin-right: 0;
}
@media (max-width: 767px) {
  .header .menu-item {
    margin-right: 0;
    margin-bottom: 1.2rem;
  }
  .header .menu-item:last-of-type {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .header .nav {
    pointer-events: none;
    position: fixed;
    z-index: 10;
    top: var(--header-height);
    left: 0;
    width: 100%;
    height: calc(100vh - var(--header-height));
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity var(--anim-default-duration) var(--anim-easing-in);
    background-color: #fff;
  }
  .header .nav:after {
    content: "";
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-image: url("../assets/patterns/menu.svg");
    background-size: 125% 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 11;
    top: calc(var(--header-height) * -1);
    left: 0;
    pointer-events: none;
  }
}
.header .nav__link {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #333;
  font-weight: 500;
  letter-spacing: 0.055rem;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.8rem 1.2rem;
  border-radius: var(--buttons-radius, 0);
  transition: background-color var(--anim-default-duration) var(--anim-easing-in);
}
.header .nav__link:last-of-type {
  margin-right: 0;
}
.header .nav__link:hover, .header .nav__link.active {
  background-color: #f6f6f6;
}
.header .nav__link--accent {
  color: #fff;
  background-color: var(--accent-color);
}
.header .nav__link--accent:hover, .header .nav__link--accent.active {
  background-color: var(--accent-color);
}
.header .nav__link--accent:after {
  background-color: unset;
}
.header .menu-button {
  display: none;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: none;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  padding: 0;
}
@media (max-width: 767px) {
  .header .menu-button {
    display: flex;
  }
}
.header .menu-button .menu-icon {
  width: 12px;
  height: 10px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.header .menu-button .menu-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #757575;
  border-radius: 6px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.header .menu-button .menu-icon span:nth-child(1) {
  top: 0px;
}
.header .menu-button .menu-icon span:nth-child(2), .header .menu-button .menu-icon span:nth-child(3) {
  top: 4px;
}
.header .menu-button .menu-icon span:nth-child(4) {
  top: 8px;
}

.header menuitem:hover > menu {
  pointer-events: initial;
}
@media (max-width: 767px) {
  .header .custom-links-menu {
    display: none;
  }
}
@media (min-width: 768px) {
  .header .mobile-only {
    display: none;
  }
}

.custom-links-menu,
li.custom-links-menu * {
  margin: 0;
  padding: 0;
}

li.custom-links-menu menuitem {
  position: relative;
  display: block;
  opacity: 0;
  cursor: pointer;
}

li.custom-links-menu menuitem > menu {
  position: absolute;
  pointer-events: none;
}

li.custom-links-menu > menu {
  display: flex;
}

li.custom-links-menu > menu > menuitem {
  pointer-events: all;
  opacity: 1;
}

menu menuitem a {
  white-space: nowrap;
  display: block;
}

menuitem:hover > menu {
  pointer-events: initial;
}

menuitem:hover > menu > menuitem,
menu:hover > menuitem {
  opacity: 1;
}

li.custom-links-menu > menu > menuitem menuitem menu {
  transform: translateX(100%);
  top: 0;
  right: 0;
}

li.custom-links-menu a {
  transition: background 0.5s, color 0.5s, transform 0.5s;
  box-sizing: border-box;
  position: relative;
}

li.custom-links-menu > menu > menuitem > menu > menuitem {
  transition: transform 0.6s, opacity 0.6s;
  transform: translateY(150%);
  opacity: 0;
}

li.custom-links-menu > menu > menuitem:hover > menu > menuitem,
li.custom-links-menu > menu > menuitem.hover > menu > menuitem {
  transform: translateY(0%);
  opacity: 1;
}

.custom-links-menu__container {
  right: -1.2rem;
  padding-top: 1.5rem !important;
}

.custom-links-menu .link-item a {
  background-color: #fff;
  border-radius: 0;
  height: 4.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2.4rem;
  margin: 0 0 1px 0;
  text-transform: uppercase;
}
.custom-links-menu .link-item a:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.hero__section {
  --info-height: 4rem;
  position: relative;
  padding-top: var(--header-height);
  margin-bottom: 6.3rem;
}
@media (max-width: 767px) {
  .hero__section {
    margin-bottom: 3.2rem;
  }
}
.hero__pattern {
  left: 0;
  top: -36.5%;
  background-image: url("../assets/patterns/hero.svg");
}
@media (max-width: 767px) {
  .hero__pattern {
    display: none;
  }
}
@media (max-width: 768px) {
  .hero__container {
    padding: 0 0.8rem;
  }
}
.hero__wrapper {
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
  width: 100%;
  height: calc(100vh - var(--header-height));
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  box-shadow: var(--sections-shadow);
  animation: fade-in var(--anim-medium-duration) var(--anim-easing-out) forwards;
  will-change: opacity;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
}
.hero__wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}
@media (max-width: 768px) {
  .hero__wrapper {
    justify-content: center;
    width: initial;
    left: initial;
    transform: initial;
    height: 57.6rem;
  }
}
.hero__content {
  position: relative;
  z-index: 2;
  color: #fff;
  padding: 5.5rem 5.6rem;
  transform-style: preserve-3d;
  will-change: opacity, transform;
  transform: translate3d(0, 100%, 0);
  animation: reveal-in-from-bottom var(--anim-medium-duration) var(--anim-easing-out) forwards;
}
@media (max-width: 768px) {
  .hero__content {
    padding: 2.4rem 1.2rem;
    text-align: center;
  }
}
.hero__tag {
  display: inline-flex;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 0.4rem 1.2rem;
  text-transform: uppercase;
  border-radius: var(--hero-radius, 0);
  background-color: var(--hero-tag-color);
  margin-bottom: 0.8rem;
}
@media (max-width: 767px) {
  .hero__tag {
    margin-bottom: 1.2rem;
  }
}
.hero__address {
  max-width: 56rem;
  text-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .hero__address {
    max-width: initial;
  }
}
.hero__info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .hero__info {
    height: initial;
  }
}
.hero__info-item {
  padding: 1.6rem 1.2rem 0 1.2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}
@media (max-width: 767px) {
  .hero__info-item {
    flex: 0 0 50%;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
  .hero__info-item:first-of-type {
    flex: 0 0 100%;
    border-top: 0;
    border-right: 0;
  }
  .hero__info-item:nth-of-type(2n + 1) {
    border-right: 0;
  }
}
.hero__info-item:last-of-type {
  border-right: 0;
}
.hero__info-item span {
  font-size: 2rem;
  line-height: 3.4rem;
  font-weight: 500;
  color: #757575;
  align-self: flex-start;
}
@media (max-width: 767px) {
  .hero__info-item span {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
.hero__info-item strong {
  font-size: 3.2rem;
  line-height: 4.4rem;
  font-weight: 500;
  color: #141414;
  align-self: flex-end;
}
@media (max-width: 767px) {
  .hero__info-item strong {
    font-size: 2rem;
    line-height: 3.4rem;
  }
}
.hero__info-item .lot-size-unit {
  text-transform: capitalize;
}

#toggle-cover-video-sound {
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: 2;
  width: max-content;
  cursor: pointer;
}
@media (max-width: 768px) {
  #toggle-cover-video-sound {
    right: 0;
    bottom: 0;
  }
}
#toggle-cover-video-sound img {
  height: 50px;
}
#toggle-cover-video-sound .muted {
  display: none;
}
#toggle-cover-video-sound .unmuted {
  display: block;
}
#toggle-cover-video-sound[data-muted] .muted {
  display: block;
}
#toggle-cover-video-sound[data-muted] .unmuted {
  display: none;
}

.gallery__section {
  position: relative;
  z-index: 2;
  padding-bottom: 12.5rem;
}
@media (max-width: 767px) {
  .gallery__section {
    padding-bottom: 5.2rem;
  }
  .gallery__section .container {
    padding: 0 0.8rem;
  }
}
.gallery__groups-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.gallery__groups-view .gallery__group {
  width: calc(50% - 1.2rem);
  margin-bottom: 2.4rem;
  border-radius: var(--image-radius, 0);
  box-shadow: var(--sections-shadow);
  overflow: hidden;
  position: relative;
}
.gallery__groups-view .gallery__group:nth-child(2) {
  margin-top: 6.4rem;
}
.gallery__groups-view .gallery__group:nth-child(odd) {
  margin-top: -6.4rem;
}
.gallery__groups-view .gallery__group:first-of-type {
  margin-top: 0;
}
.gallery__groups-view .gallery__group:last-of-type {
  margin-bottom: 0;
}
.gallery__groups-view .gallery__group .gallery__item span {
  top: initial;
  right: initial;
  left: 2.4rem;
  bottom: 2.4rem;
}
.gallery__groups-view .gallery__group .gallery__item strong {
  top: initial;
  right: 2.4rem;
  left: initial;
  bottom: 2.4rem;
}
@media (max-width: 767px) {
  .gallery__groups-view .gallery__group {
    width: initial;
    margin-top: 0 !important;
    margin-bottom: 2.4rem !important;
  }
  .gallery__groups-view .gallery__group:last-of-type {
    margin-bottom: 0 !important;
  }
}
.gallery__shadow-wrapper {
  position: relative;
  z-index: 2;
  overflow: hidden;
  border-radius: var(--image-radius, 0);
  box-shadow: var(--sections-shadow);
}
@media (max-width: 767px) {
  .gallery__shadow-wrapper {
    background-color: #eee;
  }
}
.gallery__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .gallery__wrapper {
    flex-direction: column;
    margin-bottom: 0.8rem;
  }
}
.gallery__wrapper:last-of-type {
  margin-bottom: 0;
}
.gallery__wrapper--reverse .gallery__box:first-of-type {
  order: 2;
}
@media (max-width: 767px) {
  .gallery__wrapper--reverse .gallery__box:first-of-type {
    order: 0;
  }
}
.gallery__wrapper--reverse .gallery__box--main .gallery__item {
  margin-right: 0;
  margin-left: 2.4rem;
}
@media (max-width: 767px) {
  .gallery__wrapper--reverse .gallery__box--main .gallery__item {
    margin-left: 0;
    margin-bottom: 0.8rem;
  }
}
.gallery__box {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -1.2rem;
  margin-right: -1.2rem;
  margin-bottom: -2.4rem;
}
@media (max-width: 767px) {
  .gallery__box {
    margin-right: -0.4rem;
    margin-left: -0.4rem;
    margin-bottom: -0.8rem;
  }
}
.gallery__box .gallery__item {
  width: calc(50% - 2.4rem);
  max-height: 19.4rem;
  border-radius: var(--image-radius, 0);
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .gallery__box .gallery__item {
    width: calc(50% - 0.8rem);
    margin-bottom: 0.8rem;
    margin-right: 0.4rem;
    margin-left: 0.4rem;
    max-height: 10.5rem;
  }
  .gallery__box .gallery__item:nth-of-type(1n + 3) {
    display: none;
  }
}
.gallery__box--main {
  margin: 0 0 -2.4rem 0;
}
@media (max-width: 767px) {
  .gallery__box--main {
    margin: 0;
  }
}
.gallery__box--main .gallery__item {
  flex: 1;
  max-height: 41.2rem;
  margin-left: 0;
  margin-right: 2.4rem;
}
@media (max-width: 767px) {
  .gallery__box--main .gallery__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.8rem;
    max-height: 21.6rem;
  }
}
.gallery__item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.gallery__item:before, .gallery__item:after {
  opacity: 0;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.gallery__item::before {
  z-index: 4;
  background-image: url("../assets/icons/zoom.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 2.66rem 2.66rem;
}
.gallery__item::after {
  transition: opacity var(--anim-default-duration) var(--anim-easing-in);
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}
.gallery__item:hover:before, .gallery__item:hover:after {
  opacity: 1;
}
.gallery__item--with-info:after {
  opacity: 1;
}
.gallery__item--with-info::before {
  display: none;
}
.gallery__item strong,
.gallery__item span {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery__item strong {
  font-style: italic;
  z-index: 2;
  font-size: 14.4rem;
  line-height: 12rem;
  font-weight: 800;
  color: #fff;
  opacity: 0.4;
}
@media (max-width: 768px) {
  .gallery__item strong {
    font-size: 11rem;
  }
}
.gallery__item span {
  z-index: 3;
  font-size: 3.2rem;
  line-height: 4.4rem;
  font-weight: 500;
  color: #fff;
}
.gallery__item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery__result {
  display: none;
  cursor: pointer;
  padding: 1.2rem 1.1rem 1rem 1.1rem;
  border: 1px solid #e2e2e2;
  border-radius: var(--image-radius, 0);
  margin-top: 0.75rem;
}
@media (max-width: 767px) {
  .gallery__result {
    display: flex;
    justify-content: space-between;
  }
  .gallery__result span {
    font-size: 2rem;
    line-height: 3.2rem;
    color: #142414;
    font-weight: 500;
  }
  .gallery__result strong {
    font-size: 7.2rem;
    line-height: 6.4rem;
    font-weight: 800;
    font-style: italic;
    color: #f6f6f6;
  }
}

.overview__section {
  position: relative;
  z-index: 1;
  margin-bottom: 6.5rem;
}
@media (max-width: 767px) {
  .overview__section {
    margin-bottom: 3.2rem;
  }
}
.overview__section .container {
  position: relative;
  z-index: 2;
}
.overview__title {
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .overview__title {
    margin-bottom: 1.2rem;
  }
}
.overview__tabs .tabs__body {
  padding-top: 5.3rem;
}
@media (max-width: 767px) {
  .overview__tabs .tabs__body {
    padding-top: 2.1rem;
  }
}
.overview__tabs table td:nth-child(2) {
  color: var(--main-color);
}

.features__section {
  margin-bottom: 6.4rem;
}
@media (max-width: 767px) {
  .features__section {
    margin-bottom: 3.2rem;
  }
}
.features__section .tags {
  z-index: 1;
}
.features__title {
  margin-bottom: 2.4rem;
}

.video__section {
  position: relative;
  margin-bottom: 6.4rem;
}
@media (max-width: 767px) {
  .video__section {
    margin-bottom: 3.2rem;
  }
}
.video__section .container:not(:first-child) {
  margin-top: 4rem;
}
.video__pattern {
  left: 0;
  top: -38.7%;
  background-image: url("../assets/patterns/video.svg");
}
@media (max-width: 767px) {
  .video__pattern {
    display: none;
  }
}
.video__title {
  margin-bottom: 2.4rem;
}
.video__wrapper {
  border-radius: var(--wrapper-radius, 0);
  box-shadow: var(--sections-shadow);
  overflow: hidden;
}
.video__player-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.tour__section {
  position: relative;
  z-index: 2;
  margin-bottom: 12.4rem;
}
@media (max-width: 767px) {
  .tour__section {
    margin-bottom: 5.2rem;
  }
}
.tour__section .container:not(:first-child) {
  margin-top: 4rem;
}
.tour__title {
  margin-bottom: 2.4rem;
}
.tour__wrapper {
  border-radius: var(--wrapper-radius, 0);
  box-shadow: var(--sections-shadow);
  overflow: hidden;
}
.tour__wrapper--shadow-none {
  box-shadow: unset;
}
.tour__wrapper main {
  background-color: none;
}
.tour__wrapper #loading-gui #loading-background {
  background-color: none;
}

.plans__section {
  position: relative;
  margin-bottom: 6.4rem;
}
.plans__section .container {
  position: relative;
  z-index: 1;
}
.plans__section .gallery__group {
  box-shadow: none;
}
@media (max-width: 767px) {
  .plans__section {
    margin-bottom: 3.2rem;
  }
  .plans__section:after {
    display: none;
  }
}
.plans__pattern {
  left: 0;
  top: -40.6%;
  background-image: url("../assets/patterns/plans.svg");
}
@media (max-width: 767px) {
  .plans__pattern {
    display: none;
  }
}
.plans__title {
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .plans__title {
    margin-bottom: 1.2rem;
  }
}
.plans__tabs .tabs__header {
  margin-bottom: 2.4rem;
}
@media (max-width: 767px) {
  .plans__tabs .tabs__header {
    margin-bottom: 2.1rem;
  }
}
.plans__tabs .tabs__tab-content {
  padding: 2.4rem;
  border: 1px solid #e2e2e2;
  background-color: #fafafa;
  border-radius: var(--wrapper-radius, 0);
}
.plans__tabs .tabs__tab-content .gallery__group {
  background-color: #fff;
  border-radius: var(--image-radius, 0);
}
@media (max-width: 767px) {
  .plans__tabs .tabs__tab-content {
    background-color: #fff;
    padding: 0;
  }
}

.contacts__section {
  padding: 6.4rem 0 2.4rem;
  background-color: #fafafa;
}
@media (max-width: 767px) {
  .contacts__section {
    padding: 3.2rem 0 1.2rem;
  }
}
.contacts__header {
  margin-bottom: 2.3rem;
  text-align: center;
}
.contacts__sub-title {
  font-size: 2rem;
  line-height: 3.4rem;
}
@media (max-width: 767px) {
  .contacts__sub-title {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
.contacts__wrapper {
  padding: 2.4rem;
  border: 1px solid #e2e2e2;
  background-color: #fafafa;
  border-radius: var(--wrapper-radius, 0);
}
@media (max-width: 767px) {
  .contacts__wrapper {
    padding: 0;
    border: 0;
  }
}
.contacts__wrapper-row {
  display: flex;
  padding: 3rem 1.6rem var(--l-container-padding);
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: var(--image-radius, 0);
  margin-bottom: 2.4rem;
}
.contacts__wrapper-row:last-child {
  flex-direction: column;
  padding-top: 4.9rem;
  padding-bottom: 4.7rem;
}
@media (max-width: 767px) {
  .contacts__wrapper-row:last-child {
    padding: 2.6rem 1.6rem 2.4rem;
  }
}
.contacts__wrapper-row:last-of-type {
  margin-bottom: 0;
}
.contacts__wrapper-row .agents {
  width: calc(50% - 2.8rem);
}
@media (max-width: 767px) {
  .contacts__wrapper-row {
    flex-direction: column;
    padding: 3.2rem 1.6rem;
    margin-bottom: 1.2rem;
  }
  .contacts__wrapper-row .agents {
    width: initial;
    align-self: flex-start;
  }
}
.contacts__logos {
  width: calc(50% - 3.1rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contacts__logos img {
  max-width: 19rem;
  max-height: 9.6rem;
  margin-bottom: 3.2rem;
}
.contacts__logos img:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .contacts__logos {
    width: initial;
    margin-bottom: 5.6rem;
  }
}
.contacts__form {
  width: 100%;
  max-width: 58.8rem;
}
@media (max-width: 767px) {
  .contacts__form button[type=submit] {
    width: 100%;
  }
}

.agent {
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
}
.agent--rounded img {
  border-radius: 50%;
}
.agent--squared img {
  border-radius: 0.8rem;
  border: 1px solid #e2e2e2;
}
.agent img {
  width: 9.6rem;
  height: 9.6rem;
}
.agent:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .agent {
    flex-direction: column;
    align-items: flex-start;
  }
}
.agent img {
  margin-right: 1.6rem;
}
@media (max-width: 767px) {
  .agent img {
    margin-right: 0;
    margin-bottom: 1.6rem;
  }
}
.agent__info {
  display: flex;
  flex-direction: column;
}
.agent__company {
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 0.6rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .agent__company {
    margin-bottom: 0.7rem;
  }
}
.agent__personal {
  display: flex;
  margin-bottom: 0.1rem;
}
.agent__personal b {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-right: 0.8rem;
  font-weight: 600;
  color: var(--main-color);
}
.agent__personal span {
  display: inline-flex;
  border-radius: 0.4rem;
  padding: 0.2rem 0.6rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  background-color: #f6f6f6;
}
.agent__position {
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  line-height: 2rem;
}
.agent__contacts {
  color: var(--main-color);
}
.agent__contacts a {
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--main-color);
  text-decoration: none;
  font-weight: 600;
}

.map-section {
  padding-bottom: 6.4rem;
  background: #fafafa;
}
@media (max-width: 767px) {
  .map-section {
    padding-bottom: 3.2rem;
  }
}
.map-section.unbranded {
  padding-top: 6.4rem;
}
.map-section__description {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.6rem;
  margin: auto;
  background-color: #333;
  padding: 0.4rem 1.2rem;
  border-radius: var(--hero-radius, 0);
  text-transform: uppercase;
  font-weight: 500;
}
.map-section__description span {
  color: #fff;
  font-size: 1.4rem;
  line-height: 2rem;
}
@media (max-width: 767px) {
  .map-section__description {
    transform: none;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 90%;
    text-align: center;
    padding: 0.4rem 0.8rem;
  }
  .map-section__description span {
    display: block;
    max-width: 16rem;
    margin: 0 auto;
  }
}

:root {
  --header-height: 6.4rem;
  --input-height: 4.4rem;
  --container-padding: 4rem;
  --l-container-padding: 3.2rem;
  --font-barlow: "Barlow Semi Condensed", sans-serif;
  --font-forum: "Forum", cursive;
  --font-jost: "Jost", sans-serif;
  --font-sora: "Sora", sans-serif;
  --font-tomorrow: "Tomorrow", sans-serif;
  --font-trirong: "Trirong", sans-serif;
  --font-ibm-plex-sans: "IBM Plex Sans", sans-serif;
  --primary-font: var(--font-jost);
  --hero-radius: 0;
  --wrapper-radius: 0;
  --image-radius: 0;
  --elements-radius: 0;
  --buttons-radius: 0;
  --basic-overview-radius: 0;
  --accent-color: #f59f00;
  --hero-tag-color: #0ca678;
  --error-color: #fa5252;
  --main-color: #141414;
  --text-color: #757575;
  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --gray-10: #333333;
  --gray-11: #666666;
  --luxury-heading: #282422;
  --luxury-black: #0F0E0D;
  --luxury-gray: #F7F6F5;
  --luxury-platinum: #A69F91;
  --luxury-platinum-100: #EBEAE6;
  --luxury-platinum-200: #D9D6CF;
  --luxury-platinum-300: #C0BCB1;
  --sections-shadow: 0 3.2rem 4.8rem rgba(0, 0, 0, 0.15);
  --anim-reveal-y: 60%;
  --anim-slide-x: 100%;
  --anim-slide-y: 100%;
  --anim-default-duration: 0.5s;
  --anim-small-duration: 0.35s;
  --anim-medium-duration: 0.7s;
  --anim-long-duration: 0.9s;
  --anim-very-long-duration: 1.5s;
  --anim-easing-in: cubic-bezier(0.33, 1, 0.68, 1);
  --anim-easing-out: cubic-bezier(0.32, 0, 0.67, 0);
  --anim-easing-in-out: cubic-bezier(0.65, 0, 0.35, 1);
  --anim-btn: cubic-bezier(1, 0, 0, 1);
  --anim-tag: cubic-bezier(0.86, 0, 0.07, 1);
  --anim-nav: 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  --anim-play-video-btn: cubic-bezier(0.16, 1, 0.3, 1);
}
@media screen and (max-width: 1024px) {
  :root {
    --container-padding: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --l-container-padding: 1.2rem;
  }
}

@keyframes reveal-in-from-bottom {
  0% {
    opacity: 0.1;
    transform: translate3d(0, 50px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}
@keyframes reveal-in-from-top {
  0% {
    transform: translate3d(0, -50px, 0);
    opacity: 0.1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 0.99;
  }
}
@keyframes header-reveal {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
  100% {
    transform: translate3d(0, -50px, 0);
    opacity: 0.1;
  }
}
@keyframes fade-in-scale-down {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animate-third-circle {
  0%, 50% {
    transform: translate(-50%, -50%) scale(1);
  }
  50%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes animate-second-circle {
  0%, 20% {
    transform: translate(-50%, -50%) scale(1);
  }
  80%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes animate-first-circle {
  0%, 40% {
    transform: translate(-50%, -50%) scale(1);
  }
  90%, 100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}
@keyframes nav-link-fade {
  0% {
    opacity: 0;
    transform: translateX(5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
.footer__section {
  padding: 64px 24px 40px;
  background: #333333;
}
@media (max-width: 767px) {
  .footer__section {
    padding: 48px 16px 24px;
  }
}
.footer__section .wrapper {
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 24px;
}
@media (max-width: 767px) {
  .footer__section .wrapper {
    padding: 16px;
  }
}
.footer__section .address {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}
.footer__section .address__title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #AFAFAF;
  margin: 4px 0;
}
.footer__section .address__value {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin: 4px 0;
}
.footer__section .icons {
  display: flex;
  justify-content: center;
}
.footer__section .icons img {
  width: 56px;
  margin: 24px 16px 0;
}
.footer__section .logo {
  display: flex;
  justify-content: center;
}
.footer__section .logo img {
  max-height: 80px;
  margin-bottom: 4px;
}
.footer__section .agency {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  color: #AFAFAF;
  margin: 4px 0px;
}
.footer__section .agency a, .footer__section .agency a:hover, .footer__section .agency a:visited, .footer__section .agency a:active {
  color: #AFAFAF;
}

.dev-menu {
  position: fixed;
  top: var(--header-height, 0);
  left: 0;
  z-index: 101;
  transform: translate3d(-100%, 0, 0);
  transition: transform var(--anim-default-duration) var(--anim-easing-in);
}
.dev-menu.visible {
  transform: translate3d(0, 0, 0);
}
.dev-menu__container {
  display: flex;
  width: 30rem;
  height: 50rem;
  max-height: 100vh;
  overflow: auto;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: 0;
  border-bottom-right-radius: 1rem;
}
.dev-menu__title {
  padding: 0.5rem 1.5rem 0.5rem 0.4rem;
}
.dev-menu span {
  font-size: 2rem;
  font-weight: 600;
  color: var(--main-color);
}
.dev-menu p {
  font-size: 1.2rem;
}
.dev-menu__box {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 1.5rem 0.5rem 0.4rem;
}
.dev-menu__box:last-of-type {
  border-bottom: 0;
}
.dev-menu__row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.dev-menu label {
  display: block;
  padding: 1rem 0;
  flex: 1;
}
.dev-menu .color-box {
  width: 40%;
  height: 2rem;
}
.dev-menu__button {
  background-color: #fa5252;
  border-radius: 0;
  position: absolute;
  left: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.js-multi {
  padding-top: 3rem;
}

.js-single.hidden,
.js-multi.hidden {
  display: none;
}

.b-header {
  position: fixed;
  z-index: 1;
  padding: 2.4rem 0;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.6s, transform 0.6s, padding 0.6s;
  transform: translate3d(0, 0, 0);
  background: rgba(173, 181, 189, 0.95);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.b-header.is-hidden {
  background: unset;
  transform: translate3d(0, -100%, 0);
  box-shadow: unset;
}
.b-header.is-transparent {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: unset;
}
.b-header.loaded {
  animation: var(--anim-medium-duration) ease-in fade-in both;
}
.b-header__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 2.4rem;
  flex-direction: unset;
}
@media (max-width: 768px) {
  .b-header__btn {
    display: none !important;
  }
}
.b-header__menu-header {
  display: none;
}
@media (max-width: 768px) {
  .b-header__menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .b-header__menu-header span {
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
.b-header__close-nav {
  width: max-content;
  margin: unset;
}
@media (max-width: 768px) {
  .b-header__nav {
    position: fixed;
    top: 0;
    right: 0;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    transform: translate(100%);
    transition: var(--anim-nav);
    width: 100%;
    height: 100vh;
    padding: 3.2rem;
    background-color: #ffffff;
    clip-path: polygon(50% 0, 100% 0%, 100% 100%, 95% 100%);
  }
}
.b-header__nav.open {
  z-index: 3;
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.b-header__nav.open .b-header__list-link {
  color: var(--gray-9);
}
.b-header__nav.open .b-header__list-link.active {
  font-weight: 600;
}
.b-header__nav::before {
  content: "";
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
}
.b-header__logo {
  width: 20rem;
}
.b-header__btn {
  justify-self: end;
}
.b-header__list {
  display: flex;
  gap: 3rem;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.b-header__list .contacts {
  margin-top: 2.4rem;
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .b-header__list {
    flex-direction: column;
    gap: 1.8rem;
    margin-top: 2.4rem;
  }
}
.b-header__list-item {
  list-style: none;
}
@media (max-width: 768px) {
  .b-header__list-item {
    opacity: 0;
  }
}
.b-header__burger {
  width: 100px;
  height: 100px;
}
.b-header__list-link,
.b-header .custom-links-menu a {
  font-size: 1.8rem;
}
.b-header__list-link:link, .b-header__list-link:visited,
.b-header .custom-links-menu a:link,
.b-header .custom-links-menu a:visited {
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  color: #ffffff;
  transition: all var(--anim-default-duration);
}
@media (max-width: 768px) {
  .b-header__list-link,
.b-header .custom-links-menu a {
    gap: 1.8rem;
    color: var(--gray-9);
    font-size: 2.4rem;
    letter-spacing: 0.1rem;
    flex-direction: column;
    text-transform: uppercase;
  }
}
.b-header__list-link::after, .b-header__list-link::before,
.b-header .custom-links-menu a::after,
.b-header .custom-links-menu a::before {
  transition: transform var(--anim-small-duration) ease;
}
.b-header__list-link::after,
.b-header .custom-links-menu a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  transform: scaleX(0) translateY(0.2rem);
  transform-origin: 100% 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}
.b-header__list-link:hover::after, .b-header__list-link.active::after,
.b-header .custom-links-menu a:hover::after,
.b-header .custom-links-menu a.active::after {
  transform: scale(1) translateY(0.2rem);
  transform-origin: 0 0;
}
.b-header menuitem:hover > menu {
  pointer-events: initial;
}
@media (max-width: 767px) {
  .b-header .custom-links-menu {
    display: none;
  }
}
@media (min-width: 768px) {
  .b-header .mobile-only {
    display: none;
  }
}
.b-header .custom-links-menu a {
  font-size: 1.8rem;
  color: #ffffff;
}
.b-header .custom-links-menu__container menuitem:first-child::before {
  content: "";
  position: absolute;
  top: -1rem;
  right: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid #ffffff;
  width: 0;
  height: 0;
}
.b-header .custom-links-menu__container .link-item a {
  justify-content: center;
  margin: 0;
  color: var(--gray-8);
  text-transform: capitalize;
  text-decoration: none;
  transition: all var(--anim-default-duration);
  border-bottom: solid 0.2rem transparent;
  background-color: #fff;
}
.b-header .custom-links-menu__container .link-item a:hover, .b-header .custom-links-menu__container .link-item a:active {
  border-bottom: solid 0.2rem var(--accent-color);
}
.b-header .custom-links-menu__container .link-item a::before {
  display: none;
}
.b-header .custom-links-menu__container .link-item a::after {
  background-color: transparent;
}

.b-hero__container {
  display: grid;
  grid-template-rows: 1.2fr max-content 1fr;
  height: 100%;
  max-width: 100%;
}
.b-hero__title-block {
  grid-row: 2;
  opacity: 0;
}
.b-hero__title-block.loaded {
  animation: reveal-in-from-bottom var(--anim-medium-duration) backwards;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}
@media (max-width: 768px) {
  .b-hero__title-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.b-hero__street {
  font-size: 4.4rem;
  letter-spacing: -1px;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .b-hero__street {
    font-size: 3.2rem;
  }
}
@media (max-width: 768px) {
  .b-hero__address {
    font-size: 2.4rem;
    text-align: center;
  }
}
.b-hero__status-block {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: var(--hero-radius, 0);
  background-color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 0.8rem;
}
.b-hero__status-block span {
  height: 0.6rem;
  width: 0.6rem;
  border-width: 0.2rem;
  border-style: solid;
  border-radius: 0.3rem;
}
@media (max-width: 768px) {
  .b-hero__status-block span {
    display: none;
  }
}
.b-hero__status {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 700;
}
.b-hero__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-shadow: 0 0 3rem #000000;
  margin: 0 0 1.6rem 0;
  grid-row: 3;
  align-self: end;
  gap: 3rem;
  z-index: 2;
}
@media (max-width: 768px) {
  .b-hero__content {
    flex-direction: column;
    text-shadow: none;
    gap: 1.6rem;
  }
}
.b-hero__toggle-sound #toggle-cover-video-sound {
  bottom: 10rem;
  right: 0;
}
@media (max-width: 768px) {
  .b-hero__toggle-sound #toggle-cover-video-sound {
    transform: translateX(80px);
    bottom: 4rem;
  }
}
.b-hero__price-wrapper {
  position: relative;
}
.b-hero__price-block {
  opacity: 0;
  margin-top: 0.4rem;
}
.b-hero__price-block.loaded {
  animation: reveal-in-from-bottom var(--anim-medium-duration) backwards;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}
.b-hero__price-block span:first-child {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 1.2rem;
  color: #ffffff;
}
.b-hero__price-block span:first-child::before, .b-hero__price-block span:first-child::after {
  content: "";
  height: 0.1rem;
  background: #ffffff;
}
.b-hero__price-block span:last-child {
  display: block;
  width: max-content;
  color: #ffffff;
  font-size: 3.2rem;
  letter-spacing: 0.2rem;
}
.b-hero__about {
  color: var(--gray-9);
  font-size: 4rem;
  line-height: 1.2;
  max-width: 99.4rem;
  font-weight: 300;
  letter-spacing: 2px;
}
.b-hero__details {
  margin: 3.2rem;
}
@media (max-width: 1024px) {
  .b-hero__details {
    margin: 0 var(--container-padding);
  }
}
.b-hero__details-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.2rem;
  border-radius: var(--image-radius, 0);
  box-shadow: 0 2px 1.6rem #a0a0a01a;
  padding: 2.4rem;
}
@media (max-width: 1024px) {
  .b-hero__details-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }
}
.b-hero__details-item {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1.6rem;
  row-gap: 0.8rem;
  transition: all var(--anim-default-duration);
}
.b-hero__details-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row: 1/3;
  align-self: center;
  width: 7.4rem;
  height: 7.4rem;
  border: solid 0.1rem var(--gray-6);
  border-radius: 50%;
}
.b-hero__details-wrap img {
  height: 3.6rem;
  width: 3.6rem;
}
.b-hero__details-title {
  color: var(--gray-7);
  align-self: end;
  font-weight: 300;
  font-size: 1.6rem;
  letter-spacing: 1px;
}
.b-hero__details-desc {
  color: var(--gray-9);
  font-weight: 500;
  font-size: 2.4rem;
}

.b-gallery__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.b-gallery__item {
  cursor: zoom-in;
  transition: all 0.4s;
  overflow: hidden;
  border-radius: var(--image-radius, 0);
  height: 19.2rem;
}
@media (max-width: 768px) {
  .b-gallery__item {
    height: 32rem;
  }
}
.b-gallery__item:hover {
  transform: scale(1.01);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.b-gallery__item img {
  border-radius: var(--image-radius, 0);
  transition: all var(--anim-default-duration);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.b-gallery__title {
  font-size: 3.6rem;
  text-align: center;
  margin-bottom: 2.4rem;
  font-weight: 600;
}
.b-gallery__group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.2rem;
  width: 100%;
}
.b-gallery__btn {
  margin-top: 3.2rem;
}

@media (max-width: 768px) {
  .b-overview .b-title {
    text-align: center;
  }
}
.b-overview__text {
  font-size: 2.1rem;
  line-height: 1.7;
  color: var(--gray-9);
}
@media (max-width: 768px) {
  .b-overview__text {
    text-align: center;
  }
}
.b-overview__wrapper--grid {
  display: grid;
  grid-template-columns: 52rem auto;
  gap: 6.2rem;
}
@media (max-width: 1024px) {
  .b-overview__wrapper--grid {
    display: unset;
  }
}
.b-overview__wrapper--grid .b-overview__content {
  margin-top: 4.4rem;
}
@media (max-width: 1024px) {
  .b-overview__wrapper--grid .b-overview__content {
    margin-top: 3.2rem;
  }
}
@media (max-width: 768px) {
  .b-overview__wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}
.b-overview__swiper {
  cursor: zoom-in;
  height: 45rem;
  border-radius: 0 var(--basic-overview-radius, 0) 0 0;
  overflow: hidden;
  margin-top: 3.2rem;
  box-shadow: 5rem 5rem 10rem rgba(34, 34, 34, 0.15);
  overflow: hidden;
}
@media (max-width: 1024px) {
  .b-overview__swiper {
    border-radius: var(--image-radius, 0);
  }
}
@media (max-width: 768px) {
  .b-overview__swiper {
    height: 30rem;
  }
}
.b-overview__swiper-top .swiper-slide img {
  object-fit: unset;
}
@media (max-width: 768px) {
  .b-overview__swiper-top .swiper-slide img {
    object-fit: cover;
  }
}
.b-overview__swiper-thumbs .swiper-slide img {
  cursor: pointer;
  transition: all 0.3s;
  height: 8.3rem;
}
@media (max-width: 1024px) {
  .b-overview__swiper-thumbs .swiper-slide img {
    border-radius: var(--image-radius, 0);
  }
}
.b-overview__swiper-thumbs .swiper-slide img:hover {
  transform: scale(1.04);
}
.b-overview__button {
  margin-left: auto;
  margin-right: 3.2rem;
}
.b-overview__tabs-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
}
@media (max-width: 768px) {
  .b-overview__tabs-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
  }
}
.b-overview__tabs-content {
  margin-right: 3.2rem;
  margin-top: 2.4rem;
}
.b-overview__tabs-content table tr:not(:first-child) td {
  padding-top: 1.4rem;
}
.b-overview__tabs-content table td {
  padding: 0;
}
.b-overview__tabs-content table td:nth-child(2) {
  color: var(--gray-9);
}
.b-overview__documents {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  gap: 0.8rem;
  color: var(--gray-9);
  margin-left: auto;
  margin-right: 3.2rem;
}
@media (max-width: 768px) {
  .b-overview__documents {
    margin-left: unset;
  }
}
.b-overview__documents span {
  font-size: 1.8rem;
}

.pop-up {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all var(--anim-default-duration);
}
.pop-up__bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(24, 24, 24, 0.4);
  cursor: pointer;
}
.pop-up.opened {
  opacity: 1;
  visibility: visible;
}
.pop-up.opened .pop-up__content {
  transform: scale(1);
}
.pop-up__icon {
  position: absolute;
  right: 1.6rem;
  top: 1.4rem;
  font-weight: bold;
  font-style: normal;
  cursor: pointer;
}
.pop-up__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 4rem 3.2rem;
  border-radius: 2rem;
  background-color: #ffffff;
  transform: scale(0.25);
  transition: all var(--anim-default-duration);
  border-top: 6px solid var(--accent-color);
}
.pop-up__content .documents-wrapper__row {
  gap: 0.8rem;
}
.pop-up__content .documents-wrapper__row span {
  font-size: 1.8rem;
  font-weight: 400;
}
.pop-up__title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
  color: var(--gray-9);
}
.pop-up__paragraph {
  text-align: center;
  line-height: 1.4;
}
.pop-up__paragraph span {
  background-color: gray;
  border-radius: 50%;
  padding: 0 0.4rem;
  cursor: pointer;
  transition: all var(--anim-default-duration);
}
.pop-up__paragraph span:hover {
  background-color: #676767;
}

.b-features__title {
  font-size: 3.6rem;
  margin-bottom: 2.4rem;
  font-weight: 600;
  text-align: center;
}
.b-features__icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  padding: 0.5rem;
  transition: all var(--anim-default-duration);
  border: 1px solid var(--gray-4);
}
.b-features__details-icon {
  display: inline-block;
  transform: rotate(45deg) translateX(-2px) translateY(-2px);
  height: 1.6rem;
  width: 0.8rem;
  border-bottom: 2px solid var(--gray-9);
  border-right: 2px solid var(--gray-9);
  transition: all 0.8s;
}
.b-features__background {
  width: 50rem;
  height: 50rem;
  position: relative;
  overflow: hidden;
  margin: -9.2rem 0 -8rem;
  user-select: none;
}
.b-features__tags {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  border: solid 1px var(--gray-0);
}
.b-features__tag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.6rem;
  border: solid 1px var(--gray-0);
  padding: 3.2rem;
  overflow: hidden;
  transition: all 0.8s var(--anim-tag);
}
.b-features__tag:hover {
  transform: scale(1.05);
  border: solid 1px #ffffff;
}
.b-features__tag:hover::after {
  transform: scale(80);
}
.b-features__tag:hover figcaption {
  color: var(--accent-color);
}
.b-features__tag:hover .b-features__icon-wrap {
  border: solid 1px var(--accent-color);
}
.b-features__tag:hover .b-features__details-icon {
  border-bottom: 2px solid var(--accent-color);
  border-right: 2px solid var(--accent-color);
}
.b-features__tag::after {
  content: "";
  position: absolute;
  right: -6rem;
  top: -6rem;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  background-color: var(--gray-0);
  transition: all 0.8s var(--anim-tag);
  z-index: -1;
}
.b-features__tag figcaption {
  color: var(--gray-9);
  font-weight: 500;
  transition: all var(--anim-default-duration);
  text-align: center;
}

.b-video__wrapper {
  border-radius: var(--wrapper-radius, 0);
  overflow: hidden;
}
.b-video .react-player__preview {
  position: relative;
}
.b-video .react-player__preview::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
}
.b-video .react-player__play-icon {
  position: absolute;
  display: grid;
  align-items: center;
  overflow: hidden;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) scale(1);
  background-color: #ffffff;
  color: #46484b;
  color: var(--grey50);
  text-align: center;
  font-size: 1.8rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.6s var(--anim-play-video-btn);
  will-change: transform;
  z-index: 100;
  border-style: unset !important;
  border-width: unset !important;
  border-color: unset !important;
  margin-left: unset !important;
}
@media (max-width: 768px) {
  .b-video .react-player__play-icon {
    width: 10rem;
    height: 10rem;
  }
}
.b-video .react-player__play-icon:hover {
  transform: translateX(-50%) translateY(-50%) scale(1.12);
  background-color: var(--accent-color);
  transition: all 0.6s var(--anim-play-video-btn);
}
.b-video .react-player__play-icon:hover::before, .b-video .react-player__play-icon:hover::after {
  transform: translateY(-7rem) scale(0.88);
}
@media (max-width: 768px) {
  .b-video .react-player__play-icon:hover::before, .b-video .react-player__play-icon:hover::after {
    transform: translateY(-6rem) scale(0.88);
  }
}
.b-video .react-player__play-icon::before, .b-video .react-player__play-icon::after {
  content: "Play video";
  font-family: inherit;
  transition: all 0.6s var(--anim-play-video-btn);
  font-weight: 500;
}
.b-video .react-player__play-icon::before {
  display: block;
  transform: translateY(0) scale(1);
}
.b-video .react-player__play-icon::after {
  color: #ffffff;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.b-plans__img {
  border: 1px solid var(--gray-2);
  background-color: #f8f9fa;
  border-radius: var(--wrapper-radius, 0);
}
.b-plans__container {
  position: relative;
  z-index: 1;
}

.b-contacts__title {
  margin-bottom: unset;
}
.b-contacts__agents {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  border-radius: var(--image-radius, 0);
  padding: 2.4rem;
  margin-bottom: 3.2rem;
  box-shadow: 0 2px 1.6rem #a0a0a01a;
}
.b-contacts__agent-wrap {
  display: grid;
  align-items: center;
  justify-content: space-evenly;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 1024px) {
  .b-contacts__agent-wrap:not(:last-child) {
    border-bottom: 1px solid var(--gray-1);
    padding-bottom: 2.4rem;
  }
}
.b-contacts__agent-wrap .img-branding {
  max-width: 19rem;
  max-height: 9.6rem;
  justify-self: center;
}
@media (max-width: 768px) {
  .b-contacts__agent-wrap {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}
@media (max-width: 1024px) {
  .b-contacts__agent-wrap .agent {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .b-contacts__agent-wrap .agent__personal {
    justify-content: center;
  }
}
.b-contacts__form {
  display: flex;
  align-items: center;
  padding: 4.9rem;
  border-radius: var(--image-radius, 0);
  box-shadow: 0 2px 1.6rem #a0a0a01a;
}
.b-contacts__form .form__container {
  max-width: 65rem;
  width: 100%;
}

.b-footer {
  background-color: var(--gray-7);
}
.b-footer__container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.b-footer__icons {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.b-footer__icons img {
  margin-bottom: 1.6rem;
  padding: 1.6rem 0;
}
.b-footer__brokerage {
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
}
.b-footer__text {
  text-align: center;
}
.b-footer__text span:first-child, .b-footer__text a:first-child {
  color: var(--gray-6);
}
.b-footer__text span:last-child, .b-footer__text a:last-child {
  color: var(--gray-5);
}
.b-footer__owner--column {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.b-footer__owner img {
  display: block;
  text-align: center;
}

.l-preloader {
  pointer-events: none;
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: transform 0.8s var(--anim-easing-in-out);
  background-color: var(--accent-color);
  width: 100%;
  height: 100vh;
  color: #ffffff;
}
.l-preloader.loaded {
  transform: translateY(-101%);
  transition-delay: 0.4s;
}
.l-preloader.loaded .l-preloader__loader {
  opacity: 0;
}
.l-preloader__loader {
  animation: spin 2s linear infinite;
  transition: all 0.4s ease;
  border: 2px solid #ffffff;
  border-top-color: var(--accent-color);
  border-radius: 50%;
  height: 8rem;
  width: 8rem;
}

.l-header {
  position: fixed;
  z-index: 3;
  opacity: 0;
  width: 100%;
  padding: 2rem 8rem 2rem;
  transition: transform 0.6s, background 0.6s, opacity 0.6s;
  transition-delay: 1.2s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1024px) {
  .l-header {
    padding: 2rem 3.2rem;
  }
}
@media (max-width: 768px) {
  .l-header {
    padding: 0 1.2rem;
  }
}
.l-header.loaded {
  background-color: rgba(255, 255, 255, 0.95);
  opacity: 1;
}
.l-header.no-delay {
  transition-delay: unset;
}
.l-header.open {
  margin: 0;
}
.l-header.open::after {
  opacity: 0.95;
  pointer-events: auto;
}
.l-header.open .l-burger {
  height: 4.4rem;
  width: 4.4rem;
  background-color: rgba(255, 255, 255, 0.05);
}
.l-header.open .l-burger__text {
  display: none;
  transition: width 0.3s;
}
.l-header.open .l-burger__icon::before {
  transform: rotate(-45deg) translateY(4px) translateX(-3px);
}
.l-header.open .l-burger__icon::after {
  opacity: 0;
}
.l-header.open .l-burger__line {
  transform: rotate(45deg);
  width: 1.8rem;
}
.l-header.open:not(.is-transparent) .l-burger {
  margin-top: 1.2rem;
}
.l-header.open .l-header__nav {
  z-index: 3;
  transform: translateY(0);
}
.l-header.open .l-header__nav .b-header__list-link {
  color: var(--gray-9);
}
.l-header.open .l-header__nav .b-header__list-link.active {
  font-weight: 600;
}
@media (max-width: 768px) {
  .l-header:not(.is-transparent) {
    margin: 0;
    box-shadow: unset;
    background-color: unset;
  }
}
.l-header:not(.is-transparent) .l-header__list {
  text-shadow: unset;
}
.l-header:not(.is-transparent) .l-header__list-link:link, .l-header:not(.is-transparent) .l-header__list-link:visited,
.l-header:not(.is-transparent) .l-header .custom-links-menu a:link,
.l-header:not(.is-transparent) .l-header .custom-links-menu a:visited {
  transition-delay: 0.3s;
  color: var(--luxury-black);
  text-shadow: unset;
}
@media (max-width: 768px) {
  .l-header:not(.is-transparent) .l-header__list-link:link, .l-header:not(.is-transparent) .l-header__list-link:visited,
.l-header:not(.is-transparent) .l-header .custom-links-menu a:link,
.l-header:not(.is-transparent) .l-header .custom-links-menu a:visited {
    color: var(--luxury-gray);
  }
}
.l-header:not(.is-transparent) .l-header__list-link::after,
.l-header:not(.is-transparent) .l-header .custom-links-menu a::after {
  box-shadow: unset;
  background: var(--luxury-black);
}
@media (max-width: 768px) {
  .l-header:not(.is-transparent) .l-header__list-link::after,
.l-header:not(.is-transparent) .l-header .custom-links-menu a::after {
    background: #ffffff;
  }
}
.l-header:not(.is-transparent) .theme-button--border-white {
  transition-delay: 0.3s;
  border: 0.1rem solid var(--accent-color);
  color: var(--accent-color);
}
.l-header:not(.is-transparent) .theme-button--border-white svg {
  transition-delay: 0.3s;
  fill: var(--accent-color);
}
.l-header.is-hidden {
  transform: translate3d(0, -100%, 0);
  box-shadow: unset;
  background-color: unset;
}
.l-header.is-hidden .l-header__list-link::after,
.l-header.is-hidden .l-header .custom-links-menu a::after {
  transition-delay: 0.3s;
}
.l-header.is-transparent {
  position: absolute;
  margin: 4rem 0 0;
  box-shadow: unset;
  padding: 4rem 8rem 2rem;
  background-color: unset;
}
@media (max-width: 1024px) {
  .l-header.is-transparent {
    margin: 2rem 0 0;
    padding: 2rem 3.2rem;
  }
}
@media (max-width: 768px) {
  .l-header.is-transparent {
    margin: 1.2rem 0 0;
    padding: 0 1.2rem;
  }
}
.l-header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: unset;
  flex-wrap: wrap;
  gap: 2.4rem;
}
.l-header__list {
  display: flex;
  gap: 0.8rem;
  list-style: none;
  margin: 0;
  width: 100%;
  padding: 0;
}
@media (max-width: 768px) {
  .l-header__list {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    margin-top: 3rem;
    height: calc(100% - 84px);
  }
}
.l-header__list-item {
  list-style: none;
  padding: 0.8rem 1.6rem;
  cursor: pointer;
}
.l-header__list-item:first-child {
  padding-left: unset;
}
@media (max-width: 768px) {
  .l-header__list-item.contacts {
    width: 100%;
    margin-top: auto;
  }
}
@media (max-width: 768px) {
  .l-header__list-item {
    opacity: 1;
    padding: 0;
  }
}
.l-header__list-link,
.l-header .custom-links-menu a {
  position: relative;
  display: block;
  font-size: 1.4rem;
  line-height: 2.8rem;
  font-weight: 600;
}
@media (max-width: 768px) {
  .l-header__list-link,
.l-header .custom-links-menu a {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 1.6rem;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 6rem;
  }
}
.l-header__list-link:link, .l-header__list-link:visited,
.l-header .custom-links-menu a:link,
.l-header .custom-links-menu a:visited {
  text-decoration: none;
  text-transform: capitalize;
  font-family: var(--font-ibm-plex-sans);
  color: #ffffff;
}
@media (max-width: 768px) {
  .l-header__list-link:link, .l-header__list-link:visited,
.l-header .custom-links-menu a:link,
.l-header .custom-links-menu a:visited {
    color: var(--luxury-gray);
  }
}
.l-header__list-link:link.active, .l-header__list-link:visited.active,
.l-header .custom-links-menu a:link.active,
.l-header .custom-links-menu a:visited.active {
  color: #ffffff;
}
.l-header__list-link::after,
.l-header .custom-links-menu a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  margin: auto;
  width: 0%;
  color: transparent;
  background-color: #ffffff;
  height: 0.2rem;
  transition: var(--anim-small-duration) ease;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}
@media (max-width: 768px) {
  .l-header__list-link::after,
.l-header .custom-links-menu a::after {
    color: var(--luxury-gray);
    height: 0;
    width: 2px;
    margin: unset;
    position: initial;
    grid-column: 1;
    box-shadow: unset;
  }
}
.l-header__list-link:hover::after, .l-header__list-link.active::after,
.l-header .custom-links-menu a:hover::after,
.l-header .custom-links-menu a.active::after {
  width: 100%;
  opacity: 1;
}
@media (max-width: 768px) {
  .l-header__list-link:hover::after, .l-header__list-link.active::after,
.l-header .custom-links-menu a:hover::after,
.l-header .custom-links-menu a.active::after {
    height: 100%;
    width: 2px;
  }
}
.l-header__panel {
  display: flex;
  gap: 1.6rem;
}
@media (max-width: 768px) {
  .l-header__panel {
    display: none;
  }
}
@media (max-width: 768px) {
  .l-header__nav {
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateY(-110%);
    transition: transform 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 100%;
    height: 100vh;
    padding: 1.2rem 2rem;
    background-color: var(--accent-color);
  }
  .l-header__nav.resizing {
    transition: none;
  }
}
.l-header menuitem:hover > menu {
  z-index: 2;
  pointer-events: initial;
  transition-delay: 0.4s;
  transition: all 0.5s;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.2);
}
.l-header .custom-links-menu {
  padding: 0.8rem 1.6rem;
}
@media (max-width: 767px) {
  .l-header .custom-links-menu {
    display: none;
  }
}
@media (min-width: 768px) {
  .l-header .mobile-only {
    display: none;
  }
}
.l-header .custom-links-menu a {
  font-size: 1.4rem;
  color: #ffffff;
}
.l-header .custom-links-menu__container menuitem:first-child::before {
  content: "";
  position: absolute;
  top: -1rem;
  right: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid #ffffff;
  width: 0;
  height: 0;
}
.l-header .custom-links-menu__container .link-item a {
  display: flex;
  transition: all var(--anim-default-duration);
  margin: 0;
  color: var(--accent-color);
  text-shadow: unset;
  text-decoration: none;
  background-color: #ffffff;
}
.l-header .custom-links-menu__container .link-item a::after {
  background-color: var(--accent-color);
  box-shadow: unset;
}

.l-hero {
  padding: 4rem 4rem 8.4rem;
}
@media (max-width: 1024px) {
  .l-hero {
    padding: 2rem 2rem 8.4rem;
  }
}
@media (max-width: 768px) {
  .l-hero {
    padding: 1.2rem 1.2rem 6.4rem;
  }
}
.l-hero__hero-wrapper {
  position: relative;
  height: calc(100vh - 8rem);
}
.l-hero__hero-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  background: linear-gradient(187deg, rgba(0, 0, 0, 0) 39.19%, rgba(0, 0, 0, 0.72) 87.61%);
  height: 30%;
  width: 100%;
}
@media (max-width: 768px) {
  .l-hero__title-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.l-hero__price-block, .l-hero__status-block, .l-hero__toggle-sound {
  transition: all 0.6s ease-in-out;
  transition-delay: 1.2s;
  opacity: 0;
}
.l-hero__price-block.no-delay, .l-hero__status-block.no-delay, .l-hero__toggle-sound.no-delay {
  transition-delay: 0s;
}
.l-hero__price-block.loaded, .l-hero__status-block.loaded, .l-hero__toggle-sound.loaded {
  opacity: 1;
}
.l-hero__price-block {
  color: #ffffff;
}
@media (max-width: 768px) {
  .l-hero__price-block {
    opacity: 1;
  }
}
.l-hero__price-block span {
  display: block;
}
.l-hero__price-block span:first-child {
  font-family: var(--font-ibm-plex-sans);
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  line-height: 2.4rem;
}
.l-hero__price-block span:last-child {
  font-family: var(--font-trirong);
  font-size: 4rem;
  font-weight: 300;
  line-height: 4.8rem;
  letter-spacing: -1px;
}
@media (max-width: 768px) {
  .l-hero__price-block--desktop {
    display: none;
  }
}
.l-hero__price-block--mobile {
  display: none;
  padding: 2.4rem 1.2rem;
  color: var(--luxury-black);
}
@media (max-width: 768px) {
  .l-hero__price-block--mobile {
    display: inline-block;
  }
}
.l-hero__price-block--mobile span:first-child {
  text-align: left;
}
.l-hero__price-block--mobile span:last-child {
  font-size: 3.2rem;
}
.l-hero__state-zipcode {
  font-family: var(--font-ibm-plex-sans);
}
@media (max-width: 768px) {
  .l-hero__state-zipcode {
    font-size: 2rem;
    line-height: 3.6rem;
  }
}
.l-hero__status-block {
  display: inline-flex;
  align-items: center;
  border-radius: var(--hero-radius, 0);
  margin-bottom: 0.5rem;
  padding: 0.4rem 1.2rem;
  background-color: #ffffff;
  text-align: center;
  font-family: var(--font-ibm-plex-sans);
}
.l-hero__status {
  font-weight: 500;
  text-shadow: none;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .l-hero__status {
    font-size: 1.2rem;
  }
}
.l-hero__content {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  margin: auto 0 4rem 0;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}
@media (max-width: 1024px) {
  .l-hero__content {
    margin: auto 0 2rem 0;
  }
}
@media (max-width: 768px) {
  .l-hero__content {
    margin: auto 0 1rem 0;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;
  }
}
.l-hero__toggle-sound#toggle-cover-video-sound {
  bottom: 16rem;
  right: 2.4rem;
}
@media (max-width: 768px) {
  .l-hero__toggle-sound#toggle-cover-video-sound {
    right: 0;
    bottom: 0;
  }
}
.l-hero__details-wrap {
  position: relative;
  background-color: var(--luxury-gray);
  color: var(--luxury-heading);
  font-weight: 400;
}
@media (max-width: 768px) {
  .l-hero__details-wrap {
    margin: 0 -1.2rem;
  }
}
.l-hero__details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5rem;
  padding: 8rem 4rem;
}
@media (max-width: 1024px) {
  .l-hero__details {
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    gap: 4.8rem;
    padding: 6rem 3.2rem;
  }
}
@media (max-width: 768px) {
  .l-hero__details {
    padding: 6rem 2.4rem;
  }
}
.l-hero__details-item {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 2rem;
}
.l-hero__details-icon {
  height: 2.4rem;
  width: 2.3rem;
  align-self: center;
}
.l-hero__details-icon--custom {
  background-color: var(--luxury-heading);
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem;
}
.l-hero__details-title {
  grid-column: 2;
  font-family: var(--font-ibm-plex-sans);
  font-weight: 300;
  line-height: 2.4rem;
}
.l-hero__details-desc {
  grid-column: 2;
  grid-row: 1;
  font-family: var(--font-trirong);
  font-size: 4rem;
  letter-spacing: -1px;
  line-height: 6rem;
}
.l-hero__pattern {
  position: absolute;
  right: var(--l-container-padding);
  bottom: 0;
  transform: translateY(50%);
}
@media (max-width: 1024px) {
  .l-hero__pattern {
    right: 1.2rem;
  }
}

.l-gallery {
  padding: 8.4rem 0 10rem;
}
@media (max-width: 768px) {
  .l-gallery {
    padding: 6.4rem 0 5rem;
  }
}
.l-gallery__header {
  margin-bottom: 4.2rem;
}
.l-gallery__swiper {
  position: relative;
}
.l-gallery__slide.swiper-slide {
  height: 62.6rem;
  cursor: pointer;
}
@media (max-width: 768px) {
  .l-gallery__slide.swiper-slide {
    height: 48rem;
  }
}
.l-gallery__arrow-btn.swiper-button-disabled {
  opacity: 0;
}

.l-overview {
  padding: 10rem 0 9rem;
}
@media (max-width: 768px) {
  .l-overview {
    padding: 5rem 0 4rem;
  }
}
.l-overview__container {
  position: relative;
  display: flex;
}
@media (max-width: 1024px) {
  .l-overview__container {
    min-height: 56.2rem;
  }
}
@media (max-width: 768px) {
  .l-overview__container {
    flex-direction: column;
    min-height: unset;
  }
}
.l-overview__container::before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -84%);
  background-color: var(--luxury-heading);
  width: 0.2rem;
  height: 30rem;
}
@media (max-width: 768px) {
  .l-overview__container::before {
    transform: translate(-50%, -50%);
  }
}
.l-overview__title {
  margin-bottom: 1.6rem;
  width: 100%;
}
@media (max-width: 768px) {
  .l-overview__title {
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 4rem;
    letter-spacing: -1px;
  }
}
.l-overview__paragraph {
  font-family: var(--font-ibm-plex-sans);
  line-height: 4rem;
  font-weight: 400;
  font-size: 2rem;
  color: var(--gray-10);
}
@media (max-width: 768px) {
  .l-overview__paragraph {
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
}
.l-overview__table {
  margin: 4rem 0;
}
.l-overview__content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 8rem 9rem 8rem;
  background-color: var(--luxury-gray);
}
@media (max-width: 1024px) {
  .l-overview__content {
    padding: 8rem 5rem;
  }
}
@media (max-width: 768px) {
  .l-overview__content {
    flex-direction: column;
    padding: 17.8rem 2.8rem 2.8rem;
    min-height: unset;
  }
}
.l-overview__image-wrapper {
  flex: 0 0 45rem;
}
@media (max-width: 1024px) {
  .l-overview__image-wrapper {
    flex: 0 0 35rem;
  }
}
@media (max-width: 768px) {
  .l-overview__image-wrapper {
    flex: unset;
    height: 65rem;
  }
}
.l-overview__image {
  display: block;
  height: 100%;
  object-fit: cover;
}
.l-overview__table-row {
  padding: 0.4rem 0;
}
.l-overview__table-row:last-of-type td {
  border-bottom: 1px solid var(--luxury-platinum-100);
}
@media (max-width: 768px) {
  .l-overview__table-row:last-of-type td {
    border-bottom: unset;
  }
}
@media (max-width: 768px) {
  .l-overview__table-row:last-child {
    border-bottom: 1px solid var(--luxury-platinum-100);
  }
}
.l-overview__table-data {
  font-family: var(--font-trirong);
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--luxury-heading);
}
@media (max-width: 768px) {
  .l-overview__table-data {
    display: block;
  }
}
.l-overview__table-data:nth-child(1) {
  font-weight: 600;
  width: 22rem;
}
@media (max-width: 768px) {
  .l-overview__table-data:nth-child(1) {
    border: unset;
  }
}
.l-overview__table-data:nth-child(2) {
  font-weight: 400;
  border-bottom: 1px solid var(--luxury-platinum-100);
}
@media (max-width: 768px) {
  .l-overview__table-data:nth-child(2) {
    padding-top: unset;
  }
}
.l-overview__footer {
  display: flex;
  gap: 1.6rem;
  margin-right: auto;
}
@media (max-width: 768px) {
  .l-overview__footer {
    flex-direction: column;
    margin-right: unset;
    width: 100%;
  }
}
.l-overview__pattern {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
}
@media (max-width: 768px) {
  .l-overview__pattern {
    top: 0;
    right: 0;
    left: unset;
    bottom: unset;
    transform: translate(0.5rem, -2rem);
  }
}

.l-features {
  display: grid;
  grid-template-columns: minmax(4rem, 1fr) repeat(5, minmax(min-content, 22.8rem)) minmax(4rem, 1fr);
  padding: 9rem 0 9rem;
  min-height: 98.2rem;
}
@media (max-width: 768px) {
  .l-features {
    display: flex;
    flex-direction: column-reverse;
    padding: 4rem 0 4rem;
  }
}
.l-features::after {
  content: "";
  background-color: var(--accent-color);
  width: 100%;
  height: 100%;
}
.l-features__image-wrapper {
  grid-column: 1/4;
}
@media (max-width: 768px) {
  .l-features__image-wrapper {
    height: 65rem;
  }
}
.l-features__content {
  grid-column: 4/7;
}
.l-features__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.l-features__header {
  margin-bottom: 4.8rem;
}
.l-features__content {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10rem 0 12rem 10rem;
  background-color: var(--accent-color);
}
@media (max-width: 1024px) {
  .l-features__content {
    padding: 6rem 0 6rem 5rem;
  }
}
@media (max-width: 768px) {
  .l-features__content {
    padding: 11.2rem 2rem 12.2rem;
  }
}
.l-features__amenities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4.2rem;
}
@media (max-width: 1024px) {
  .l-features__amenities {
    grid-template-columns: 1fr;
  }
}
.l-features__amenity {
  display: flex;
  gap: 1.8rem;
}
.l-features__amenity-image {
  height: 2.6rem;
  width: 2.6rem;
}
.l-features__amenity-title {
  font-family: var(--font-ibm-plex-sans);
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 400;
  color: var(--luxury-platinum-100);
}
.l-features__pattern {
  position: absolute;
}
.l-features__pattern--top {
  top: 0;
  right: 0;
}
.l-features__pattern--bottom {
  bottom: 0;
  left: 0;
}

.l-tour {
  padding: 9rem 0 19rem;
}
@media (max-width: 768px) {
  .l-tour {
    padding: 4rem 0;
  }
}
@media (max-width: 768px) {
  .l-tour__subtitle {
    display: none;
  }
}
@media (max-width: 768px) {
  .l-tour__buttons {
    padding: 0 1.2rem;
    padding-bottom: 11rem;
  }
}
@media (max-width: 768px) {
  .l-tour__iframe {
    height: 65rem;
  }
}
.l-tour__pattern {
  position: absolute;
  bottom: 0;
}
@media (max-width: 768px) {
  .l-tour__pattern {
    display: none;
  }
}

.l-plans {
  position: relative;
  background-color: var(--accent-color);
}
.l-plans::before, .l-plans::after {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 0.2rem;
  height: 15rem;
}
@media (max-width: 768px) {
  .l-plans::before, .l-plans::after {
    height: 10rem;
  }
}
.l-plans::before {
  transform: translateY(-100%);
  background-color: var(--luxury-black);
}
.l-plans::after {
  background-color: #ffffff;
}
.l-plans__container {
  position: relative;
}
.l-plans__container--top {
  padding-top: 18rem;
}
@media (max-width: 768px) {
  .l-plans__container--top {
    padding-top: 14rem;
  }
}
.l-plans__container--bottom {
  padding-bottom: 12.8rem;
}
.l-plans__title {
  margin-bottom: 4.8rem;
  text-align: center;
}
@media (max-width: 768px) {
  .l-plans__title {
    margin-bottom: 2.4rem;
  }
}
.l-plans__slide-wrapper {
  margin: 5rem 0;
}
.l-plans__slide-wrapper a {
  display: block;
}
.l-plans__slide {
  position: relative;
  cursor: pointer;
}
.l-plans__slide.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48rem;
}
.l-plans__slide.swiper-slide:not(.single-slide) {
  background-color: #ffffff;
}
.l-plans__slide.swiper-slide.single-slide .l-plans__slide-wrapper {
  margin: unset;
}
.l-plans__slide.swiper-slide.single-slide .l-plans__expand {
  display: none;
}
@media (max-width: 768px) {
  .l-plans__slide.swiper-slide.single-slide {
    background-color: #ffffff;
  }
}
.l-plans__slide.swiper-slide img {
  object-fit: contain;
  max-height: 40rem;
}
.l-plans__expand {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4.4rem;
  height: 4.4rem;
  padding: 1.2rem;
  background-color: var(--accent-color);
  pointer-events: none;
  cursor: pointer;
}
.l-plans__pagination {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 5rem;
}
.l-plans__pagination .swiper-pagination-bullet {
  opacity: 100%;
  transition: all 0.4s;
  border: 0.2rem solid transparent;
  border-radius: unset;
  width: max-content;
  height: 100%;
  padding: 0.8rem 1.3rem;
  background: unset;
  color: var(--luxury-gray);
}
.l-plans__pagination .swiper-pagination-bullet:hover {
  color: #ffffff;
}
.l-plans__pagination .swiper-pagination-bullet-active {
  border-bottom: 0.2rem solid #ffffff;
  background: unset;
  color: #ffffff;
}
.l-plans__pattern {
  position: absolute;
  top: 0;
  right: var(--container-padding);
  margin-left: auto;
}
@media (max-width: 768px) {
  .l-plans__pattern {
    display: none;
  }
}
.l-plans__pattern--bottom {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.l-plans__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.l-video {
  position: relative;
  background-color: var(--luxury-gray);
  padding: 12.8rem 0 6.4rem;
}
@media (max-width: 768px) {
  .l-video {
    padding: 12.8rem 0 0;
  }
}
.l-video__pattern {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.l-video__buttons {
  padding-bottom: 4.4rem;
}
@media (max-width: 768px) {
  .l-video__iframe {
    height: 35.2rem;
  }
  .l-video__iframe video {
    object-fit: cover;
  }
}
.l-video .react-player__play-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  border-radius: var(--buttons-radius, 0);
  background-color: #ffffff;
  border-style: unset !important;
  border-width: unset !important;
  border-color: unset !important;
  margin-left: unset !important;
  cursor: pointer;
}
.l-video .react-player__play-icon::after {
  transform: translateX(2px);
  transition: all 0.6s var(--anim-play-video-btn);
}
.l-video .react-player__play-icon:hover::after {
  border-left: solid 2.2rem var(--accent-color);
}
.l-video .react-player__play-icon::after {
  content: "";
  width: 0.6px;
  height: 2.8rem;
  border-left: solid 2.2rem var(--luxury-black);
  border-bottom: solid 1.5rem transparent;
  border-top: solid 1.6rem transparent;
}

.l-presented {
  position: relative;
  background-color: var(--luxury-gray);
}
.l-presented__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  row-gap: 1.6rem;
  column-gap: 2rem;
  padding-top: 6.4rem;
  padding-bottom: 18rem;
}
@media (max-width: 1024px) {
  .l-presented__container {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 768px) {
  .l-presented__container {
    padding-top: 4.4rem;
    padding-bottom: 14rem;
  }
}
.l-presented__title {
  align-self: center;
}
@media (max-width: 1024px) {
  .l-presented__title {
    justify-self: center;
  }
}
.l-presented__branding-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.4rem;
}
.l-presented__branding {
  width: 21.5rem;
  height: auto;
}
.l-presented__branding-wrapper, .l-presented__agent {
  background-color: #ffffff;
}
.l-presented__agent {
  display: flex;
  align-items: flex-start;
  gap: 2.4rem;
  height: 100%;
  padding: 4rem 6rem;
}
@media (max-width: 768px) {
  .l-presented__agent {
    flex-direction: column;
    padding: 4rem;
  }
}
.l-presented__agent:last-child {
  grid-column: 2;
}
@media (max-width: 1024px) {
  .l-presented__agent:last-child {
    grid-column: unset;
  }
}
.l-presented__info {
  font-family: var(--font-ibm-plex-sans);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: var(--gray-11);
}
.l-presented__info > * {
  display: block;
}
.l-presented__link:link, .l-presented__link:visited {
  color: inherit;
  text-decoration: none;
}
.l-presented__headshot {
  width: 16rem;
  height: 16rem;
}
.l-presented__name {
  margin-bottom: 0.4rem;
  font-family: var(--font-trirong);
  font-size: 3.2rem;
  letter-spacing: -1px;
  line-height: 4rem;
  color: var(--gray-10);
}
.l-presented__position {
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -1px;
}
.l-presented__brokerage-name {
  margin-top: 1.6rem;
}
.l-presented__website-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.6rem;
  cursor: pointer;
}
.l-presented__website:link, .l-presented__website:visited {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -1px;
  color: var(--gray-10);
}
.l-presented__icon {
  height: 1.3rem;
  width: 1.3rem;
}

.l-contacts__pattern {
  position: absolute;
  left: var(--l-container-padding);
  top: 0;
  transform: translateY(-50%);
}
@media (max-width: 768px) {
  .l-contacts__title {
    margin-bottom: 2.4rem;
    text-align: center;
  }
}
.l-contacts__container {
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 15rem;
  padding-top: 18rem;
  padding-bottom: 18rem;
}
@media (max-width: 1024px) {
  .l-contacts__container {
    column-gap: 7rem;
  }
}
@media (max-width: 768px) {
  .l-contacts__container {
    grid-template-columns: 1fr;
    padding-top: 11rem;
    padding-bottom: 14rem;
  }
}
.l-contacts__form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 3.2rem;
}
@media (max-width: 768px) {
  .l-contacts__form-container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}
.l-contacts__form-container.is-hidden {
  display: none;
}
.l-contacts__full {
  grid-column: 1/-1;
  height: 100%;
}
.l-contacts__form-group {
  position: relative;
}
.l-contacts__form-group--hidden {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.l-contacts__success {
  font-family: var(--font-ibm-plex-sans);
}
.l-contacts__button-wrap {
  display: flex;
  justify-content: center;
}

.l-map {
  background-color: var(--accent-color);
}
.l-map__section-header {
  margin-bottom: 4.8rem;
}
.l-map__container {
  position: relative;
  padding-top: 18rem;
  padding-bottom: 18rem;
}
@media (max-width: 768px) {
  .l-map__container {
    padding-top: 9rem;
    padding-bottom: 13rem;
  }
}
.l-map__pattern {
  position: absolute;
}
@media (max-width: 768px) {
  .l-map__pattern {
    height: 5rem;
    width: 5rem;
  }
}
.l-map__pattern--top {
  top: 0;
}
@media (max-width: 768px) {
  .l-map__pattern--top {
    right: 0;
  }
}
.l-map__pattern--bottom {
  bottom: 0;
  left: var(--l-container-padding);
}
@media (max-width: 768px) {
  .l-map__pattern--bottom {
    left: 0;
  }
}
.l-map__map-container {
  max-width: 100%;
}
@media (max-width: 768px) {
  .l-map__map-container {
    height: 65rem;
  }
}

.l-footer {
  padding: 5.8rem 0;
  background-color: var(--accent-color);
  color: var(--luxury-platinum);
}
@media (max-width: 768px) {
  .l-footer {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}
.l-footer__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .l-footer__container {
    flex-direction: column-reverse;
    row-gap: 4.8rem;
    text-align: center;
  }
}
.l-footer__icons {
  display: flex;
  column-gap: 1.6rem;
}
@media (max-width: 768px) {
  .l-footer__icons {
    column-gap: 4.8rem;
  }
}
.l-footer__presented {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: 0.4rem;
}
@media (max-width: 768px) {
  .l-footer__presented {
    align-items: center;
  }
}
.l-footer__presented img {
  width: 14.8rem;
}
.l-footer__presented span,
.l-footer__presented a:link,
.l-footer__presented a:visited {
  font-family: var(--font-ibm-plex-sans);
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: var(--luxury-gray);
  text-decoration: none;
}
.l-footer__brokerage {
  font-family: var(--font-ibm-plex-sans);
  color: #ffffff;
}