.contacts {
  &__section {
    padding: 6.4rem 0 2.4rem;
    background-color: #fafafa;
    @include breakpoint("phone-max") {
      padding: 3.2rem 0 1.2rem;
    }
  }
  &__header {
    margin-bottom: 2.3rem;
    text-align: center;
  }
  &__sub-title {
    font-size: 2rem;
    line-height: 3.4rem;
    @include breakpoint("phone-max") {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
  }
  &__wrapper {
    padding: 2.4rem;
    border: 1px solid #e2e2e2;
    background-color: #fafafa;
    border-radius: var(--wrapper-radius, 0);
    @include breakpoint("phone-max") {
      padding: 0;
      border: 0;
    }
  }
  &__wrapper-row {
    display: flex;
    padding: 3rem 1.6rem var(--l-container-padding);
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: var(--image-radius, 0);
    margin-bottom: 2.4rem;
    &:last-child {
      flex-direction: column;
      padding-top: 4.9rem;
      padding-bottom: 4.7rem;
      @include breakpoint("phone-max") {
        padding: 2.6rem 1.6rem 2.4rem;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .agents {
      width: calc(50% - 2.8rem);
    }
    @include breakpoint("phone-max") {
      flex-direction: column;
      padding: 3.2rem 1.6rem;
      margin-bottom: 1.2rem;
      .agents {
        width: initial;
        align-self: flex-start;
      }
    }
  }
  &__logos {
    width: calc(50% - 3.1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      max-width: 19rem;
      max-height: 9.6rem;
      margin-bottom: 3.2rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    @include breakpoint("phone-max") {
      width: initial;
      margin-bottom: 5.6rem;
    }
  }
  &__form {
    width: 100%;
    max-width: 58.8rem;
  }
  &__form {
    button[type="submit"] {
      @include breakpoint("phone-max") {
        width: 100%;
      }
    }
  }
}

.agent {
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;

  &--rounded {
    img {
      border-radius: 50%;
    }
  }

  &--squared {
    img {
      border-radius: 0.8rem;
      border: 1px solid #e2e2e2;
    }
  }

  img {
    width: 9.6rem;
    height: 9.6rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
  @include breakpoint("phone-max") {
    flex-direction: column;
    align-items: flex-start;
  }
  img {
    margin-right: 1.6rem;

    @include breakpoint("phone-max") {
      margin-right: 0;
      margin-bottom: 1.6rem;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
  }
  &__company {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 0.6rem;
    font-weight: 600;
    @include breakpoint("phone-max") {
      margin-bottom: 0.7rem;
    }
  }
  &__personal {
    display: flex;
    margin-bottom: 0.1rem;
    b {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-right: 0.8rem;
      font-weight: 600;
      color: var(--main-color);
    }
    span {
      display: inline-flex;
      border-radius: 0.4rem;
      padding: 0.2rem 0.6rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 500;
      background-color: #f6f6f6;
    }
  }
  &__position {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  &__contacts {
    color: var(--main-color);
    a {
      font-size: 1.4rem;
      line-height: 2rem;
      color: var(--main-color);
      text-decoration: none;
      font-weight: 600;
    }
  }
}
