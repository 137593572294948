.plans {
  &__section {
    position: relative;
    margin-bottom: 6.4rem;
    .container {
      position: relative;
      z-index: 1;
    }
    .gallery__group {
      box-shadow: none;
    }

    @include breakpoint("phone-max") {
      margin-bottom: 3.2rem;
      &:after {
        display: none;
      }
    }
  }
  &__pattern {
    left: 0;
    top: -40.6%;
    background-image: url("../assets/patterns/plans.svg");
    @include breakpoint("phone-max") {
      display: none;
    }
  }
  &__title {
    margin-bottom: 2.4rem;
    @include breakpoint("phone-max") {
      margin-bottom: 1.2rem;
    }
  }
  &__tabs {
    .tabs {
      &__header {
        margin-bottom: 2.4rem;
        @include breakpoint("phone-max") {
          margin-bottom: 2.1rem;
        }
      }

      &__tab-content {
        padding: 2.4rem;
        border: 1px solid #e2e2e2;
        background-color: #fafafa;
        border-radius: var(--wrapper-radius, 0);
        .gallery__group {
          background-color: #fff;
          border-radius: var(--image-radius, 0);
        }
        @include breakpoint("phone-max") {
          background-color: #fff;
          padding: 0;
        }
      }
    }
  }
}
