.l-header {
  position: fixed;
  z-index: 3;
  opacity: 0;

  width: 100%;
  padding: 2rem 8rem 2rem;

  transition: transform 0.6s, background 0.6s, opacity 0.6s;
  transition-delay: 1.2s;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @include breakpoint("laptop-max") {
    padding: 2rem 3.2rem;
  }

  @include breakpoint("tablet-max") {
    padding: 0 1.2rem;
  }

  &.loaded {
    background-color: rgba(#ffffff, 0.95);
    opacity: 1;
  }

  &.no-delay {
    transition-delay: unset;
  }

  &.open {
    margin: 0;

    &::after {
      opacity: 0.95;
      pointer-events: auto;
    }

    .l-burger {
      height: 4.4rem;
      width: 4.4rem;

      background-color: rgba(#ffffff, 5%);

      &__text {
        display: none;
        transition: width 0.3s;
      }

      &__icon {
        &::before {
          transform: rotate(-45deg) translateY(4px) translateX(-3px);
        }

        &::after {
          opacity: 0;
        }
      }

      &__line {
        transform: rotate(45deg);
        width: 1.8rem;
      }
    }

    &:not(.is-transparent) {
      .l-burger {
        margin-top: 1.2rem;
      }
    }

    .l-header {
      &__nav {
        z-index: 3;
        transform: translateY(0);

        .b-header {
          &__list-link {
            color: var(--gray-9);

            &.active {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  &:not(.is-transparent) {
    @include breakpoint("tablet-max") {
      margin: 0;
      box-shadow: unset;

      background-color: unset;
    }

    .l-header {
      &__list {
        text-shadow: unset;
      }

      &__list-link,
      .custom-links-menu a {
        &:link,
        &:visited {
          transition-delay: 0.3s;

          color: var(--luxury-black);
          text-shadow: unset;

          @include breakpoint("tablet-max") {
            color: var(--luxury-gray);
          }
        }

        &::after {
          box-shadow: unset;
          background: var(--luxury-black);

          @include breakpoint("tablet-max") {
            background: #ffffff;
          }
        }
      }
    }

    .theme-button {
      &--border-white {
        transition-delay: 0.3s;
        border: 0.1rem solid var(--accent-color);
        color: var(--accent-color);

        svg {
          transition-delay: 0.3s;
          fill: var(--accent-color);
        }
      }
    }
  }

  &.is-hidden {
    transform: translate3d(0, -100%, 0);
    box-shadow: unset;
    background-color: unset;

    .l-header {
      &__list-link,
      .custom-links-menu a {
        &::after {
          transition-delay: 0.3s;
        }
      }
    }
  }

  &.is-transparent {
    position: absolute;

    margin: 4rem 0 0;
    box-shadow: unset;
    padding: 4rem 8rem 2rem;

    background-color: unset;

    @include breakpoint("laptop-max") {
      margin: 2rem 0 0;
      padding: 2rem 3.2rem;
    }

    @include breakpoint("tablet-max") {
      margin: 1.2rem 0 0;
      padding: 0 1.2rem;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: unset;
    flex-wrap: wrap;
    gap: 2.4rem;
  }

  &__list {
    display: flex;
    gap: 0.8rem;

    list-style: none;

    margin: 0;
    width: 100%;
    padding: 0;

    @include breakpoint("tablet-max") {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.4rem;

      margin-top: 3rem;
      height: calc(100% - 84px);
    }
  }

  &__list-item {
    list-style: none;
    padding: 0.8rem 1.6rem;
    cursor: pointer;

    &:first-child {
      padding-left: unset;
    }

    &.contacts{
      @include breakpoint("tablet-max") {
        width: 100%;
        margin-top: auto;
      }
    }

    @include breakpoint("tablet-max") {
      opacity: 1;
      padding: 0;
    }
  }

  &__list-link,
  .custom-links-menu a {
    position: relative;
    display: block;
    font-size: 1.4rem;
    line-height: 2.8rem;
    font-weight: 600;

    @include breakpoint("tablet-max") {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      gap: 1.6rem;

      font-size: 1.8rem;
      font-weight: 400;
      line-height: 6rem;
    }

    &:link,
    &:visited {
      text-decoration: none;
      text-transform: capitalize;

      font-family: var(--font-ibm-plex-sans);
      color: #ffffff;

      @include breakpoint("tablet-max") {
        color: var(--luxury-gray);
      }

      &.active {
        color: #ffffff;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;

      margin: auto;
      width: 0%;
      color: transparent;
      background-color: #ffffff;
      height: 0.2rem;
      transition: var(--anim-small-duration) ease;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

      @include breakpoint("tablet-max") {
        color: var(--luxury-gray);
        height: 0;
        width: 2px;
        margin: unset;
        position: initial;
        grid-column: 1;
        box-shadow: unset;
      }
    }

    &:hover,
    &.active {
      &::after {
        width: 100%;
        opacity: 1;

        @include breakpoint("tablet-max") {
          height: 100%;
          width: 2px;
        }
      }
    }
  }

  &__panel {
    display: flex;
    gap: 1.6rem;

    @include breakpoint("tablet-max") {
      display: none;
    }
  }

  &__nav {
    @include breakpoint("tablet-max") {
      position: fixed;
      top: 0;
      right: 0;

      overflow-y: auto;
      transform: translateY(-110%);

      transition: transform 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);

      width: 100%;
      height: 100vh;
      padding: 1.2rem 2rem;

      background-color: var(--accent-color);

      &.resizing {
        transition: none;
      }
    }
  }

  menuitem:hover > menu {
    z-index: 2;
    pointer-events: initial;

    transition-delay: 0.4s;
    transition: all 0.5s;

    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.2);
  }

  .custom-links-menu {
    padding: 0.8rem 1.6rem;

    @include breakpoint("phone-max") {
      display: none;
    }
  }

  .mobile-only {
    @include breakpoint("tablet-min") {
      display: none;
    }
  }

  .custom-links-menu {
    a {
      font-size: 1.4rem;
      color: #ffffff;
    }

    &__container {
      menuitem {
        &:first-child {
          &::before {
            content: "";

            position: absolute;
            top: -1rem;
            right: 0;

            border-left: 1rem solid transparent;
            border-right: 1rem solid transparent;
            border-bottom: 1rem solid #ffffff;

            width: 0;
            height: 0;
          }
        }
      }

      .link-item {
        a {
          display: flex;
          transition: all var(--anim-default-duration);
          margin: 0;

          color: var(--accent-color);

          text-shadow: unset;
          text-decoration: none;

          background-color: #ffffff;

          &::after {
            background-color: var(--accent-color);
            box-shadow: unset;
          }
        }
      }
    }
  }
}
