.button {
  position: relative;
  border-width: 0;
  outline: 0;
  border-radius: var(--buttons-radius, 0);
  background-color: var(--accent-color);
  color: #fff;
  padding: 1rem 1.6rem;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.04rem;
  font-weight: 600;
  &:after {
    user-select: none;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
    transition: opacity var(--anim-default-duration) var(--anim-easing-in);
    opacity: 0;
  }
  & > * {
    position: relative;
    z-index: 2;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

input,
textarea {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  font-family: var(--primary-font);
  border-radius: var(--elements-radius, 0);
  padding: 0 3rem 0 0.8rem;
  border: 1px solid #e2e2e2;
  height: var(--input-height, 0);
  transition: border-color var(--anim-default-duration) var(--anim-easing-in);
  outline: none;
  &:hover {
    border-color: #afafaf;
  }
  &:focus {
    border-color: var(--accent-color);
  }
}

input[type="radio"],
input[type="checkbox"] {
  width: 2rem;
  height: 2rem;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

textarea {
  resize: none;
  height: 8rem;
  padding: 1rem 3rem 1rem 0.8rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  tr {
    td {
      font-size: 2rem;
      line-height: 3.4rem;
      font-weight: 500;
      padding: 1.2rem 0;
      border-bottom: 1px solid #e2e2e2;
      @include breakpoint("phone-max") {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }
    &:last-of-type {
      td {
        border-bottom: 0;
      }
    }
  }
}

.tabs {
  max-width: 128rem;
  padding: 0 var(--container-padding);
  margin: 0 auto;
  @include breakpoint("phone-max") {
    max-width: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  &__header {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  &__nav {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    @include breakpoint("phone-max") {
      padding: 0 var(--container-padding);
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__link {
    position: relative;
    display: inline-flex;
    flex: 1;
    cursor: pointer;
    padding: 0.9rem 1.2rem 1.3rem;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.04rem;
    color: var(--text-color);
    text-transform: uppercase;
    transition: color var(--anim-default-duration) var(--anim-easing-in);
    white-space: nowrap;
    @include breakpoint("phone-max") {
      padding: 1rem 0.8rem 1.3rem;
    }
    &.active {
      color: var(--main-color);
      &::before {
        opacity: 1;
        background-color: var(--accent-color);
      }
    }
    &::before {
      opacity: 0;
      content: "";
      position: absolute;
      z-index: 2;
      width: 100%;
      top: calc(100% - 3px);
      left: 0;
      height: 3px;
      background-color: #eee;
      transition: opacity var(--anim-default-duration) var(--anim-easing-in),
        background-color var(--anim-default-duration) var(--anim-easing-in),
        color var(--anim-default-duration) var(--anim-easing-in);
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      top: calc(100% - 4px);
      width: 100%;
      height: 1px;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
    }
    &:hover {
      color: var(--main-color);
      &::before {
        opacity: 1;
      }
    }
  }
  &__body {
    display: block;
    font-size: 2.4rem;
    line-height: 3.8rem;
    font-weight: 400;
    @include breakpoint("phone-max") {
      padding: 0 var(--container-padding);
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }
  &__tab-content {
    display: none;
    width: 100%;
    &.active {
      display: flex;
    }
  }
}

.documents-wrapper {
  background-color: #fafafa;
  border-radius: 0.8rem;
  padding: 0.4rem;
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1.8rem;
    background-color: #fff;
    margin-bottom: 0.2rem;
    border-radius: 0.4rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    span {
      display: inline-flex;
      align-items: center;
      font-size: 2rem;
      line-height: 3.4rem;
      font-weight: 500;
      color: var(--main-color);
      @include breakpoint("phone-max") {
        font-size: 1.8rem;
        line-height: 2rem;
      }
      img {
        width: 2.8rem;
        height: 2.4rem;
        margin-right: 1.4rem;
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      background-color: #fafafa;
      img {
        width: 1.3rem;
        height: 1.4rem;
      }
    }
  }
}

.tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -1.25rem -1.2rem 0;
  @include breakpoint("phone-max") {
    margin: 0 -0.8rem -0.8rem 0;
  }
  .tag {
    padding: 0.3rem 1.2rem;
    margin: 0 1.25rem 1.2rem 0;
    background-color: #f6f6f6;
    color: var(--main-color);
    font-size: 2rem;
    line-height: 3.4rem;
    font-weight: 400;
    border-radius: var(--elements-radius, 0);
    @include breakpoint("phone-max") {
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin: 0 0.8rem 0.8rem 0;
    }
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;

  &--ratio {
    // this is what keeps our iframe to the right ratio
    padding-top: 56.26%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  &--cover {
    video {
      object-fit: cover;
    }
  }

  video,
  iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  &__container {
    text-align: center;
    &.is-hidden {
      display: none;
    }
  }
  &__title {
    margin-bottom: 1.6rem;
  }
  &__row {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    margin-bottom: 2.4rem;
    &.double-column {
      .form__column {
        &:first-of-type {
          margin-right: 0.4rem;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      @include breakpoint("phone-max") {
        margin-bottom: 0;
        .form__column {
          margin-right: 0 !important;
          margin-bottom: 2.4rem;
        }
      }
    }
    @include breakpoint("phone-max") {
      flex-direction: column;
    }
  }
  &__column {
    flex: 1;
    position: relative;
    &.has-error {
      input,
      textarea {
        border-color: var(--error-color);
      }
      .input-wrap {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  .input-wrap {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;
      position: absolute;
      right: 1.2rem;
      bottom: 0;
      top: 0;
      margin: auto;
      width: 1.65rem;
      height: 1.65rem;
      background: url("../assets/icons/error.svg") no-repeat center;
      background-size: 95% 95%;
      opacity: 0;
      transition: opacity var(--anim-default-duration) var(--anim-easing-in);
    }
  }
  .row__messages {
    position: absolute;
    left: 0.8rem;
    top: calc(100% + 0.3rem);
    font-size: 1.2rem;
    color: var(--error-color);
  }
  .label {
    display: block;
    width: 100%;
    padding-left: 0.8rem;
    margin-bottom: 0.4rem;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    font-family: var(--primary-font);
  }
  .success-view {
    display: none;
    text-align: center;
    &.is-visible {
      display: block;
    }
    h3 {
      margin-bottom: 1.6rem;
    }
    p {
      font-size: 2rem;
      line-height: 3.4rem;
      &:last-of-type {
        margin-bottom: 1.6rem;
      }
    }
  }
}

.marquee {
  position: relative;
  overflow: hidden;
  //--offset: 20vw;
  //--move-initial: calc(-25% + var(--offset));
  //--move-final: calc(-50% + var(--offset));
  margin: -9.2rem 0 -8rem;
  user-select: none;
  &__inner {
    width: fit-content;
    display: flex;
    position: relative;
    //transform: translate3d(var(--move-initial), 0, 0);
    //animation: marquee 10s linear infinite;
    //animation-play-state: running;
  }
  span {
    white-space: nowrap;
    padding: 0 2rem;
    font-size: 14.4rem;
    line-height: 1.2;
    font-weight: 800;
    font-style: italic;
    color: #fafafa;
    @include breakpoint("phone-max") {
      font-size: 7.2rem;
    }
  }
  @include breakpoint("phone-max") {
    margin: -3.6rem 0 -5rem 0;
  }
}

// @keyframes marquee {
//   0% {
//     transform: translate3d(var(--move-initial), 0, 0);
//   }

//   100% {
//     transform: translate3d(var(--move-final), 0, 0);
//   }
// }

.waypoint-animation {
  @include animate-reveal-in-from-bottom-with-fade-in();
}

.pattern {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 200%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.burger {
  display: none;

  @include breakpoint("tablet-max") {
    display: grid;
    grid-template-rows: 1fr max-content 1fr;

    height: 1.6rem;
    width: 2.4rem;

    cursor: pointer;
  }

  &:hover {
    .burger__line {
      width: 100%;
    }
  } 
 
  &::before,
  &__line,
  &::after {
    content: '';
   
    width: 100%;
    height: 0.2rem;

    background-color: #ffffff;
  }

  &__line {
    width: 60%;
    justify-self: end;
    transition: var(--anim-default-duration) var(--anim-easing-in);
  }

  &::after {
    align-self: end; 
  }
}

.l-burger {
  z-index: 3;
  
  display: none;
  align-items: center;
  gap: 1rem;

  transition: var(--anim-default-duration) var(--anim-easing-in);

  padding: 1rem;
  margin-left: auto;

  background-color: var(--luxury-black);
  color: #ffffff;
  cursor: pointer;
 
  &__icon {
    display: grid;
    justify-items: end;
    gap: 3px;

    padding: 6px 3px;

    &::before {
      width: 1.8rem;
    }
  }

  &__icon::before,
  &__line,
  &__icon::after {
    content: '';
   
    display: block;
    transition: var(--anim-default-duration) var(--anim-easing-in);
    height: 0.2rem;
    background-color: #ffffff;
  }

  &__line {
    display: grid;
    gap: 3px;
  }

  &__line,
  &__icon::after {
    width: 1.2rem;
  }

  &__line {
    justify-self: end;
    transition: var(--anim-default-duration) var(--anim-easing-in);
  }

  &__text {
    transition: var(--anim-default-duration) var(--anim-easing-in);

    font-family: var(--font-ibm-plex-sans);
    font-size: 1.4rem;
    line-height: 2.8rem;
    font-weight: 600;
  }

  @include breakpoint("tablet-max") {
    display: flex;
  }
}

.b-button {
  &,
  &:link,
  &:visited {
    position: relative;
    display: inline-block;

    cursor: pointer;

    overflow: hidden;
    transition: all 0.2s;

    border-radius: var(--buttons-radius, 0);
   
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
  }

  &--accent {
    color: #ffffff;
    padding: 1rem 2rem;
    background-color: var(--accent-color);

    &:hover,
    &:active {
      span {
        color: var(--accent-color);
      }
    }

    &::before {
      background-color: #ffffff;
    }
  }

  &--bordered {
    color: var(--accent-color);
    padding: 1.2rem 3rem;
    border: solid 2px var(--accent-color);
  }

  &--hovered {
    &:hover,
    &:active {
      span {
        color: #ffffff;
      }
    }

    &::before {
      background-color: var(--accent-color);
    }
  }

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    transition: transform 0.2s 0.4s, width 0.4s var(--anim-btn), background-color 0.1s;

    height: 100%;
    width: 3px;

    transform: scaleY(0);
  } 

  &:hover,
  &:active {
    &:before {
      transform: scaleY(1);

      transition: transform 0.2s,width 0.4s var(--anim-btn) 0.2s,background-color 0.1s;

      width: 100%;
    }
  }

  span {
    position: relative;
  }
}

.preloader {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;

  z-index: 10000;

  animation: animateBackground var(--anim-default-duration) linear;
  transition: all var(--anim-default-duration);

  height: 100%;
  width: 100%;

  backdrop-filter: blur(0.3rem);

  transition: all var(--anim-default-duration);

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &__icon,
  &__icon-box {
    width: 11.8rem;
    height: 11.8rem;
  }

  &__icon-text {
    font-size: 2.4rem;
    color: var(--gray-9);
    letter-spacing: 0.1rem;
  }

  &__icon-box {
    position: absolute;
    top: 50%;
    left: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    transform: translate(-50%, -50%);

    img {
      background-color: var(--accent-color);
      border-radius: 50%;
      height: 11rem;
      width: 11rem;
      padding: 2rem;
    }
  }

  &__bg {
    transition: all var(--anim-default-duration);

    height: inherit;
    width: inherit;

    background: var(--gray-1);
  }

  &__circle {
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);

    border-radius: 50%;
    height: 16rem;
    width: 16rem;

    &--1 {
      opacity: 0.4;
      animation: animate-first-circle 1.5s ease-out infinite;
      border: 0.3rem solid var(--accent-color);
    }

    &--2 {
      opacity: 0.4;
      animation: animate-second-circle 1.5s ease-out infinite;
      border: 0.2rem solid var(--accent-color);
    }

    &--3 {
      opacity: 0.4;
      animation: animate-third-circle 1.5s ease-out infinite;
      border: 0.1rem solid var(--accent-color);
    }
  }
}

.b-tabs {
  display: flex;
  padding-left: 1px;
  width: max-content;
  position: relative;
  border-radius: var(--buttons-radius);
  overflow: hidden;

  &--gray {
    background-color: var(--gray-1);
  }

  &__subtitle {
    margin: unset;
  }

  &__button {
    align-items: center;
    color: #767b82;
    display: inline-flex;
    font-weight: 700;
    justify-content: center;
    line-height: 2rem;
    margin-left: -1px;
    max-width: 29rem;
    transition: color 0.3s, border-color 0.3s;
    cursor: pointer;
    background-color: transparent;
    padding: 1.2rem 6.2rem;
    border: unset;

    &.active {
      background-color: var(--accent-color);
      color: #ffffff;
    }
  }
}

.btn-arrow {
  transition: background-color 0.3s ease-in-out;
  width: 3.8rem;
  height: 3.8rem;
  border: 1.75px solid var(--gray-9);
  border-radius: 50%;
  display: block;
  position: relative;
  flex: 0 0 auto;
  cursor: pointer;
  z-index: 100;

  &.swiper-button-disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &--next {
    &:hover {
      .arrow {
        &::before,
        &::after {
          right: 32%;
        }

        &::after {
          left: 32%;
        }
      }
    }
    
    .arrow {
      &::before {
        right: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
  
      &::after {
        left: -50%;
        right: 50%;
        transform: translateY(-50%);
      }
    }    
  }

  &--prev {
    &:hover {
      .arrow {
        &::before,
        &::after {
          left: 32%;
        }

        &::after {
          right: 32%;
        }
      }
    }
    
    .arrow {
      &::before {
        left: 50%;
        transform: translateY(-50%) rotate(228deg);
      }
  
      &::after {
        left: 50%;
        right: -50%;
        transform: translateY(-50%);
      }
    }    
  }

  &:hover {
    background-color: var(--gray-9);

    .arrow {
      &::before,
      &::after {
        border-color: #ffffff;
      }

      &::before {
        opacity: 1;
      }
    }
  }

  .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transition: opacity 0.3s ease-in-out, border-color 0.3s ease-in-out,
        transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out,
        opacity 0.3s ease-in-out, left 0.3s ease-in-out, right 0.3s ease-in-out;
    }

    &::before {
      width: 8px;
      height: 8px;
      border: 1.75px solid var(--gray-9);
      border-width: 1.75px 1.75px 0 0;
      opacity: 0;
    }

    &::after {
      border-top: 1.75px solid var(--gray-9);
    }
  } 
}

.slider-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  margin-top: 1.6rem;
  position: relative;

  .slider-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }

  .button-prev {
    justify-self: end;
  }

  .button-start {
    justify-self: start;
  }
}

.l-slider-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;

  &--desktop {
    @include breakpoint("tablet-max") {
      display: none;
    }
  }

  &--mobile {
    display: none;
    justify-content: space-between;

    margin-top: 2.4rem;

    .slider-pagination {
      order: 2;

      width: max-content;
    }

    .l-btn-arrow-next {
      order: 3;
    }

    @include breakpoint("tablet-max") {
      display: flex;
    }
  }

  &__pagination {
    display: flex;
    gap: 0.4rem;

    font-family: var(--font-ibm-plex-sans);
    font-weight: 600;

    line-height: 2.4rem;
    letter-spacing: -0.3rem;

    margin: 0.8rem;

    &.swiper-pagination-fraction {
      color: var(--luxury-heading);
    }
  }
}

.video-player-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper-slide-active {
  z-index: 1;
}

.slider-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__buttons {
    background-color: #ffffff;
    margin-top: unset;
    z-index: 1000;
  }

  .slider-buttons {
    margin-bottom: unset;
  }

  .swiper-slide-active {
    .b-subtitle {
      opacity: 1;
    }
  }
}
  
.swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.8rem;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.floorplans {
  position: relative;

  img {
    width: 100%;
  }

  .hotspot {
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: #d0ebff;
    border-radius: 50%;
    border: solid 1px #d1d5db;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;

    @include breakpoint("phone-max") {
      width: 16px;
      height: 16px;
    }
  }
}

.map-container {
  position: relative;
  overflow: hidden;

  border-radius: var(--wrapper-radius, 0);
  max-width: 120rem;
  height: 60rem;

  cursor: pointer;

  @include breakpoint("phone-max") {
    height: 40rem;
  }

  &__cover,
  &__interactive {
    height: 100%;
  }

  &__cover {
    margin: 0 auto;

    background-size: cover;
    background-position: center center;
  }
}

.title-address {
  color: #ffffff;

  @include breakpoint("tablet-max") {
    text-align: center;
  }

  span {
    display: block;
  }
}

.zipcode {
  font-size: 2.4rem;
  font-weight: 400;

  letter-spacing: -1px;
  line-height: 3.6rem;
}

.theme-button {
  all: reset;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  transition: all 0.3s;

  padding: 0.8rem 1.6rem;
  border: 0.1rem solid var(--accent-color);
  border-radius: var(--buttons-radius, 0);
  overflow: hidden;

  font-family: var(--font-ibm-plex-sans);
  font-size: 1.4rem;
  font-weight: 600;

  text-decoration: none;
  line-height: 2.8rem;
  color: #ffffff;

  cursor: pointer;

  @include breakpoint("laptop-max") {
    padding: 0.8rem 1.2rem;
  }

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    transform: scale3d(0.7, 1, 1);

    transition: transform 0.4s, opacity 0.4s;

    width: 100%;
    height: 100%;

    background-color: var(--luxury-platinum-100);
  }

  &__icon {
    padding: 0.3rem 0.2rem;
  }

  svg {
    z-index: 1;
  }

  &:hover {
    @include breakpoint("tablet-min") {
      border: 0.1rem solid var(--luxury-gray);

      &::before {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1.1);
      }

      color: var(--accent-color);

      svg {
        fill: var(--accent-color);
      }
    }
  }

  &__text,
  &__icon {
    z-index: 1;
  }

  &__icon {
    width: 3rem;
    height: 2.4rem;
  }

  &--border-white {
    border: 0.1rem solid var(--luxury-gray);

    svg {
      fill: #ffffff;
    }
  }

  &--border-accent {
    border: 0.1rem solid var(--accent-color);
    color: var(--accent-color);

    &:hover {
      border: 0.1rem solid var(--accent-color);
    }

    svg {
      fill: var(--accent-color);
    }
  }

  &--accent {
    background-color: var(--accent-color);

    svg {
      fill: var(--luxury-gray);
    }
  }

  &--menu {
    justify-content: center;

    font-size: 1.8rem;
    font-weight: 600;
    line-height: 3.6rem;

    width: 100%;
    padding: 1.6rem;
    background-color: #ffffff;

    color: var(--accent-color);

    svg {
      fill: var(--accent-color);
    }
  }
}

.street {
  font-family: var(--font-trirong);
  font-weight: 300;
  font-size: 6rem;

  letter-spacing: -2px;
  line-height: 6rem;

  @include breakpoint("tablet-max") {
    font-size: 3.6rem;
    line-height: 4.6rem;
  }

  &--small {
    display: none;

    font-size: 2rem;
    color: var(--luxury-gray);

    letter-spacing: 0;

    @include breakpoint("tablet-max") {
      display: block;
    }
  }
}

.section-header {
  display: grid;
  grid-template-columns: auto 1fr repeat(2, auto);
  align-items: center;

  gap: 2.4rem;

  margin-bottom: 3.2rem;

  @include breakpoint("laptop-max") {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;
  }

  @include breakpoint("tablet-max") {
    margin-bottom: 1.6rem;
  }

  &--two-col {
    grid-template-columns: auto 1fr;
  }

  &--three-col {
    grid-template-columns: auto 1fr auto;
  }

  &--white {
    &::after {
      background-color: #ffffff;
    }
  }

  .swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &::after {
    content: '';

    grid-column: 2;
    grid-row: 1;

    width: 100%;
    height: 0.2rem;

    background-color: var(--luxury-platinum-200);
  }
}

.l-btn-arrow {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s;

  min-height: 4.4rem;
  min-width: 4.4rem;

  background-color: var(--accent-color);
  border: 0.1rem solid var(--accent-color);
  border-radius: var(--buttons-radius, 0);

  &.swiper-button-disabled {
    @include breakpoint("tablet-max") {
      opacity: 0;
    }
  }

  &--white {
    background-color: #ffffff;
    border: 0.1rem solid #ffffff;

    &.swiper-button-disabled {
      opacity: 0;
    }

    svg {
      fill: var(--accent-color);
    }
  }

  &:hover {
    @include breakpoint("tablet-min") {
      background-color: var(--luxury-platinum-100);
      border: 0.1rem solid var(--luxury-platinum-100);

      svg {
        fill: var(--accent-color);
      }
    }
  }

  &__icon {
    fill: var(--luxury-gray);
    height: 2rem;
  }
}

.dot-pattern {
  display: block;
  width: 10rem;
  height: auto;
}

.arrow-btn-centered {
  z-index: 2;

  position: absolute;
  top: 50%;

  border-radius: var(--buttons-radius, 0);
  transform: translateY(-50%);

  &:nth-child(1) {
    left: var(--l-container-padding);
  }

  &:nth-child(2) {
    right: var(--l-container-padding);
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.3s;
}

mux-player {
  height: 700px;
}
