.l-video {
  position: relative;

  background-color: var(--luxury-gray);
  padding: 12.8rem 0 6.4rem;

  @include breakpoint("tablet-max") {
    padding: 12.8rem 0 0;
  }

  &__pattern {
    position: absolute;
    left: 50%;
    top: 0;

    transform: translateX(-50%);
  }

  &__buttons {
    padding-bottom: 4.4rem;
  }

  &__iframe {
    @include breakpoint("tablet-max") {
      height: 35.2rem;

      video {
        object-fit: cover;
      }
    }
  }

  .react-player {
    &__play-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8rem;
      height: 8rem;
      border-radius: var(--buttons-radius, 0);
      background-color: #ffffff;

      border-style: unset !important;
      border-width: unset !important;
      border-color: unset !important;
      margin-left: unset !important;

      cursor: pointer;

      &::after {
        transform: translateX(2px);
        
        transition: all 0.6s var(--anim-play-video-btn);
      }

      &:hover {
        &::after {
          border-left: solid 2.2rem var(--accent-color);
        }
      }

      &::after {
        content: "";
        width: 0.6px;
        height: 2.8rem;
        border-left: solid 2.2rem var(--luxury-black);
        border-bottom: solid 1.5rem transparent;
        border-top: solid 1.6rem transparent;
      }
    }
  }
}
