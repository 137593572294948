.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  max-width: 100vw;
  padding: 0;
  height: var(--header-height);
  display: flex;
  align-items: center;
  background-color: #fff;
  opacity: 0;
  transform: translate3d(0, -50px, 0);
  will-change: transform, opacity;
  transform-style: preserve-3d;
  animation: var(--anim-medium-duration) var(--anim-easing-out) reveal-in-from-top forwards;
  &.overflow {
    overflow: hidden;
  }
  @include breakpoint("phone-max") {
    padding: 1.2rem 0;
    align-items: flex-start;
  }
  &.is-hidden {
    animation-delay: 0s;
    animation: header-reveal var(--anim-medium-duration) var(--anim-easing-out)
      forwards;
  }
  &.is-transparent {
    background-color: rgba(0, 0, 0, 0);
  }
  &--is-menu-open {
    @include breakpoint("phone-max") {
      background-color: #fff;
      .nav {
        pointer-events: initial !important;
        opacity: 1 !important;
      }
      .menu-button {
        .menu-icon {
          span {
            background-color: #afafaf;
            &:nth-child(1) {
              top: 4px;
              width: 0%;
              left: 50%;
            }
            &:nth-child(2) {
              transform: rotate(45deg);
            }
            &:nth-child(3) {
              transform: rotate(-45deg);
            }
            &:nth-child(4) {
              top: 4px;
              width: 0%;
              left: 50%;
            }
          }
        }
      }
    }
  }
  &__top-bar {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 13;
    height: 100%;
    @include breakpoint("phone-max") {
      flex: 1 1 100%;
    }
  }
  &__logo {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.75rem;

    img {
      display: block;
      max-height: 100%;
    }
  }
  .container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    @include breakpoint("phone-max") {
      align-items: flex-start;
    }
  }
  .menu {
    display: flex;
    list-style: none;
    position: relative;
    padding: 0;
    margin: 0;
    @include breakpoint("phone-max") {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: var(--header-height);
    }
    &-item {
      display: inline-flex;
      margin-right: 0.4rem;
      &:last-of-type {
        margin-right: 0;
      }
      @include breakpoint("phone-max") {
        margin-right: 0;
        margin-bottom: 1.2rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .nav {
    @include breakpoint("phone-max") {
      pointer-events: none;
      position: fixed;
      z-index: 10;
      top: var(--header-height);
      left: 0;
      width: 100%;
      height: calc(100vh - var(--header-height));
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity var(--anim-default-duration) var(--anim-easing-in);
      background-color: #fff;
      &:after {
        content: "";
        width: 100vw;
        height: 100vh;
        position: absolute;
        background-image: url("../assets/patterns/menu.svg");
        background-size: 125% 100%;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 11;
        top: calc(var(--header-height) * -1);
        left: 0;
        pointer-events: none;
      }
    }
    &__link {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #333;
      font-weight: 500;
      letter-spacing: 0.055rem;
      text-decoration: none;
      text-transform: uppercase;
      padding: 0.8rem 1.2rem;
      border-radius: var(--buttons-radius, 0);
      transition: background-color var(--anim-default-duration)
        var(--anim-easing-in);
      &:last-of-type {
        margin-right: 0;
      }
      &:hover,
      &.active {
        background-color: #f6f6f6;
      }
      &--accent {
        color: #fff;
        background-color: var(--accent-color);
        &:hover,
        &.active {
          background-color: var(--accent-color);
        }
        &:after {
          background-color: unset;
        }
      }
    }
  }
  .menu-button {
    display: none;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: none;
    border-radius: 50%;
    border: 1px solid #e2e2e2;
    padding: 0;
    @include breakpoint("phone-max") {
      display: flex;
    }
    .menu-icon {
      width: 12px;
      height: 10px;
      position: relative;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #757575;
        border-radius: 6px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: 4px;
        }
        &:nth-child(4) {
          top: 8px;
        }
      }
    }
  }
}

.header {
  menuitem:hover > menu {
    pointer-events:initial;
  }

  .custom-links-menu {
    @include breakpoint("phone-max") {
      display: none;
    }
  }

  .mobile-only {
    @include breakpoint("tablet-min") {
      display: none;
    }
  }
}

.custom-links-menu,
li.custom-links-menu * {
  margin:0;
  padding:0;
}

li.custom-links-menu menuitem {
  position:relative;
  display:block;
  opacity:0;
  cursor:pointer;
}

li.custom-links-menu menuitem > menu {
  position: absolute;
  pointer-events:none;
}
li.custom-links-menu > menu { display:flex; }

li.custom-links-menu > menu > menuitem { pointer-events: all; opacity:1; }
menu menuitem a { white-space:nowrap; display:block; }

menuitem:hover > menu {
  pointer-events:initial;
}
menuitem:hover > menu > menuitem,
menu:hover > menuitem{
  opacity:1;
}
li.custom-links-menu > menu > menuitem menuitem menu {
  transform:translateX(100%);
  top:0; right:0;
}

li.custom-links-menu a {
  transition: background 0.5s, color 0.5s, transform 0.5s;
  box-sizing:border-box;
  position:relative;
}

li.custom-links-menu > menu > menuitem > menu > menuitem{
  transition: transform 0.6s, opacity 0.6s;
  transform:translateY(150%);
  opacity:0;
}
li.custom-links-menu > menu > menuitem:hover > menu > menuitem,
li.custom-links-menu > menu > menuitem.hover > menu > menuitem{
  transform:translateY(0%);
  opacity: 1;
}

.custom-links-menu__container {
  right: -1.2rem;
  padding-top: 1.5rem !important;
}

.custom-links-menu .link-item a {
  background-color: #fff;
  border-radius: 0;
  height: 4.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2.4rem;
  margin:0 0 1px 0;
  text-transform: uppercase;

  &:hover {
    background-color: rgba(255,255,255,0.8);
  }
}
