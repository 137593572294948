.b-hero {  
  &__container {
    display: grid;
    grid-template-rows: 1.2fr max-content 1fr;

    height: 100%;
    max-width: 100%;
  }

  &__title-block {
    grid-row: 2;
    opacity: 0;

    &.loaded {
      animation: reveal-in-from-bottom var(--anim-medium-duration) backwards;
      animation-delay: 0.5s;
      animation-fill-mode: both;
    }

    @include breakpoint("tablet-max") {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__street {
    font-size: 4.4rem;
    letter-spacing: -1px;
    line-height: 1.2;

    @include breakpoint("tablet-max") {
      font-size: 3.2rem;
    }
  }

  &__address {
    @include breakpoint("tablet-max") {
     font-size: 2.4rem;
     text-align: center;
    }
  }

  &__status-block {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    padding: 0.5rem 1rem;

    border-radius: var(--hero-radius, 0);
    background-color: #ffffff;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 0.8rem;

    span {
      height: 0.6rem;
      width: 0.6rem;
      border-width: 0.2rem;
      border-style: solid;
      border-radius: 0.3rem;

      @include breakpoint("tablet-max") {
        display: none;
      }
    }
  }

  &__status {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: 700;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    text-shadow: 0 0 3rem #000000;
    margin: 0 0 1.6rem 0;
    grid-row: 3;
    align-self: end;
    gap: 3rem;

    z-index: 2;

    @include breakpoint("tablet-max") {
      flex-direction: column;
      text-shadow: none;
      gap: 1.6rem;
    }
  }

  &__toggle-sound {
    #toggle-cover-video-sound {
      bottom: 10rem;
      right: 0;

      @include breakpoint("tablet-max") {
        transform: translateX(80px);
        bottom: 4rem;
      }
    }
  }

  &__price-wrapper {
    position: relative;
  }

  &__price-block {
    opacity: 0;
    margin-top: 0.4rem;

    &.loaded {
      animation: reveal-in-from-bottom var(--anim-medium-duration) backwards;
      animation-delay: 0.5s;
      animation-fill-mode: both;
    }

    span {
      &:first-child {
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        align-items: center;
        gap: 1.6rem;

        margin-bottom: 0.8rem;

        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-size: 1.2rem;
        color: #ffffff;

        &::before,
        &::after {
          content: '';
          height: 0.1rem;
          background: #ffffff;
        }
      }

      &:last-child {
        display: block;
        width: max-content;
        color: #ffffff;
        font-size: 3.2rem;
        letter-spacing: 0.2rem;
      }
    }
  }

  &__about {
    color: var(--gray-9);
    font-size: 4rem;
    line-height: 1.2;
    max-width: 99.4rem;
    font-weight: 300;
    letter-spacing: 2px;
  }

  &__details {
    margin: 3.2rem;

    @include breakpoint("laptop-max") {
      margin: 0 var(--container-padding);
    }
  }

  &__details-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3.2rem;

    border-radius: var(--image-radius, 0);
    box-shadow: 0 2px 1.6rem #a0a0a01a;
    padding: 2.4rem;

    @include breakpoint("laptop-max") {
      grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    }
  }

  &__details-item {
    display: grid;
    grid-template-columns: max-content 1fr;

    column-gap: 1.6rem;
    row-gap: 0.8rem;

    transition: all var(--anim-default-duration);
  }

  &__details-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    grid-row: 1 / 3;
    align-self: center;

    width: 7.4rem;
    height: 7.4rem;

    border: solid 0.1rem var(--gray-6);
    border-radius: 50%;

    img {
      height: 3.6rem;
      width: 3.6rem;
    }
  }

  &__details-title {
    color: var(--gray-7);
    align-self: end;
    font-weight: 300;
    font-size: 1.6rem;
    letter-spacing: 1px;
  }

  &__details-desc {
    color: var(--gray-9);
    font-weight: 500;
    font-size: 2.4rem;
  }
}


