@import "../variables";
@import "../mixins";

html {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 10px;
  height: 100%;
  line-height: 1.2;
  @include breakpoint("large-desktop") {
    font-size: calc(10px + (39.61 - 13.61) * (100vw - 1921px) / (6017 - 1921));
  }
  @include breakpoint("desktop") {
    font-size: calc(9px + (12 - 9) * (100vw - 1440px) / (1921 - 1440));
  }
  @include breakpoint("large-laptop") {
    font-size: calc(8px + (10 - 8) * (100vw - 1241px) / (1440 - 1241));
  }
  @include breakpoint("laptop") {
    font-size: calc(8px + (10 - 8) * (100vw - 1025px) / (1240 - 1025));
  }
  @include breakpoint("tablet") {
    font-size: calc(8px + (10 - 8) * (100vw - 768px) / (1024 - 768));
  }
  @include breakpoint("phone") {
    font-size: 10px;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.2;
  color: var(--text-color);
  font-style: normal;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  @include breakpoint("phone-max") {
    --sections-shadow: 0 2.4rem 3.2rem rgba(0, 0, 0, 0.15);
    &.is-menu-open {
      overflow: hidden;
    }
  }
}

.main-content {
  flex: 1;
}

.section {
  padding: 4.8rem 0;

  @include breakpoint("tablet-max") {
    padding: 2.4rem 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 128rem;
  padding: 0 var(--container-padding);
  margin: 0 auto;
  height: 100%;

  &--large {
    max-width: 139.2rem;
  }
  &--full {
    max-width: 100%;
  }
}

.l-container {
  max-width: 122rem;
  margin: 0 auto;
  padding: 0 var(--l-container-padding);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: var(--main-color);
}

p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 4.8rem;
  line-height: 6rem;
  font-weight: 500;
  @include breakpoint("phone-max") {
    font-size: 3.2rem;
    line-height: 4.4rem;
  }
}

h2 {
  font-size: 4.8rem;
  line-height: 6rem;
  font-weight: 500;
  text-transform: uppercase;
  @include breakpoint("phone-max") {
    font-size: 3.2rem;
    line-height: 4.4rem;
  }
}

h3 {
  font-size: 3.2rem;
  line-height: 4.4rem;
  font-weight: 500;
  @include breakpoint("phone-max") {
    font-size: 2rem;
    line-height: 3.4rem;
  }
}

button {
  all: unset;
}

.b-title {
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--gray-9);

  &--centered {
    text-align: center;
  }

  &--margin {
    margin-bottom: 2.4rem;
  }
}

.l-title {
  font-family: var(--font-trirong);
  text-transform: unset;
  color: var(--luxury-heading);

  &--gray {
    color: var(--luxury-platinum-100);
  }

  &--medium {
    font-size: 6rem;
    font-weight: 400;
    line-height: 12rem;
  }

  &--large {
    font-size: 6.4rem;
    font-weight: 300;
    line-height: 6.8rem;
    letter-spacing: -2px;
  }

  @include breakpoint("tablet-max") {
    font-size: 4.8rem;
    line-height: 9.6rem;
  }
}

.l-subtitle {
  display: block;

  font-family: var(--font-ibm-plex-sans);
  font-size: 2.4rem;

  color: var(--luxury-heading);

  @include breakpoint("tablet-max") {
    font-size: 1.6rem;
  }

  &--margin {
    margin-bottom: 0.8rem;
  }
}

.l-label {
  font-family: var(--font-ibm-plex-sans);
  line-height: 2.4rem;
  font-size: 1.3rem;
  font-weight: 600;

  color: var(--luxury-heading);
  text-transform: uppercase;
}

.b-subtitle {
  display: inline-block;
  width: 100%;
  margin: 0.8rem 0 2.4rem;
  font-size: 2rem;
  color: var(--gray-6);

  &--dotted {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    gap: 0.5rem;

    &::before {
      content: '';
      display: block;
      height: 0.8rem;
      width: 0.8rem;
      background-color: var(--accent-color);
      border-radius: 50%;
    }
  }

  &--colored {
    color: var(--accent-color);
  }

  &--centered {
    text-align: center;
  }
}

.hero-wrapper {
  position: relative;

  background-size: cover;
  background-position: center;
  
  height: 100%;

  &--h-screen {
    height: 100vh;
  }

  @include breakpoint("tablet-max") {
    height: 600px;
  }
}

.block {
  display: block;
}