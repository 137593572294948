.b-header {
  position: fixed;
  z-index: 1;

  padding: 2.4rem 0;

  width: 100%;

  left: 0;
  right: 0;
  top: 0;

  transition: 0.6s, transform 0.6s, padding 0.6s;
  transform: translate3d(0, 0, 0);

  background: rgba(173, 181, 189, 0.95);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  &.is-hidden {
    background: unset;
    transform: translate3d(0, -100%, 0);
    box-shadow: unset;
  }

  &.is-transparent {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: unset;
  }

  &.loaded {
    animation: var(--anim-medium-duration) ease-in fade-in both;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 2.4rem;
    flex-direction: unset;
  }

  &__btn {
    @include breakpoint("tablet-max") {
      display: none !important;
    }
  }

  &__menu-header {
    display: none;

    @include breakpoint("tablet-max") {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      span {
        font-size: 1.4rem;
        text-transform: uppercase;
      }
    }
  }

  &__close-nav {
    width: max-content;
    margin: unset;
  }

  &__nav {
    @include breakpoint("tablet-max") {
      position: fixed;
      top: 0;
      right: 0;

      align-items: start;
      justify-content: start;
      flex-direction: column;

      transform: translate(100%);
      transition: var(--anim-nav);

      width: 100%;
      height: 100vh;
      padding: 3.2rem;

      background-color: #ffffff;
      clip-path: polygon(50% 0, 100% 0%, 100% 100%, 95% 100%);
    }

    &.open {
      z-index: 3;
      transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

      .b-header__list-link {
        color: var(--gray-9);

        &.active {
          font-weight: 600;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;

      opacity: 0;

      width: 100%;
      height: 100%;

      background-color: rgba(#000000, 0.2);

      pointer-events: none;
    }
  }

  &__logo {
    width: 20rem;
  }

  &__btn {
    justify-self: end;
  }

  &__list {
    display: flex;
    gap: 3rem;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    .contacts {
      margin-top: 2.4rem;
      width: 100%;
      text-align: center;
    }

    @include breakpoint("tablet-max") {
      flex-direction: column;
      gap: 1.8rem;
      margin-top: 2.4rem;
    }
  }

  &__list-item {
    list-style: none;
   
    @include breakpoint("tablet-max") {
      opacity: 0;
    }
  }

  &__burger {
    width: 100px;
    height: 100px;
  }

  &__list-link,
  .custom-links-menu a {
    font-size: 1.8rem;

    &:link,
    &:visited {
      position: relative;

      text-decoration: none;
      text-transform: capitalize;
      color: #ffffff;

      transition: all var(--anim-default-duration);
    }

    @include breakpoint("tablet-max") {
      gap: 1.8rem;
      color: var(--gray-9);
      font-size: 2.4rem;
      letter-spacing: 0.1rem;
      flex-direction: column;
      text-transform: uppercase;
    }

    &::after,
    &::before {
      transition: transform var(--anim-small-duration) ease;
    }

    &::after {
      content: "";

      position: absolute;
      left: 0;
      bottom: 0;

      display: block;

      transform: scaleX(0) translateY(0.2rem);
      transform-origin: 100% 0;

      width: 100%;
      height: 2px;
      background-color: #ffffff;
    }

    &:hover,
    &.active {
      &::after {
        transform: scale(1) translateY(0.2rem);
        transform-origin: 0 0;
      }
    }
  }

  // custom link menu redesign
  menuitem:hover > menu {
    pointer-events: initial;
  }

  .custom-links-menu {
    @include breakpoint("phone-max") {
      display: none;
    }
  }

  .mobile-only {
    @include breakpoint("tablet-min") {
      display: none;
    }
  }

  .custom-links-menu {
    a {
      font-size: 1.8rem;
      color: #ffffff;
    }

    &__container {
      menuitem {
        &:first-child {
          &::before {
            content: "";

            position: absolute;
            top: -1rem;
            right: 0;

            border-left: 1rem solid transparent;
            border-right: 1rem solid transparent;
            border-bottom: 1rem solid #ffffff;

            width: 0;
            height: 0;
          }
        }
      }

      .link-item a {
        justify-content: center;
        margin: 0;

        color: var(--gray-8);
        text-transform: capitalize;
        text-decoration: none;

        transition: all var(--anim-default-duration);

        border-bottom: solid 0.2rem transparent;
        background-color: #fff;

        &:hover,
        &:active {
          border-bottom: solid 0.2rem var(--accent-color);
        }

        &::before {
          display: none;
        }

        &::after {
          background-color: transparent;
        }
      }
    }
  }
}
