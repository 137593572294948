.l-tour {
  padding: 9rem 0 19rem;

  @include breakpoint("tablet-max") {
    padding: 4rem 0;
  }

  &__subtitle {
    @include breakpoint("tablet-max") {
      display: none;
    }
  }

  &__buttons {
    @include breakpoint("tablet-max") {
      padding: 0 1.2rem;
      padding-bottom: 11rem;
    }
  }

  &__iframe {
    @include breakpoint("tablet-max") {
      height: 65rem;
    }
  }

  &__pattern {
    position: absolute;
    bottom: 0;

    @include breakpoint("tablet-max") {
      display: none;
    }
  }
}
