.l-presented {
  position: relative;

  background-color: var(--luxury-gray);

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;

    row-gap: 1.6rem;
    column-gap: 2rem;

    padding-top: 6.4rem;
    padding-bottom: 18rem;

    @include breakpoint("laptop-max") {
      grid-template-columns: 1fr;
    }

    @include breakpoint("tablet-max") {
      padding-top: 4.4rem;
      padding-bottom: 14rem;
    }
  }

  &__title {
    align-self: center;

    @include breakpoint("laptop-max") {
      justify-self: center;
    }
  }

  &__branding-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4.4rem;
  }

  &__branding {
    width: 21.5rem;
    height: auto;
  }

  &__branding-wrapper,
  &__agent {
    background-color: #ffffff;
  }

  &__agent {
    display: flex;
    align-items: flex-start;

    gap: 2.4rem;

    height: 100%;
    padding: 4rem 6rem;

    @include breakpoint("tablet-max") {
      flex-direction: column;

      padding: 4rem;
    }

    &:last-child {
      grid-column: 2;

      @include breakpoint("laptop-max") {
        grid-column: unset;
      }
    }
  }

  &__info {
    font-family: var(--font-ibm-plex-sans);
    font-size: 1.4rem;
    font-weight: 400;

    line-height: 2.1rem;
    color: var(--gray-11);

    & > * {
      display: block;
    }
  }

  &__link {
    &:link,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }

  &__headshot {
    width: 16rem;
    height: 16rem;
  }

  &__name {
    margin-bottom: 0.4rem;
    font-family: var(--font-trirong);
    font-size: 3.2rem;
    letter-spacing: -1px;

    line-height: 4rem;
    color: var(--gray-10);
  }

  &__position {
    font-size: 2rem;
    line-height: 2rem;

    letter-spacing: -1px;
  }

  &__brokerage-name {
    margin-top: 1.6rem;
  }

  &__website-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;

    margin-top: 1.6rem;
    cursor: pointer;
  }

  &__website {
    &:link,
    &:visited {
      font-size: 1.6rem;
      font-weight: 600;
      letter-spacing: -1px;

      color: var(--gray-10);
    }
  }

  &__icon {
    height: 1.3rem;
    width: 1.3rem;
  }
}
