.l-preloader {
  pointer-events: none;

  position: fixed;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;

  transition: transform 0.8s var(--anim-easing-in-out);

  background-color: var(--accent-color);
  width: 100%;
  height: 100vh;
  color: #ffffff;

  &.loaded {
    transform: translateY(-101%);
    transition-delay: 0.4s;

    .l-preloader {
      &__loader {
        opacity: 0;
      }
    }
  }

  &__loader {
    animation: spin 2s linear infinite;
    transition: all 0.4s ease;

    border: 2px solid #ffffff;
    border-top-color: var(--accent-color);
    border-radius: 50%;
    height: 8rem;
    width: 8rem;
  }
}
