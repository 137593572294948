@import "../../../mixins";

.footer__section {
  padding: 64px 24px 40px;
  background: #333333;

  @include breakpoint("phone-max") {
    padding: 48px 16px 24px;
  }

  .wrapper {
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 24px;

    @include breakpoint("phone-max") {
      padding: 16px;
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #AFAFAF;
      margin: 4px 0;
    }

    &__value {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 34px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      margin: 4px 0;
    }
  }

  .icons {
    display: flex;
    justify-content: center;

    img {
      width: 56px;
      margin: 24px 16px 0;
    }
  }

  .logo {
    display: flex;
    justify-content: center;

    img {
      max-height: 80px;
      margin-bottom: 4px;
    }
  }

  .agency {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    color: #AFAFAF;
    margin: 4px 0px;

    a, a:hover, a:visited, a:active {
      color: #AFAFAF;
    }
  }
}