.l-overview {
  padding: 10rem 0 9rem;

  @include breakpoint("tablet-max") {
    padding: 5rem 0 4rem;
  }

  &__container {
    position: relative;

    display: flex;

    @include breakpoint("laptop-max") {
      min-height: 56.2rem;
    }

    @include breakpoint("tablet-max") {
      flex-direction: column;

      min-height: unset;
    }

    &::before {
      content: "";
      z-index: 1;

      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -84%);

      background-color: var(--luxury-heading);
      width: 0.2rem;
      height: 30rem;

      @include breakpoint("tablet-max") {
        transform: translate(-50%, -50%);
      }
    }
  }

  &__title {
    margin-bottom: 1.6rem;
    width: 100%;

    @include breakpoint("tablet-max") {
      font-size: 3.2rem;
      font-weight: 400;

      line-height: 4rem;
      letter-spacing: -1px;
    }
  }

  &__paragraph {
    font-family: var(--font-ibm-plex-sans);
    line-height: 4rem;
    font-weight: 400;
    font-size: 2rem;

    color: var(--gray-10);

    @include breakpoint("tablet-max") {
      font-size: 1.6rem;

      line-height: 3.2rem;
    }
  }

  &__table {
    margin: 4rem 0;
  }

  &__content {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    flex: 1;

    padding: 8rem 9rem 8rem;
    background-color: var(--luxury-gray);

    @include breakpoint("laptop-max") {
      padding: 8rem 5rem;
    }

    @include breakpoint("tablet-max") {
      flex-direction: column;

      padding: 17.8rem 2.8rem 2.8rem;
      min-height: unset;
    }
  }

  &__image-wrapper {
    flex: 0 0 45rem;

    @include breakpoint("laptop-max") {
      flex: 0 0 35rem;
    }

    @include breakpoint("tablet-max") {
      flex: unset;

      height: 65rem;
    }
  }

  &__image {
    display: block;

    height: 100%;
    object-fit: cover;
  }

  &__table-row {
    padding: 0.4rem 0;

    &:last-of-type td {
      border-bottom: 1px solid var(--luxury-platinum-100);

      @include breakpoint("tablet-max") {
        border-bottom: unset;
      }
    }

    &:last-child {
      @include breakpoint("tablet-max") {
        border-bottom: 1px solid var(--luxury-platinum-100);
      }
    }
  }

  &__table-data {
    font-family: var(--font-trirong);
    font-size: 1.6rem;

    line-height: 2.4rem;
    color: var(--luxury-heading);

    @include breakpoint("tablet-max") {
      display: block;
    }

    &:nth-child(1) {
      font-weight: 600;
      width: 22rem;

      @include breakpoint("tablet-max") {
        border: unset;
      }
    }

    &:nth-child(2) {
      font-weight: 400;

      border-bottom: 1px solid var(--luxury-platinum-100);

      @include breakpoint("tablet-max") {
        padding-top: unset;
      }
    }
  }

  &__footer {
    display: flex;
    gap: 1.6rem;

    margin-right: auto;

    @include breakpoint("tablet-max") {
      flex-direction: column;

      margin-right: unset;
      width: 100%;
    }
  }

  &__pattern {
    position: absolute;
    left: 0;
    bottom: 0;

    transform: translate(-50%, 50%);

    @include breakpoint("tablet-max") {
      top: 0;
      right: 0;
      left: unset;
      bottom: unset;

      transform: translate(0.5rem, -2rem)
    }
  }
}
