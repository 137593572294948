.l-gallery {
  padding: 8.4rem 0 10rem;

  @include breakpoint("tablet-max") {
    padding: 6.4rem 0 5rem;
  }

  &__header {
    margin-bottom: 4.2rem;
  }

  &__swiper {
    position: relative;
  }

  &__slide {
    &.swiper-slide {
      height: 62.6rem;
      cursor: pointer;

      @include breakpoint("tablet-max") {
        height: 48rem;
      }
    }
  }

  &__arrow-btn {
    &.swiper-button-disabled {
      opacity: 0;
    }
  }
}
