.hero {
  &__section {
    --info-height: 4rem; //9.4rem;
    position: relative;
    padding-top: var(--header-height);
    margin-bottom: 6.3rem;
    @include breakpoint("phone-max") {
      margin-bottom: 3.2rem;
    }
  }
  &__pattern {
    left: 0;
    top: -36.5%;
    background-image: url("../assets/patterns/hero.svg");
    @include breakpoint("phone-max") {
      display: none;
    }
  }   
  &__container {
    @include breakpoint("tablet-max") {
      padding: 0 0.8rem;
    } 
  }
  &__wrapper {
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 1;
    width: 100%;
    height: calc(100vh - var(--header-height));
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    box-shadow: var(--sections-shadow);
    animation: fade-in var(--anim-medium-duration) var(--anim-easing-out)
      forwards;
    will-change: opacity;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.8)
      );
    }
    @include breakpoint("tablet-max") {
      justify-content: center;
      width: initial;
      left: initial;
      transform: initial;
      height: 57.6rem;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    color: #fff;
    padding: 5.5rem 5.6rem;
    transform-style: preserve-3d;
    will-change: opacity, transform;
    transform: translate3d(0, 100%, 0);
    animation: reveal-in-from-bottom var(--anim-medium-duration)
      var(--anim-easing-out) forwards;
    // animation-delay: var(--anim-medium-duration);
    @include breakpoint("tablet-max") {
      padding: 2.4rem 1.2rem;
      text-align: center;
    }
  }
  &__tag {
    display: inline-flex;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    padding: 0.4rem 1.2rem;
    text-transform: uppercase;
    border-radius: var(--hero-radius, 0);
    background-color: var(--hero-tag-color);
    margin-bottom: 0.8rem;
    @include breakpoint("phone-max") {
      margin-bottom: 1.2rem;
    }
  }
  &__address {
    max-width: 56rem;
    text-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    @include breakpoint("tablet-max") {
      max-width: initial;
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // height: var(--info-height, 0);
    width: 100%;
    margin: 0 auto;
    @include breakpoint("phone-max") {
      height: initial;
    }
    &-item {
      padding: 1.6rem 1.2rem 0 1.2rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      @include breakpoint("phone-max") {
        flex: 0 0 50%;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        &:first-of-type {
          flex: 0 0 100%;
          border-top: 0;
          border-right: 0;
        }
        &:nth-of-type(2n + 1) {
          border-right: 0;
        }
      }
      &:last-of-type {
        border-right: 0;
      }
      span {
        font-size: 2rem;
        line-height: 3.4rem;
        font-weight: 500;
        color: #757575;
        align-self: flex-start;
        @include breakpoint("phone-max") {
          font-size: 1.8rem;
          line-height: 2.6rem;
        }
      }
      strong {
        font-size: 3.2rem;
        line-height: 4.4rem;
        font-weight: 500;
        color: #141414;
        align-self: flex-end;
        @include breakpoint("phone-max") {
          font-size: 2rem;
          line-height: 3.4rem;
        }
      }
      .lot-size-unit {
        text-transform: capitalize;
      }
    }
  }
}

#toggle-cover-video-sound {
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: 2;
  width: max-content;
  cursor: pointer;

  @include breakpoint("tablet-max") {
    right: 0;
    bottom: 0;
  }

  img {
    height: 50px;
  }

  .muted {
    display: none;
  }

  .unmuted {
    display: block;
  }

  &[data-muted] {
    .muted {
      display: block;
    }
    .unmuted {
      display: none;
    }
  }
}
