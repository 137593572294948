.l-map {
  background-color: var(--accent-color);

  &__section-header {
    margin-bottom: 4.8rem;
  }

  &__container {
    position: relative;

    padding-top: 18rem;
    padding-bottom: 18rem;

    @include breakpoint("tablet-max") {
      padding-top: 9rem;
      padding-bottom: 13rem;
    }
  }

  &__pattern {
    position: absolute;

    @include breakpoint("tablet-max") {
      height: 5rem;
      width: 5rem;
    }

    &--top {
      top: 0;
      @include breakpoint("tablet-max") {
        right: 0;
      }
    }

    &--bottom {
      bottom: 0;
      left: var(--l-container-padding);

      @include breakpoint("tablet-max") {
        left: 0;
      }
    }
  }

  &__map-container {
    max-width: 100%;

    @include breakpoint("tablet-max") {
      height: 65rem;
    }
  }
}
