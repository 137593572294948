.overview {
  &__section {
    position: relative;
    z-index: 1;
    margin-bottom: 6.5rem;
    @include breakpoint('phone-max') {
      margin-bottom: 3.2rem;
    }
    .container {
      position: relative;
      z-index: 2;
    }
  }
  &__title {
    margin-bottom: 2.4rem;
    @include breakpoint('phone-max') {
      margin-bottom: 1.2rem;
    }
  }
  &__tabs {
    .tabs__body {
      padding-top: 5.3rem;
      @include breakpoint('phone-max') {
        padding-top: 2.1rem;
      }
    }
    table {
      td {
        &:nth-child(2) {
          color: var(--main-color);
        }
      }
    }
  }
}
