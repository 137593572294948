.b-video {
  &__wrapper {
    border-radius: var(--wrapper-radius, 0);
    overflow: hidden;
  }

  .react-player {
    &__preview {
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: linear-gradient(
          180deg,
          transparent 0%,
          rgba(0, 0, 0, 0.4) 100%
        );
      }
    }

    &__play-icon {
      position: absolute;
      display: grid;
      align-items: center;
      overflow: hidden;
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) scale(1);
      background-color: #ffffff;
      color: #46484b;
      color: var(--grey50);
      text-align: center;
      font-size: 1.8rem;
      font-weight: 400;
      cursor: pointer;
      transition: all 0.6s var(--anim-play-video-btn);

      will-change: transform;
      z-index: 100;

      border-style: unset !important;
      border-width: unset !important;
      border-color: unset !important;
      margin-left: unset !important;

      @include breakpoint("tablet-max") {
        width: 10rem;
        height: 10rem;
      }

      &:hover {
        transform: translateX(-50%) translateY(-50%) scale(1.12);
        background-color: var(--accent-color);
        transition: all 0.6s var(--anim-play-video-btn);

        &::before,
        &::after {
          transform: translateY(-7rem) scale(0.88);

          @include breakpoint("tablet-max") {
            transform: translateY(-6rem) scale(0.88);
          }
        }
      }

      &::before,
      &::after {
        content: "Play video";
        font-family: inherit;
        transition: all 0.6s var(--anim-play-video-btn);
        font-weight: 500;
      }

      &::before {
        display: block;
        transform: translateY(0) scale(1);
      }

      &::after {
        color: #ffffff;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
      }
    }
  }
}
