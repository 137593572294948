.map-section {
  padding-bottom: 6.4rem;
  background: #fafafa;

  @include breakpoint("phone-max") {
    padding-bottom: 3.2rem;
  }

  &.unbranded {
    padding-top: 6.4rem
  }

  &__description {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.6rem;
    margin: auto;
    background-color: #333;
    padding: 0.4rem 1.2rem;
    border-radius: var(--hero-radius, 0);
    text-transform: uppercase;
    font-weight: 500;
    span {
      color: #fff;
      font-size: 1.4rem;
      line-height: 2rem;
    }
    @include breakpoint("phone-max") {
      transform: none;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 90%;
      text-align: center;
      padding: 0.4rem 0.8rem;
      span {
        display: block;
        max-width: 16rem;
        margin: 0 auto;
      }
    }
  }
}
