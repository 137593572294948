.b-overview { 
  .b-title {
    @include breakpoint("tablet-max") {
      text-align: center;
    }
  }
  
  &__text {
    font-size: 2.1rem;
    line-height: 1.7;
    color: var(--gray-9);

    @include breakpoint("tablet-max") {
      text-align: center;
    }
  }

  &__wrapper {
    &--grid {
      display: grid;
      grid-template-columns: 52rem auto;
      gap: 6.2rem;

      @include breakpoint("laptop-max") {
        display: unset;
      }

      .b-overview {
        &__content {
          margin-top: 4.4rem;

          @include breakpoint("laptop-max") {
            margin-top: 3.2rem;
          }
        }
      }
    }

    @include breakpoint("tablet-max") {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__swiper {
    cursor: zoom-in;
    height: 45rem;
    border-radius: 0 var(--basic-overview-radius, 0) 0 0;
    overflow: hidden;
    margin-top: 3.2rem;
    box-shadow: 5rem 5rem 10rem rgba(34, 34, 34, 0.15);
    overflow: hidden;

    @include breakpoint("laptop-max") {
      border-radius: var(--image-radius, 0);
    }

    @include breakpoint("tablet-max") {
      height: 30rem;
    }
  }

  &__swiper-top {
    .swiper-slide img {
      object-fit: unset;

      @include breakpoint("tablet-max") {
        object-fit: cover;
      }
    }
  }

  &__swiper-thumbs {
    .swiper-slide {
      img {
        cursor: pointer;
        transition: all 0.3s;
        height: 8.3rem;

        @include breakpoint("laptop-max") {
          border-radius: var(--image-radius, 0);
        }

        &:hover {
          transform: scale(1.04);
        }
      }
    }
  }

  &__button {
    margin-left: auto;
    margin-right: 3.2rem;
  }

  &__tabs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;

    @include breakpoint("tablet-max") {
      flex-direction: column;
      align-items: center;
      gap: 1.6rem;
    }
  }

  &__tabs-content {
    margin-right: 3.2rem;
    margin-top: 2.4rem;

    table {
      tr {
        &:not(:first-child) {
          td {
            padding-top: 1.4rem;
          }
        }
      }

      td {
        padding: 0;

        &:nth-child(2) {
          color: var(--gray-9);
        }
      }
    }
  }
  

  &__documents {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    gap: 0.8rem;
    color:var(--gray-9);
    margin-left: auto;
    margin-right: 3.2rem;

    @include breakpoint("tablet-max") {
      margin-left: unset;
    }

    span {
      font-size: 1.8rem;
    }
  }
}

.pop-up {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all var(--anim-default-duration);

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(24, 24, 24, 0.4);
    cursor: pointer;
  }

  &.opened {
    opacity: 1;
    visibility: visible;

    .pop-up {
      &__content {
        transform: scale(1);
      }
    }
  }

  &__icon {
    position: absolute;
    right: 1.6rem;
    top: 1.4rem;
    font-weight: bold;
    font-style: normal;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    position: relative;
    
    padding: 4rem 3.2rem;
    border-radius: 2rem;
    background-color: #ffffff;
    transform: scale(0.25);
    transition: all var(--anim-default-duration);
    border-top: 6px solid var(--accent-color);

    .documents-wrapper__row {
      gap: 0.8rem;
      
      span {
        font-size: 1.8rem;
        font-weight: 400;
      }
    }
  }
  
  &__title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
    color: var(--gray-9);
  }
  
  &__paragraph {
    text-align: center;
    line-height: 1.4;
    
    span {
      background-color: gray;
      border-radius: 50%;
      padding: 0 0.4rem;
      cursor: pointer;
      transition: all var(--anim-default-duration);
      
      &:hover {
        background-color: darken(gray, 10%);
      }
    }
  }

}
