.gallery {
  &__section {
    position: relative;
    z-index: 2;
    padding-bottom: 12.5rem;
    @include breakpoint("phone-max") {
      padding-bottom: 5.2rem;
      .container {
        padding: 0 0.8rem;
      }
    }
  }
  &__groups-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .gallery__group {
      width: calc(50% - 1.2rem);
      margin-bottom: 2.4rem;
      border-radius: var(--image-radius, 0);
      box-shadow: var(--sections-shadow);
      overflow: hidden;
      position: relative;
      // 'after' blocks image click events
      //&:after {
      //  content: '';
      //  position: absolute;
      //  z-index: 1;
      //  left: 0;
      //  top: 0;
      //  bottom: 0;
      //  right: 0;
      //  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
      //}
      &:nth-child(2) {
        margin-top: 6.4rem;
      }
      &:nth-child(odd) {
        margin-top: -6.4rem;
      }
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      .gallery__item {
        span {
          top: initial;
          right: initial;
          left: 2.4rem;
          bottom: 2.4rem;
        }
        strong {
          top: initial;
          right: 2.4rem;
          left: initial;
          bottom: 2.4rem;
        }
      }
      @include breakpoint("phone-max") {
        width: initial;
        margin-top: 0 !important;
        margin-bottom: 2.4rem !important;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  &__shadow-wrapper {
    position: relative;
    z-index: 2;
    overflow: hidden;
    border-radius: var(--image-radius, 0);
    box-shadow: var(--sections-shadow);
    @include breakpoint("phone-max") {
      background-color: #eee;
    }
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.4rem;
    @include breakpoint("phone-max") {
      flex-direction: column;
      margin-bottom: 0.8rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &--reverse {
      .gallery__box {
        &:first-of-type {
          order: 2;
          @include breakpoint("phone-max") {
            order: 0;
          }
        }
        &--main {
          .gallery__item {
            margin-right: 0;
            margin-left: 2.4rem;
            @include breakpoint("phone-max") {
              margin-left: 0;
              margin-bottom: 0.8rem;
            }
          }
        }
      }
    }
  }
  &__box {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    margin-bottom: -2.4rem;
    @include breakpoint("phone-max") {
      margin-right: -0.4rem;
      margin-left: -0.4rem;
      margin-bottom: -0.8rem;
    }
    .gallery__item {
      width: calc(50% - 2.4rem);
      max-height: 19.4rem;
      border-radius: var(--image-radius, 0);
      margin-left: 1.2rem;
      margin-right: 1.2rem;
      margin-bottom: 2.4rem;
      @include breakpoint("phone-max") {
        width: calc(50% - 0.8rem);
        margin-bottom: 0.8rem;
        margin-right: 0.4rem;
        margin-left: 0.4rem;
        max-height: 10.5rem;
        &:nth-of-type(1n + 3) {
          display: none;
        }
      }
    }
    &--main {
      margin: 0 0 -2.4rem 0;
      @include breakpoint("phone-max") {
        margin: 0;
      }
      .gallery__item {
        flex: 1;
        max-height: 41.2rem;
        margin-left: 0;
        margin-right: 2.4rem;
        @include breakpoint("phone-max") {
          width: 100%;
          margin-right: 0;
          margin-bottom: 0.8rem;
          max-height: 21.6rem;
        }
      }
    }
  }
  &__item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:before,
    &:after {
      opacity: 0;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
    &::before {
      z-index: 4;
      background-image: url("../assets/icons/zoom.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 2.66rem 2.66rem;
    }
    &::after {
      transition: opacity var(--anim-default-duration) var(--anim-easing-in);
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
    }
    &:hover {
      &:before,
      &:after {
        opacity: 1;
      }
    }
    &--with-info {
      &:after {
        opacity: 1;
      }
      &::before {
        display: none;
      }
    }
    strong,
    span {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    strong {
      font-style: italic;
      z-index: 2;
      font-size: 14.4rem;
      line-height: 12rem;
      font-weight: 800;
      color: #fff;
      opacity: 0.4;
      @include breakpoint("tablet-max") {
        font-size: 11rem;
      }
    }
    span {
      z-index: 3;
      font-size: 3.2rem;
      line-height: 4.4rem;
      font-weight: 500;
      color: #fff;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__result {
    display: none;
    cursor: pointer;
    padding: 1.2rem 1.1rem 1rem 1.1rem;
    border: 1px solid #e2e2e2;
    border-radius: var(--image-radius, 0);
    margin-top: 0.75rem;
    @include breakpoint("phone-max") {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 2rem;
        line-height: 3.2rem;
        color: #142414;
        font-weight: 500;
      }
      strong {
        font-size: 7.2rem;
        line-height: 6.4rem;
        font-weight: 800;
        font-style: italic;
        color: #f6f6f6;
      }
    }
  }
}
