.b-contacts {
  &__title {
    margin-bottom: unset;
  }

  &__agents {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
   
    border-radius: var(--image-radius, 0);
    padding: 2.4rem;
    margin-bottom: 3.2rem;
    box-shadow: 0 2px 1.6rem #a0a0a01a;
  }

  &__agent-wrap {
    display: grid;
    align-items: center;
    justify-content: space-evenly;
    grid-template-columns: repeat(2, 1fr);

    &:not(:last-child) {
      @include breakpoint("laptop-max") {
        border-bottom: 1px solid var(--gray-1);
        padding-bottom: 2.4rem;
      }
    }

    .img-branding {
      max-width: 19rem;
      max-height: 9.6rem;
      justify-self: center;
    }

    @include breakpoint("tablet-max") {
      grid-template-columns: 1fr;
      justify-content: center;
    }

    .agent {
      @include breakpoint("laptop-max") {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      &__personal {
        @include breakpoint("laptop-max") {
          justify-content: center;
        }
      }
    }
  }

  &__form {
    display: flex;
    align-items: center;
    padding: 4.9rem;
    border-radius: var(--image-radius, 0);
    box-shadow: 0 2px 1.6rem #a0a0a01a;

    .form {
      &__container {
        max-width: 65rem;
        width: 100%;
      }
    }
  }
}
