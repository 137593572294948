.l-footer {
  padding: 5.8rem 0;

  background-color: var(--accent-color);

  color: var(--luxury-platinum);

  @include breakpoint("tablet-max") {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint("tablet-max") {
      flex-direction: column-reverse;
      row-gap: 4.8rem;

      text-align: center;
    }
  }

  &__icons {
    display: flex;
    column-gap: 1.6rem;

    @include breakpoint("tablet-max") {
      column-gap: 4.8rem;
    }
  }

  &__presented {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    margin-bottom: 0.4rem;

    @include breakpoint("tablet-max") {
      align-items: center;
    }

    img {
      width: 14.8rem;
    }

    span,
    a:link,
    a:visited {
      font-family: var(--font-ibm-plex-sans);
      font-size: 1.3rem;
      line-height: 2.4rem;
      color: var(--luxury-gray);
      text-decoration: none;
    }
  }

  &__brokerage {
    font-family: var(--font-ibm-plex-sans);
    color: #ffffff;
  }
}