.dev-menu {
  position: fixed;
  top: var(--header-height, 0);
  left: 0;
  z-index: 101;
  transform: translate3d(-100%, 0, 0);
  transition: transform var(--anim-default-duration) var(--anim-easing-in);
  &.visible {
    transform: translate3d(0, 0, 0);
  }
  &__container {
    display: flex;
    width: 30rem;
    height: 50rem;
    max-height: 100vh;
    overflow: auto;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: 0;
    border-bottom-right-radius: 1rem;
  }
  &__title {
    padding: 0.5rem 1.5rem 0.5rem 0.4rem;
  }
  span {
    font-size: 2rem;
    font-weight: 600;
    color: var(--main-color);
  }
  p {
    font-size: 1.2rem;
  }
  &__box {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 1.5rem 0.5rem 0.4rem;
    &:last-of-type {
      border-bottom: 0;
    }
  }
  &__row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  label {
    display: block;
    padding: 1rem 0;
    flex: 1;
  }
  .color-box {
    width: 40%;
    height: 2rem;
  }
  &__button {
    background-color: #fa5252;
    border-radius: 0;
    position: absolute;
    left: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.js-multi {
  padding-top: 3rem;
}
.js-single,
.js-multi {
  &.hidden {
    display: none;
  }
}
