.b-features {
  &__title {
    font-size: 3.6rem;
    margin-bottom: 2.4rem;
    font-weight: 600;
    text-align: center;
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    padding: 0.5rem;
    transition: all var(--anim-default-duration);

    border: 1px solid var(--gray-4);
  }

  &__details-icon {
    display: inline-block;
    transform: rotate(45deg) translateX(-2px) translateY(-2px);
    height: 1.6rem;
    width: 0.8rem;
    border-bottom: 2px solid var(--gray-9);
    border-right: 2px solid var(--gray-9);
    transition: all 0.8s;
  }

  &__background {
    width: 50rem;
    height: 50rem;
    position: relative;
    overflow: hidden;
    margin: -9.2rem 0 -8rem;
    user-select: none;
  }

  &__tags {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    
    border: solid 1px var(--gray-0);
  }

  &__tag {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;

    border: solid 1px var(--gray-0);
    padding: 3.2rem;
    overflow: hidden;
    transition: all 0.8s var(--anim-tag);

    &:hover {
      transform: scale(1.05);
      border: solid 1px #ffffff;

      &::after {
        transform: scale(80);
      }

      figcaption {
        color: var(--accent-color);
      }

      .b-features {
        &__icon-wrap {
          border: solid 1px var(--accent-color);
        }

        &__details-icon {
          border-bottom: 2px solid var(--accent-color);
          border-right: 2px solid var(--accent-color);
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: -6rem;
      top: -6rem;
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      background-color: var(--gray-0);
      transition: all 0.8s var(--anim-tag);
      z-index: -1;
    }

    figcaption {
      color: var(--gray-9);
      font-weight: 500;
      transition: all var(--anim-default-duration);
      text-align: center;
    }
  }
}
