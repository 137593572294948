.l-features {
  display: grid;
  grid-template-columns: minmax(4rem, 1fr) repeat(5, minmax(min-content, 22.8rem)) minmax(4rem, 1fr);

  padding: 9rem 0 9rem;
  min-height: 98.2rem;

  @include breakpoint("tablet-max") {
    display: flex;
    flex-direction: column-reverse;

    padding: 4rem 0 4rem;
  }

  &::after {
    content: "";

    background-color: var(--accent-color);

    width: 100%;
    height: 100%;
  }

  &__image-wrapper {
    grid-column: 1 / 4;

    @include breakpoint("tablet-max") {
      height: 65rem;
    }
  }

  &__content {
    grid-column: 4 / 7;
  }

  &__image {
    display: block;

    width: 100%;
    height: 100%;
    
    object-fit: cover;
  }

  &__header {
    margin-bottom: 4.8rem;
  }

  &__content {
    position: relative;

    display: flex;
    justify-content: center;
    flex-direction: column;

    padding: 10rem 0 12rem 10rem;

    background-color: var(--accent-color);

    @include breakpoint("laptop-max") {
      padding: 6rem 0 6rem 5rem;
    }

    @include breakpoint("tablet-max") {
      padding: 11.2rem 2rem 12.2rem;
    }
  }

  &__amenities {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4.2rem;

    @include breakpoint("laptop-max") {
      grid-template-columns: 1fr;
    }
  }

  &__amenity {
    display: flex;
    gap: 1.8rem;
  }

  &__amenity-image {
    height: 2.6rem;
    width: 2.6rem;
  }

  &__amenity-title {
    font-family: var(--font-ibm-plex-sans);
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 400;

    color: var(--luxury-platinum-100);
  }

  &__pattern {
    position: absolute;

    &--top {
      top: 0;
      right: 0;
    }

    &--bottom {
      bottom: 0;
      left: 0;
    }
  }
}
