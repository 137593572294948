.l-hero {
  padding: 4rem 4rem 8.4rem;

  @include breakpoint("laptop-max") {
    padding: 2rem 2rem 8.4rem;
  }

  @include breakpoint("tablet-max") {
    padding: 1.2rem 1.2rem 6.4rem;
  }

  &__hero-wrapper {
    position: relative;
    height: calc(100vh - 8rem);

    &::after {
      content: "";

      position: absolute;
      bottom: 0;

      background: linear-gradient(187deg, rgba(0, 0, 0, 0) 39.19%, rgba(0, 0, 0, 0.72) 87.61%);

      height: 30%;
      width: 100%;
    }
  }

  &__title-block {
    @include breakpoint("tablet-max") {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__price-block,
  &__status-block,
  &__toggle-sound {
    transition: all 0.6s ease-in-out;
    transition-delay: 1.2s;

    opacity: 0;

    &.no-delay {
      transition-delay: 0s;
    }

    &.loaded {
      opacity: 1;
    }
  }

  &__price-block {
    color: #ffffff;

    @include breakpoint("tablet-max") {
      opacity: 1;
    }

    span {
      display: block;

      &:first-child {
        font-family: var(--font-ibm-plex-sans);
        font-size: 1.3rem;
        font-weight: 600;

        text-transform: uppercase;
        text-align: right;

        line-height: 2.4rem;
      }

      &:last-child {
        font-family: var(--font-trirong);
        font-size: 4rem;
        font-weight: 300;

        line-height: 4.8rem;
        letter-spacing: -1px;
      }
    }

    &--desktop {
      @include breakpoint("tablet-max") {
        display: none;
      }
    }

    &--mobile {
      display: none;
      padding: 2.4rem 1.2rem;

      color: var(--luxury-black);

      @include breakpoint("tablet-max") {
        display: inline-block;
      }

      span {
        &:first-child {
          text-align: left;
        }

        &:last-child {
          font-size: 3.2rem;
        }
      }
    }
  }

  &__state-zipcode {
    font-family: var(--font-ibm-plex-sans);

    @include breakpoint("tablet-max") {
      font-size: 2rem;
      line-height: 3.6rem;
    }
  }

  &__status-block {
    display: inline-flex;
    align-items: center;

    border-radius: var(--hero-radius, 0);
    margin-bottom: 0.5rem;
    padding: 0.4rem 1.2rem;

    background-color: #ffffff;

    text-align: center;
    font-family: var(--font-ibm-plex-sans);
  }

  &__status {
    font-weight: 500;

    text-shadow: none;
    text-transform: uppercase;

    letter-spacing: 1px;

    @include breakpoint("tablet-max") {
      font-size: 1.2rem;
    }
  }

  &__content {
    z-index: 2;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;

    margin: auto 0 4rem 0;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

    @include breakpoint("laptop-max") {
      margin: auto 0 2rem 0;
    }

    @include breakpoint("tablet-max") {
      margin: auto 0 1rem 0;
      align-items: center;
      flex-direction: column;
      gap: 1.6rem;
    }
  }

  &__toggle-sound {
    &#toggle-cover-video-sound {
      bottom: 16rem;
      right: 2.4rem;

      @include breakpoint("tablet-max") {
        right: 0;
        bottom: 0;
      }
    }
  }

  &__details-wrap {
    position: relative;

    background-color: var(--luxury-gray);

    color: var(--luxury-heading);
    font-weight: 400;

    @include breakpoint("tablet-max") {
      margin: 0 -1.2rem;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5rem;

    padding: 8rem 4rem;

    @include breakpoint("laptop-max") {
      grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
      gap: 4.8rem;

      padding: 6rem 3.2rem;
    }

    @include breakpoint("tablet-max") {
      padding: 6rem 2.4rem;
    }
  }

  &__details-item {
    display: grid;
    grid-template-columns: max-content 1fr;

    column-gap: 2rem;
  }

  &__details-icon {
    height: 2.4rem;
    width: 2.3rem;

    align-self: center;

    &--custom {
      background-color: var(--luxury-heading);
      border-radius: 50%;
      height: 1.4rem;
      width: 1.4rem;
    }
  }

  &__details-title {
    grid-column: 2;

    font-family: var(--font-ibm-plex-sans);
    font-weight: 300;

    line-height: 2.4rem;
  }

  &__details-desc {
    grid-column: 2;
    grid-row: 1;

    font-family: var(--font-trirong);
    font-size: 4rem;

    letter-spacing: -1px;
    line-height: 6rem;
  }

  &__pattern {
    position: absolute;
    right: var(--l-container-padding);
    bottom: 0;

    transform: translateY(50%);

    @include breakpoint("laptop-max") {
      right: 1.2rem;
    }
  }
}
