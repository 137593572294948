:root {
  --header-height: 6.4rem;
  --input-height: 4.4rem;
  --container-padding: 4rem;
  @media screen and (max-width: 1024px) {
    --container-padding: 1.2rem;
  }

  --l-container-padding: 3.2rem;

  @media screen and (max-width: 768px) {
    --l-container-padding: 1.2rem;
  }

  // fonts
  --font-barlow: "Barlow Semi Condensed", sans-serif;
  --font-forum: "Forum", cursive;
  --font-jost: "Jost", sans-serif;
  --font-sora: "Sora", sans-serif;
  --font-tomorrow: "Tomorrow", sans-serif;
  --font-trirong: "Trirong", sans-serif;
  --font-ibm-plex-sans: "IBM Plex Sans", sans-serif;
  --primary-font: var(--font-jost);

  // radiuses
  // hero tag, map description
  --hero-radius: 0; // 1.4rem;
  // hero, video, plans-outer, map
  --wrapper-radius: 0; // 1.2rem;
  // images, plans-inner
  --image-radius: 0; //0.8rem;
  // tags, input, textarea
  --elements-radius: 0; // 0.4rem;
  // button
  --buttons-radius: 0; //5rem;
  // overview radius
  --basic-overview-radius: 0; //5rem;

  // colors
  --accent-color: #f59f00; // #228be6;
  --hero-tag-color: #0ca678;
  --error-color: #fa5252;

  // text colors
  --main-color: #141414;
  --text-color: #757575;

  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --gray-10: #333333;
  --gray-11: #666666;

  --luxury-heading: #282422;
  --luxury-black: #0F0E0D;
  --luxury-gray: #F7F6F5;
  --luxury-platinum: #A69F91;
  --luxury-platinum-100: #EBEAE6;
  --luxury-platinum-200: #D9D6CF;
  --luxury-platinum-300: #C0BCB1;
 
  // shadow
  --sections-shadow: 0 3.2rem 4.8rem rgba(0, 0, 0, 0.15);


  // animations

  --anim-reveal-y: 60%;
  --anim-slide-x: 100%;
  --anim-slide-y: 100%;

  --anim-default-duration: 0.5s;
  --anim-small-duration: 0.35s;
  --anim-medium-duration: 0.7s;
  --anim-long-duration: 0.9s;
  --anim-very-long-duration: 1.5s;

  --anim-easing-in: cubic-bezier(0.33, 1, 0.68, 1); // easeOutCubic slow at the end
  --anim-easing-out: cubic-bezier(0.32, 0, 0.67, 0); // easeInCubic slow at the beginning
  --anim-easing-in-out: cubic-bezier(0.65, 0, 0.35, 1); // easeInOutCubic slow at the beginning and at the end

  --anim-btn: cubic-bezier(1, 0, 0, 1);
  --anim-tag: cubic-bezier(0.86, 0, 0.07, 1);
  --anim-nav: 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  --anim-play-video-btn: cubic-bezier(0.16, 1, 0.3, 1);
}
