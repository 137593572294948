.b-footer {
  background-color: var(--gray-7);

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 2.4rem;

    img {
      margin-bottom: 1.6rem;
      padding: 1.6rem 0;
    }
  }

  &__brokerage {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }

  &__text {
    text-align: center;
    
    span, a {
      &:first-child {
        color: var(--gray-6);
      }

      &:last-child {
        color: var(--gray-5);
      }
    }
  }

  &__owner {
    &--column {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    img {
      display: block;
      text-align: center;
    }
  }
}
