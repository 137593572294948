@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin animate-reveal-in-from-bottom-with-fade-in {
  transition: transform var(--anim-medium-duration) var(--anim-easing-in),
    opacity var(--anim-medium-duration) var(--anim-easing-in);
  opacity: 0;
  transform: translate3d(0, 50px, 0);
  will-change: transform, opacity;
  &.animate {
    animation-delay: var(--anim-medium-duration);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
