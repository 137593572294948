.features {
  &__section {
    margin-bottom: 6.4rem;
    @include breakpoint('phone-max') {
      margin-bottom: 3.2rem;
    }

    .tags {
      z-index: 1;
    }
  }
  &__title {
    margin-bottom: 2.4rem;
  }
}
