.tour {
  &__section {
    position: relative;
    z-index: 2;
    margin-bottom: 12.4rem;
    @include breakpoint('phone-max') {
      margin-bottom: 5.2rem;
    }

    .container:not(:first-child) {
      margin-top: 4rem;
    }
  }
  &__title {
    margin-bottom: 2.4rem;
  }
  &__wrapper {
    border-radius: var(--wrapper-radius, 0);
    box-shadow: var(--sections-shadow);
    overflow: hidden;
    &--shadow-none {
      box-shadow: unset;
    }
    main {
      background-color: none;
    }
    #loading-gui #loading-background {
      background-color: none;
    }
  }
}
