.l-contacts {
  &__pattern {
    position: absolute;
    left: var(--l-container-padding);
    top: 0;

    transform: translateY(-50%);
  }

  &__title {
    @include breakpoint("tablet-max") {
      margin-bottom: 2.4rem;
      text-align: center;
    }
  }

  &__container {
    position: relative;

    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    column-gap: 15rem;

    padding-top: 18rem;
    padding-bottom: 18rem;

    @include breakpoint("laptop-max") {
      column-gap: 7rem;
    }

    @include breakpoint("tablet-max") {
      grid-template-columns: 1fr;

      padding-top: 11rem;
      padding-bottom: 14rem;
    }
  }

  &__form-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 3.2rem;

    @include breakpoint("tablet-max") {
      grid-template-columns: 1fr;

      row-gap: 2rem;
    }

    &.is-hidden {
      display: none;
    }
  }

  &__full {
    grid-column: 1 / -1;

    height: 100%;
  }

  &__form-group {
    position: relative;

    &--hidden {
      position: absolute;

      visibility: hidden;
      opacity: 0;
    }
  }

  &__success {
    font-family: var(--font-ibm-plex-sans);
  }

  &__button-wrap {
    display: flex;
    justify-content: center;
  }
}
