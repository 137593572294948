@keyframes reveal-in-from-bottom {
  0% {
    opacity: 0.1;
    transform: translate3d(0, 50px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}

@keyframes reveal-in-from-top {
  0% {
    transform: translate3d(0, calc(50px * -1), 0);
    opacity: 0.1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 0.99;
  }
}


@keyframes header-reveal {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0.99;
  }
  100% {
    transform: translate3d(0, calc(50px * -1), 0);
    opacity: 0.1;
  }
}

@keyframes fade-in-scale-down {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animate-third-circle {
  0%,
  50% {
    transform: translate(-50%, -50%) scale(1);
  }

  50%,
  100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}

@keyframes animate-second-circle {
  0%,
  20% {
    transform: translate(-50%, -50%) scale(1);
  }

  80%,
  100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}

@keyframes animate-first-circle {
  0%,
  40% {
    transform: translate(-50%, -50%) scale(1);
  }

  90%,
  100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}

@keyframes nav-link-fade {
  0% {
    opacity: 0;
    transform: translateX(5rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1turn);
  }
}
