@import '../variables';

@mixin breakpoint($breakpoint) {
  @if $breakpoint == 'phone' {
    @media (max-width: $screen-xs) {
      @content;
    }
  } @else if $breakpoint == 'phone-portrait' {
    @media only screen and (max-width: $screen-xs) and (orientation: portrait) {
      @content;
    }
  } @else if $breakpoint == 'phone-landscape' {
    @media only screen and (max-width: $screen-xs) and (orientation: landscape) {
      @content;
    }
  } @else if $breakpoint == 'phone-max' {
    @media (max-width: $screen-xs-max) {
      @content;
    }
  } @else if $breakpoint == 'phone-max-portrait' {
    @media only screen and (max-width: $screen-xs-max) and (orientation: portrait) {
      @content;
    }
  } @else if $breakpoint == 'phone-max-landscape' {
    @media only screen and (max-width: $screen-xs-max) and (orientation: landscape) {
      @content;
    }
  } @else if $breakpoint == 'phone-phone-max' {
    @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
      @content;
    }
  } @else if $breakpoint == 'phone-phone-max-portrait' {
    @media only screen and (min-width: $screen-xs) and (max-width: $screen-xs-max) and (orientation: portrait) {
      @content;
    }
  } @else if $breakpoint == 'phone-phone-max-landscape' {
    @media only screen and (min-width: $screen-xs) and (max-width: $screen-xs-max) and (orientation: landscape) {
      @content;
    }
  } @else if $breakpoint == 'tablet-min' {
    @media (min-width: $screen-sm-min) {
      @content;
    }
  } @else if $breakpoint == 'tablet-max' {
    @media (max-width: $screen-sm) {
      @content;
    }
  } @else if $breakpoint == 'tablet' {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $breakpoint == 'tablet-portrait' {
    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) and (orientation: portrait) {
      @content;
    }
  } @else if $breakpoint == 'tablet-landscape' {
    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) and (orientation: landscape) {
      @content;
    }
  } @else if $breakpoint == 'tablet-max' {
    @media (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $breakpoint == 'laptop-max' {
    @media (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $breakpoint == 'tablet-desktop' {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
      @content;
    }
  } @else if $breakpoint == 'large-desktop' {
    @media only screen and (min-width: 1921px) and (max-width: 6017px) {
      @content;
    }
  } @else if $breakpoint == 'desktop' {
    @media only screen and (min-width: 1496px) and (max-width: 1921px) {
      @content;
    }
  } @else if $breakpoint == 'large-laptop' {
    @media only screen and (min-width: 1241px) and (max-width: 1496px) {
      @content;
    }
  } @else if $breakpoint == 'laptop' {
    @media only screen and (min-width: 1025px) and (max-width: 1240px) {
      @content;
    }
  } @else if $breakpoint == 'laptop-min' {
    @media only screen and (min-width: 1025px) {
      @content;
    }
  } @else if $breakpoint == 'mobile-tablet' {
    @media only screen and (min-width: 320px) and (max-width: 1024px) {
      @content;
    }
  }
}