.b-plans { 
  &__img {
    border: 1px solid var(--gray-2);
    background-color: #f8f9fa;
    border-radius: var(--wrapper-radius, 0);
  }

  &__container {
    position: relative;
    z-index: 1;
  }
}
